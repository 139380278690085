import { Timestamp } from '@bufbuild/protobuf'
import { Button, Tag } from 'antd'
import {
  ListBalanceTransactionsByProgramId,
  updateMember,
} from 'api/databaseCalls'
import { CardWithImage, EmojiTextInput, ItemInfo, Loader } from 'components'
import { TRANSACTIONS } from 'constants/routes'
import { MemberContext, ProgramContext, UserContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { Member_Action, Member_ConvertedTo } from 'gen/perkup/v1/program_pb'
import { useSwagStoreRoute } from 'hooks/routingHooks/useSwagStoreRoute'
import useIds from 'hooks/useIds'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { isEmpty } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { getRouteByProgramApprovedCats } from 'utils'
import {
  isNearCashProductVariant,
  removeProductVariantsFromSessionStorage,
} from 'utils/productVariant'
import {
  getRewardPreviewConfirmationDefaultThankYouText,
  useProductInfo,
  useProductType,
} from 'utils/rewardAcceptance'

import { DRAW_FROM_PERSONAL_FUNDS } from 'constants/sessionOrLocalStorage'
import { useShopRoute } from 'hooks'
import { AnimatedPage } from './components'
import { RewardGiftThumbnail } from './components/RewardThumbnails'
import { useMemberProduct, useProductVariantsFromSessionStorage } from './hooks'

export function RewardAcceptanceConfirmationPage() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const user = useContext(UserContext)
  const { orgId } = useIds()
  const { id: memberId } = member

  const navigate = useNavigate()

  const { gift, id: programId } = program

  const { savedVariants, isLoading: isLoadingSavedVariants } =
    useProductVariantsFromSessionStorage({
      programId,
    })

  const [thanksMessage, setThanksMessage] = useState('')

  const {
    isAmazonGift,
    isProductVariantGift,
    isVendorGift,
    isCollectionGift,
    isMultiProductGift,
  } = useProductType()

  const { product } = useMemberProduct({})

  const { swagRoute } = useSwagStoreRoute()
  const { shopRoute } = useShopRoute()
  const { showNearCash } = useSpendingOptions()

  const { productTitle, productVendorTitle, productImage } = useProductInfo({
    product,
    isAmazonGift,
    isProductVariantGift,
    isVendorGift,
    isCollectionGift,
    selectedProductVariants: savedVariants,
    isMultiProductGift,
  })

  const isMultipleAcceptanceGift = isMultiProductGift || isCollectionGift
  const memberGiftConvertedToCash =
    member?.convertedTo === Member_ConvertedTo.nearCash

  const isGift = !!gift && !memberGiftConvertedToCash

  const isThanksSubmitted = !!member?.thanksMessage

  const disableSendButton = !thanksMessage || isThanksSubmitted

  const eligibleCatRoute = getRouteByProgramApprovedCats({
    approvedCategories: program.approvedCategories,
    swagRoute,
    shopRoute,
    showNearCash,
  })

  // Send thanks message to program owner
  const handleCTAClick = async ({ sendThanks }: { sendThanks: boolean }) => {
    if (sendThanks) {
      const memberActions = member?.actions || {}

      const thanksSubmitedKey = Member_Action[Member_Action.thanksSubmitted]

      memberActions[thanksSubmitedKey] = Timestamp.now()

      // Save the thanks message on the member
      await updateMember({
        orgId,
        programId: program.id,
        memberId,
        member: { thanksMessage, actions: memberActions },
      })
      toaster.success('Successfully sent note')
    }

    // Update UI
    setThanksMessage('')
    removeProductVariantsFromSessionStorage({ programId })
    sessionStorage.removeItem(`${programId}_${DRAW_FROM_PERSONAL_FUNDS}`)
    if (isGift) {
      const balanceTransactions = await ListBalanceTransactionsByProgramId({
        programId: program?.id,
        orgId,
        userId: user.id,
      })
      if (!balanceTransactions || isEmpty(balanceTransactions)) {
        navigate(`${TRANSACTIONS}`)
        return
      }
      const { authId } = balanceTransactions[0]
      navigate(`${TRANSACTIONS}/${authId}`)
    } else {
      navigate(eligibleCatRoute)
    }
  }

  const thankYouPlaceholder = useMemo(() => {
    return getRewardPreviewConfirmationDefaultThankYouText(isGift)
  }, [isGift])

  if (isLoadingSavedVariants) return <Loader />

  const acceptedLabel = 'Accepted'

  if (isGift) {
    return (
      <AnimatedPage>
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={32}
          width="100%"
          minWidth={320}
          maxWidth={720}
        >
          {/** GIFT THUMBNAIL */}
          {isGift && (
            <Pane borderRadius={4}>
              {!isMultipleAcceptanceGift && savedVariants[0] && (
                <RewardGiftThumbnail
                  imageUrl={productImage}
                  productTitle={productTitle}
                  companyTitle={isVendorGift ? productVendorTitle : undefined}
                  programTitle={program.name}
                  badge={
                    <Tag
                      color="green"
                      style={{ marginTop: 8, width: 'fit-content' }}
                    >
                      {acceptedLabel}
                    </Tag>
                  }
                  onClick={() => handleCTAClick({ sendThanks: false })}
                  imageFit={
                    savedVariants[0] &&
                    isNearCashProductVariant(savedVariants[0])
                      ? 'contain'
                      : 'cover'
                  }
                />
              )}
              {isMultipleAcceptanceGift && (
                <Pane display="flex" flexDirection="column" gap={16}>
                  {savedVariants.map(pv => {
                    const hasOneVariant = isEmpty(pv.options)
                    const displayName = hasOneVariant
                      ? pv.productName
                      : `${pv.productName} - ${pv.variantName}`
                    return (
                      <ItemInfo
                        image={pv.imageUrl}
                        name={displayName}
                        key={pv.id}
                        imageFit={
                          isNearCashProductVariant(pv) ? 'contain' : 'cover'
                        }
                      >
                        <Tag color="green">{acceptedLabel}</Tag>
                      </ItemInfo>
                    )
                  })}
                </Pane>
              )}
            </Pane>
          )}

          <Pane display="flex" flexDirection="column" gap={16} width="100%">
            {/** THANK YOU INPUT */}
            <Pane display="flex" flexDirection="column" gap={8}>
              <Heading size={600}>
                Say thank you to {program?.email?.fromName}
              </Heading>

              <EmojiTextInput
                value={thanksMessage}
                onChange={setThanksMessage}
                asParagraph
                hideEmojiPicker={isMobile}
                disabled={isThanksSubmitted}
                maxLength={150}
                autoFocus
                placeholder={thankYouPlaceholder}
                eventType="onChange"
                handleSubmitCTA={() => handleCTAClick({ sendThanks: true })}
              />
            </Pane>

            {/** CTA BUTTON SECTION */}
            <Pane
              display="flex"
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              gap={8}
              alignSelf={isMobile ? 'center' : 'end'}
              width="100%"
            >
              <Button
                onClick={() => handleCTAClick({ sendThanks: false })}
                type="text"
              >
                Skip
              </Button>
              <Button
                type={disableSendButton ? undefined : 'primary'}
                onClick={() => handleCTAClick({ sendThanks: true })}
                disabled={disableSendButton}
              >
                Submit
              </Button>
            </Pane>
          </Pane>
        </Pane>
      </AnimatedPage>
    )
  }

  return (
    <CardWithImage imageUrl={program?.email?.banner}>
      <Pane display="flex" flexDirection="column" gap={16} width="100%">
        {/** THANK YOU INPUT */}
        <Pane display="flex" flexDirection="column" gap={8}>
          <Heading size={600}>
            Say thank you to {program?.email?.fromName}
          </Heading>

          <EmojiTextInput
            value={thanksMessage}
            onChange={setThanksMessage}
            asParagraph
            hideEmojiPicker={isMobile}
            disabled={isThanksSubmitted}
            maxLength={150}
            autoFocus
            placeholder={thankYouPlaceholder}
            eventType="onChange"
            handleSubmitCTA={() => handleCTAClick({ sendThanks: true })}
          />
        </Pane>

        {/** CTA BUTTON SECTION */}
        <Pane
          display="flex"
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          gap={8}
          justifyContent={isMobile ? undefined : 'end'}
        >
          <Button
            onClick={() => handleCTAClick({ sendThanks: false })}
            type="text"
          >
            Skip
          </Button>
          <Button
            type={disableSendButton ? undefined : 'primary'}
            onClick={() => handleCTAClick({ sendThanks: true })}
            disabled={disableSendButton}
          >
            Submit
          </Button>
        </Pane>
      </Pane>
    </CardWithImage>
  )
}
