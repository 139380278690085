import { CloudDownloadOutlined } from '@ant-design/icons'
import { captureMessage } from '@sentry/react'
import { Button } from 'antd'
import { callFunction } from 'api/functionCalls'
import OktaLogo from 'assets/Okta_Logo.png'
import {
  IntegrationCard,
  PageHeader,
  PeopleCsvUploadButton,
  withOrgSidebar,
} from 'components'
import SlackIntegration from 'components/Integrations/SlackIntegration'
import { OrgContext, OrgIntegrationsContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import {
  Organization_SubscriptionStatus,
  Organization_WorkOSStatus,
} from 'gen/perkup/v1/organization_pb'
import { useFinchConnectOpen } from 'hooks/useFinchConnectOpen'
import { useContext } from 'react'
import { makePlural } from 'utils'
import ConnectedIntegration from './ConnectedIntegration'
import FinchButton from './FinchButton'
import IntegrationsList from './IntegrationsList'

function CSVTemplateButton() {
  const headers = [
    { label: 'Employee ID (optional)', key: 'employeeId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Birthday (optional)', key: 'dob' },
    { label: 'Start Date (optional)', key: 'startDate' },
    { label: 'End Date (optional)', key: 'endDate' },
    { label: 'Title (optional)', key: 'title' },
    { label: 'Manager ID (optional)', key: 'managerId' },
    { label: 'Location (optional)', key: 'location' },
    { label: 'Department (optional)', key: 'department' },
  ]

  const csvHeaderString = headers.map(header => header.label).join(',')

  const csvContent = `data:text/csv;charset=utf-8,${csvHeaderString}\n`

  return (
    <Button
      type="link"
      href={encodeURI(csvContent)}
      download="perkup-people-template.csv"
      icon={<CloudDownloadOutlined />}
    >
      Download CSV Template
    </Button>
  )
}

function Integrations() {
  const org = useContext(OrgContext)
  const integrations = useContext(OrgIntegrationsContext)
  const { open, contextHolder } = useFinchConnectOpen()

  const handleWorkOSConnectClick = async () => {
    await callFunction('workos-GenerateAdminPortalLink', {}).then(link => {
      if (link) {
        window.location.href = link
      } else {
        toaster.warning('Something went wrong, please contact support')
        captureMessage('WorkOS admin portal link not available')
      }
    })
  }

  const hasConnectHRIS = integrations ? integrations?.length > 0 : false

  return (
    <Pane>
      {contextHolder}
      <Pane marginBottom={24}>
        <Pane display="flex" alignItems="flex-start" gap={32} marginBottom={12}>
          <PageHeader
            title={
              hasConnectHRIS
                ? 'Active HRIS integrations'
                : 'Integrate your HRIS'
            }
            description={
              hasConnectHRIS
                ? `Manage HRIS ${makePlural('integration', integrations?.length)}`
                : 'Connect your employee directory to PerkUp.'
            }
            vlogLink="https://www.loom.com/embed/d34fa651f4ab4a21b39282ba960f25b9?sid=e74b673f-5fa4-47a8-8c18-a9dd15163161"
          />

          {hasConnectHRIS && <FinchButton isButton onClick={() => open()} />}
        </Pane>
      </Pane>
      <Pane>
        {hasConnectHRIS ? (
          <Pane display="grid" gap={16}>
            {integrations?.map(integration => (
              <ConnectedIntegration
                key={integration.id}
                integration={integration}
              />
            ))}
          </Pane>
        ) : (
          <IntegrationsList onClick={() => open()} />
        )}
        <Pane marginTop={48}>
          <Pane display="flex" gap={32} alignItems="center" marginBottom={16}>
            <Heading size={800}>CSV Uploader</Heading>
            <CSVTemplateButton />
          </Pane>
          <PeopleCsvUploadButton />
        </Pane>

        <Pane>
          <Heading size={800} marginTop={48} marginBottom={24}>
            Integrations
          </Heading>

          <Pane display="grid" gap={16}>
            <SlackIntegration displayCard />
            <IntegrationCard
              logo={OktaLogo}
              title="SAML Authentication"
              description="Okta, Azure, OneLogin and more"
              connectLabel={
                org.workosStatus === Organization_WorkOSStatus.activated
                  ? 'Manage'
                  : 'Set up'
              }
              onConnectClick={handleWorkOSConnectClick}
              disabled={
                org.subscriptionStatus !==
                Organization_SubscriptionStatus.active
              }
            />
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default withOrgSidebar(Integrations)
