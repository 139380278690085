import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { createProgram } from 'api/databaseCalls'
import {
  CategorySelection,
  FrequencySelection,
  IndividualsSelection,
} from 'components'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import { defaultBudgets } from 'constants/rewards'
import { CatContext, OrgContext, UserContext } from 'context'
import { Dialog, Pane, Strong, TextInput, toaster } from 'evergreen-ui'
import { SelectAmount } from 'features'
import { ProgramFrequency, ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useEffect, useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'

export default function NewProgram({ type }: { type?: 'primary' | 'default' }) {
  const cats = useContext(CatContext)
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const userId = user?.id

  const [prorate, setProrate] = useState(false)
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [selectedIndividualIds, setSelectedIndividualIds] = useState<string[]>(
    []
  )
  const [name, setName] = useState('')
  const [budgetVal, setBudgetVal] = useState(defaultBudgets[0])
  const [frequency, setFrequency] = useState<ProgramFrequency>(
    ProgramFrequency.monthly
  )
  const [approvedCategories, setApprovedCategories] = useState<string[]>(
    cats?.map(({ name }) => name)
  )

  // Reset form
  useEffect(() => {
    setName('')
    setBudgetVal(defaultBudgets[0])
    setApprovedCategories(cats?.map(({ name }) => name))
  }, [cats, isDialogShown])

  const disableSubmit =
    !name || !budgetVal || budgetVal <= 0 || !approvedCategories?.length

  const searchClient = individualsSearchClient(org?.id)

  return (
    <Pane>
      <Button
        type={type}
        icon={<PlusOutlined />}
        onClick={() => setIsDialogShown(true)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        Create program
      </Button>
      <Dialog
        title="New program"
        isShown={isDialogShown}
        onCloseComplete={() => setIsDialogShown(false)}
        onConfirm={async () => {
          await createProgram({
            orgId: org.id,
            name,
            budget: budgetVal,
            autoEnrolled: true,
            frequency,
            peopleIds: selectedIndividualIds,
            prorate,
            type: ProgramType.perks,
            approvedCategories,
            allMerchants: approvedCategories.length === cats.length,
            ownerId: user.id,
            accountId: org?.defaultAccountId,
            userId,
          })
          setIsDialogShown(false)
          toaster.success(`${name} program succcessfully created!`)
        }}
        isConfirmDisabled={disableSubmit}
      >
        <Strong is="div" marginBottom={8}>
          Title
        </Strong>
        <TextInput
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          placeholder="Health and wellness"
          autoFocus
        />

        <Pane marginY={16}>
          <SelectAmount amount={budgetVal} onAmountChange={setBudgetVal} />
        </Pane>

        <Pane marginY={16}>
          <FrequencySelection
            value={frequency}
            onChange={setFrequency}
            prorate={prorate}
            onChangeProrate={setProrate}
          />
        </Pane>

        <Pane marginBottom={32}>
          <InstantSearch
            indexName={ALGOLIA_INDIVIDUALS_INDEX}
            searchClient={searchClient}
          >
            <IndividualsSelection
              selectedIndividualIds={selectedIndividualIds}
              onIndividualClick={selectedIndividualIds => {
                setSelectedIndividualIds(selectedIndividualIds ?? [])
              }}
            />
          </InstantSearch>
        </Pane>

        <Strong is="div" marginBottom={8}>
          Approved categories
        </Strong>
        <CategorySelection
          approvedCategories={approvedCategories}
          handleChange={setApprovedCategories}
        />
      </Dialog>
    </Pane>
  )
}
