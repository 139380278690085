import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { deleteAccount } from 'api/databaseCalls'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { isFunction } from 'lodash-es'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { useAccountBalances } from '../../../hooks/accounts/use-account-balances'

export function DeleteAccountButton({
  accountId,
  setIsLoadingDelete,
}: {
  accountId: string
  setIsLoadingDelete?: (isLoading: boolean) => void
}) {
  const org = useContext(OrgContext)
  const navigate = useNavigate()
  const { outstandingFunds, balance, spent } = useAccountBalances(accountId)

  const accountHasNoBalance = balance === 0
  const accountHasNoOutstandingBalance = outstandingFunds === 0
  const accountHasNoSpend = spent === 0
  const isNotDefaultAccount = org.defaultAccountId !== accountId
  const notViewingAccountsTable =
    window.location.pathname !== DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT

  if (
    accountHasNoBalance &&
    accountHasNoOutstandingBalance &&
    accountHasNoSpend &&
    isNotDefaultAccount
  ) {
    return (
      <Tooltip title="Delete account">
        <Popconfirm
          title="Delete account"
          description="Are you sure you want to delete this account?"
          okText="Delete"
          onConfirm={() => {
            if (isFunction(setIsLoadingDelete)) {
              setIsLoadingDelete(true)
            }
            deleteAccount({ accountId, orgId: org.id })
              .then(() => {
                if (notViewingAccountsTable) {
                  navigate(DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT)
                }
              })
              .finally(() => {
                if (isFunction(setIsLoadingDelete)) {
                  setIsLoadingDelete(false)
                }
              })
          }}
          placement="topRight"
        >
          <Button icon={<DeleteOutlined />} type="text" />
        </Popconfirm>
      </Tooltip>
    )
  }
  return null
}
