import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, DatePicker, Flex, Space, TableProps, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { FilterValue } from 'antd/es/table/interface'
import type {
  Fulfillment,
  FulfillmentStatus,
  OrderDetails,
} from 'api/tinybirdCalls/types'
import { giftNotAvailableAsset } from 'assets'
import {
  FlagAvatarWithText,
  IndividualAvatar,
  IndividualsSelection,
  LongDateDisplay,
  ORDER_FULFILLMENT_STATUS,
  OrderFulfillmentTable,
  OrderItemsBreakdown,
  OrderStatusTag,
  PerkEmptyState,
  TrackingFulfillmentsBreakdown,
  TruncatedCopyableText,
  TruncatedLongText,
} from 'components'
import { iso3RankedList } from 'constants/addresses'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import { tablePaginationSettings } from 'constants/antdesign'
import { SIDEBAR_WIDTH } from 'constants/layout'
import { getProgramTypeLabel } from 'constants/programs'
import { Heading, Text } from 'evergreen-ui'
import { OrderFulfillment_FulfillmentStatus_Enum } from 'gen/perkup/v1/vendor_pb'
import { useOrdersTableData } from 'hooks'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { capitalize, compact, isEmpty } from 'lodash-es'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { InstantSearch } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { RangeValue } from 'types/RewardsDate'
import {
  buildGhostFulfillment1,
  calculateOrderTotal,
  getCountryIsoAlpha2,
  getCountryNameFromIso2,
  getDateTimeString,
  numToDollars,
} from 'utils'
import { insertElementIf } from 'utils/arrays'
import { getIndividualDisplayName } from 'utils/individual'

const csvHeaders: { key: string; label: string }[] = [
  { key: 'orderId', label: 'Order ID' },
  { key: 'date', label: 'Date' },
  { key: 'placedBy', label: 'Placed by' },
  { key: 'product', label: 'Product' },
  { key: 'fulfillmentStatus', label: 'Order status' },
  { key: 'tracking', label: 'Tracking' },
  { key: 'shipppingTo', label: 'Shipping to' },
  { key: 'total', label: 'Total' },
  { key: 'programType', label: 'Program Type' },
  { key: 'programName', label: 'Program Name' },
]

const getCsvFormattedOrders = (orders: OrderDetails[]) => {
  return orders.map(order => {
    const countryIso2 = order.orderDetails?.shippingAddress?.country
    const createDate = order.created ? new Date(order.created) : undefined
    return {
      orderId: order.orderId,
      date: getDateTimeString(createDate),
      placedBy: order.individual
        ? getIndividualDisplayName(order.individual)
        : '',
      product: order.items.map(item => item.productName).join(', '),
      fulfillmentStatus: order.fulfillmentStatus
        ? ORDER_FULFILLMENT_STATUS[order.fulfillmentStatus].label
        : '',
      tracking: compact(
        order.fulfillments.map(fulfillment => fulfillment?.trackingUrl || '')
      ).join(', '),
      shipppingTo: countryIso2 ? getCountryNameFromIso2(countryIso2) : '',
      total: numToDollars(calculateOrderTotal(order.items)),
      programName: order.programs.map(program => program.name).join(', '),
      programType: compact(
        order.programs.map(program =>
          program.programType
            ? getProgramTypeLabel(program.programType)
            : undefined
        )
      ).join(', '),
    }
  })
}

type AntdTableProps = TableProps<OrderDetails>

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 0

const columns: ColumnsType<OrderDetails> = [
  {
    title: 'Order ID',
    dataIndex: 'orderId',
    key: 'orderId',
    render: (orderId: OrderDetails['orderId']) => {
      if (!orderId) return null
      return (
        <Space style={{ minWidth: 150 }}>
          <TruncatedCopyableText
            text={orderId}
            textMaxWidth={100}
            type="order ID"
          />
        </Space>
      )
    },
  },
  {
    title: 'Date',
    dataIndex: 'created',
    key: 'created',
    render: (created: OrderDetails['created']) => {
      if (!created) return null
      return <LongDateDisplay date={new Date(created)} />
    },
  },
  {
    title: 'Placed by',
    dataIndex: 'individual',
    key: 'individual.id',
    render: (individual: OrderDetails['individual']) => {
      if (!individual) return null
      return <IndividualAvatar individual={individual} />
    },
  },
  {
    title: 'Product',
    dataIndex: 'items',
    key: 'product',
    render: (items: OrderDetails['items']) => (
      <Space style={{ width: 320 }}>
        <OrderItemsBreakdown items={items} />
      </Space>
    ),
  },
  {
    title: 'Order status',
    dataIndex: 'fulfillmentStatus',
    key: 'fulfillmentStatus',
    render: (status: FulfillmentStatus) => (
      <Space style={{ minWidth: 105 }}>
        <OrderStatusTag withIcon status={status} />
      </Space>
    ),
    filters: [
      OrderFulfillment_FulfillmentStatus_Enum.success,
      OrderFulfillment_FulfillmentStatus_Enum.pending,
      OrderFulfillment_FulfillmentStatus_Enum.cancelled,
    ].map(status => ({
      text: ORDER_FULFILLMENT_STATUS[status].label,
      value: OrderFulfillment_FulfillmentStatus_Enum[status],
    })),
  },
  {
    title: 'Tracking',
    dataIndex: 'fulfillments',
    key: 'tracking',
    render: (fulfillments: Fulfillment[]) => (
      <Space style={{ width: 'max-content' }}>
        <TrackingFulfillmentsBreakdown fulfillments={fulfillments} />
      </Space>
    ),
  },
  {
    title: 'Shipping to',
    dataIndex: ['orderDetails', 'shippingAddress', 'country'],
    key: 'orderDetails.shippingAddress.country',
    render: (country: string) => (
      <Space style={{ minWidth: 105 }}>
        <FlagAvatarWithText iso2={country} />
      </Space>
    ),
    filters: iso3RankedList.map(iso3 => {
      const iso2 = getCountryIsoAlpha2(iso3)
      return {
        text: getCountryNameFromIso2(iso2),
        value: iso2.toUpperCase(),
      }
    }),
    filterSearch: true,
  },
  {
    title: 'Total',
    dataIndex: 'items',
    key: 'total',
    render: (items: OrderDetails['items']) => (
      <Flex style={{ minWidth: 100 }} justify="end">
        <Text>{numToDollars(calculateOrderTotal(items))}</Text>
      </Flex>
    ),
  },
  {
    title: 'Program Type',
    dataIndex: 'programs',
    key: 'programsType',
    render: (programs: OrderDetails['programs']) => {
      // filtering out null and repeated program type values
      const filteredTypes = [
        ...new Set(
          programs
            .map(program => program.programType)
            .filter(Boolean) as string[]
        ),
      ]

      if (filteredTypes.length === 0) return null

      const programTypeToDisplay =
        filteredTypes.length === 1
          ? capitalize(getProgramTypeLabel(filteredTypes[0]))
          : filteredTypes.reduce((acc, program) => {
              return `${capitalize(acc)} - ${capitalize(getProgramTypeLabel(program))}`
            })

      return (
        <Space style={{ minWidth: 120 }}>
          <Text>{programTypeToDisplay}</Text>
        </Space>
      )
    },
  },
  {
    title: 'Program Name',
    dataIndex: 'programs',
    key: 'programsName',
    render: (programs: OrderDetails['programs']) => {
      if (programs.length === 0) return null

      const programNames = programs
        .map(program => program.name ?? '')
        .reduce((acc, program) => {
          return `${acc} - ${program}`
        })

      if (!programNames) return null

      return <TruncatedLongText text={programNames} />
    },
  },
]

const expandableProps: TableProps<OrderDetails>['expandable'] = {
  expandRowByClick: true,
  expandedRowRender: ({ fulfillments, items }) => (
    <Flex vertical style={{ width: '100%', padding: '16px 32px' }}>
      {fulfillments.map(fulfillment => (
        <OrderFulfillmentTable
          key={fulfillment.name}
          itemsOnOrder={items}
          fulfillment={fulfillment}
        />
      ))}
    </Flex>
  ),
  rowExpandable: () => true,
}

function useFilters() {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [individualId, setIndividualId] = useState<string>()
  const [dateRange, setDateRange] = useState<RangeValue>()
  const [antDesignTableFilters, setAntDesignTableFilters] = useState<
    Record<string, FilterValue | null>
  >({})

  const handleTableChange: AntdTableProps['onChange'] = (
    pagination,
    filters
  ) => {
    setAntDesignTableFilters(filters)
    if (pagination.current) {
      setPage(pagination.current - 1)
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize)
    }
  }

  const handleIndividualChange = (individualId: string | undefined) =>
    setIndividualId(individualId)

  const handleDateRangeChange = (range: RangeValue) => {
    const start = range?.[0] ? range[0].startOf('day') : null
    const end = range?.[1] ? range[1].endOf('day') : null
    setDateRange(!start && !end ? null : [start, end])
  }

  return {
    handlePageChange: setPage,
    handlePageSizeChange: setPageSize,
    handleTableChange,
    handleIndividualChange,
    handleDateRangeChange,
    tableFilters: antDesignTableFilters,
    outsideTableFilters: {
      individualId,
      dateRange,
    },
    paginationFilters: {
      pageSize,
      page,
    },
  }
}

export function SwagOrdersTable({
  headerSize = 700,
  productId, // If we start to add more default filters, lets do something like Algolia's initialUiState
}: {
  headerSize?: number
  productId?: string
}) {
  const orgId = useDevSafeOrgId()
  const searchClient = individualsSearchClient(orgId)

  const {
    tableFilters,
    paginationFilters,
    outsideTableFilters,
    handlePageChange,
    handlePageSizeChange,
    handleTableChange,
    handleIndividualChange,
    handleDateRangeChange,
  } = useFilters()

  const { data, total, loading, error } = useOrdersTableData({
    args: { orgId },
    filters: {
      status: tableFilters.fulfillmentStatus as string[],
      iso2s: tableFilters['orderDetails.shippingAddress.country'] as string[],
      ...outsideTableFilters,
      ...paginationFilters,
      productId,
    },
  })

  if (error) {
    return (
      <PerkEmptyState
        iconUrl={giftNotAvailableAsset}
        header="Failed to load table data"
      />
    )
  }

  const formatAndPackageOrder = (order: OrderDetails, index: number) => {
    /**
     * Check to see if there are any items that are missing in the fulfillments. If so, we will create a ghost fulfillment of the stragglers.
     * This in progress fulfillment should've been included in the the shopify response, but something this going wrong in shopify.
     * We are building these in progress fulfillments manually for now.
     */
    const ghostFulfillment = buildGhostFulfillment1(
      order.items,
      order.fulfillments
    )

    return {
      ...order,
      key: `${order.orderId}-${order.individual?.id}-${order.created}-${index}`,
      fulfillments: [
        ...order.fulfillments,
        ...insertElementIf(!!ghostFulfillment, ghostFulfillment),
      ],
    }
  }

  const ordersToDisplay = data?.map(formatAndPackageOrder)
  const csvFormattedOrders = getCsvFormattedOrders(ordersToDisplay)

  const { individualId, dateRange } = outsideTableFilters

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
    >
      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Flex gap={16} justify="space-between" align="center">
          <Flex gap={24} align="center">
            <Heading size={headerSize}>Orders</Heading>
            <Text color="muted">Last updated 2 hours ago</Text>
          </Flex>

          <Flex gap={16}>
            <IndividualsSelection
              mode="single"
              onIndividualClick={selectedIndividualIds => {
                handleIndividualChange(selectedIndividualIds?.[0])
              }}
              selectedIndividualIds={individualId ? [individualId] : []}
              defaultLabel="Search by name, or email"
              width={400}
            />
            <DatePicker.RangePicker
              format="YYYY/MM/DD"
              onChange={handleDateRangeChange}
            />

            <CSVLink
              data={csvFormattedOrders}
              headers={csvHeaders}
              filename="perkup_swag_orders_report.csv"
            >
              <Tooltip title="Download orders report">
                <Button
                  disabled={isEmpty(csvFormattedOrders)}
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
            </CSVLink>
          </Flex>
        </Flex>

        <Table
          locale={{
            emptyText: `No orders found${individualId ? ' for this individual' : ''}${
              dateRange ? ' between these dates' : ''
            }.`,
          }}
          scroll={{ x: true }}
          style={{
            width: `calc(100vw - ${SIDEBAR_WIDTH}px - 65px)`,
          }}
          loading={{
            spinning: loading,
            indicator: <LoadingOutlined spin />,
            size: 'large',
          }}
          dataSource={ordersToDisplay}
          columns={columns}
          expandable={expandableProps}
          pagination={{
            onShowSizeChange: (_, size) => {
              handlePageSizeChange(size)
            },
            onChange: page => {
              handlePageChange(page - 1)
            },
            defaultPageSize: DEFAULT_PAGE_SIZE,
            defaultCurrent: DEFAULT_PAGE + 1,
            total,
            ...tablePaginationSettings,
          }}
          onChange={handleTableChange}
          rowClassName="pointer"
        />
      </Flex>
    </InstantSearch>
  )
}
