import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { PerkImage } from 'components/PerkImage'
import { Pane } from 'evergreen-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { tempateImageHeight, tempateImageWidth } from 'utils/templates'

export const CARD_ELEVATION_MARGIN = 4

export function CarouselImageCard({
  imageUrl,
  onClick,
  onDeleteClick,
}: {
  imageUrl: string
  onClick: () => void
  onDeleteClick?: () => void
}) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Pane
      position="relative"
      hoverElevation={1}
      onClick={onClick}
      marginTop={CARD_ELEVATION_MARGIN}
      cursor="pointer"
      overflow="hidden"
      borderRadius={8}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <PerkImage
        preview={false}
        src={imageUrl}
        style={{
          objectFit: 'cover',
          height: tempateImageHeight,
          width: tempateImageWidth,
        }}
        sizes={`${tempateImageWidth * 2}px`} // 2x resolution to account for image fit being cover
      />

      <AnimatePresence>
        {isHovered && !!onDeleteClick && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <Button
              style={{ position: 'absolute', top: 8, right: 8 }}
              icon={<DeleteOutlined />}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onDeleteClick()
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Pane>
  )
}
