import { Select, Tag } from 'antd'
import {
  adminAccountPermissionsOptions,
  individualAccountPermissionsOptions,
  managerAccountPermissionsOptions,
} from 'constants/accounts'
import { IndividualContext } from 'context'
import { Avatar, Pane, Text } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { useListIndividualsByIds } from 'hooks/individuals/useListIndividualsByIds'
import { cloneDeep } from 'lodash-es'
import { useContext, useMemo } from 'react'
import {
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
  Permission,
  PermissionOption,
  Permissions,
} from 'types/Permissions'
import { PermissonSelect } from './PermissionSelect'
import { PermissionsIndividualSelectWrapper } from './PermissionsIndividualSelectWrapper'

export function PermissionsOverview({
  currentPermissions,
  onPermissionsChange,
  adminPermissionOptions,
  managerPermissionOptions,
  individualPermissionOptions,
  isModal,
}: {
  currentPermissions: Permissions
  onPermissionsChange: (newPermissions: Permissions) => void
  adminPermissionOptions: PermissionOption[]
  managerPermissionOptions: PermissionOption[]
  individualPermissionOptions: PermissionOption[]
  isModal: boolean
}) {
  const { id: individualId, role } = useContext(IndividualContext)
  const isAdmin = role === Individual_Role.admin
  const isManager = role === Individual_Role.manager

  // WARNING: Memozing currentIndividualIds because the PermissionsIndividualSelectWrapper triggers unnecessary re-renders / callbacks, which in turn is affecting the useListIndividualsByIds hook below
  const currentIndividualIds = useMemo(
    () =>
      Object.keys(currentPermissions).filter(
        id => ![PERMISSION_ADMIN, PERMISSION_MANAGER].includes(id)
      ),
    [currentPermissions]
  )

  const { individuals: currentIndividuals } = useListIndividualsByIds({
    individualIds: currentIndividualIds,
  })

  const handleSelectPermission = (
    updatedPermission: Permission,
    permissionKey: string // ACCOUNT_ADMIN or ACCOUNT_MANAGER or individualId
  ) => {
    const newPermissions = cloneDeep(currentPermissions)

    // If the admin, manager, or individual permission is set to 0, remove it from the permissions object
    if (updatedPermission === 0 && currentPermissions[permissionKey]) {
      delete newPermissions[permissionKey]
    }
    // Otherwise, update the permissions object with the new permission value
    else {
      newPermissions[permissionKey] = updatedPermission
    }

    // Now we need to check if this specific individual just lost or degraded their own full access permissions accidentally. This happens when the individual is an admin or manager and decided to modify the admin / manager permissions
    const individualLostTheirAdminFullAccess =
      isAdmin &&
      newPermissions[PERMISSION_ADMIN] !== adminPermissionOptions[0].value

    const individualLostTheirManagerFullAccess =
      isManager &&
      newPermissions[PERMISSION_MANAGER] !== managerPermissionOptions[0].value

    // Grant the individual full access again using their individualId
    if (
      individualLostTheirAdminFullAccess ||
      individualLostTheirManagerFullAccess
    ) {
      newPermissions[individualId] = individualPermissionOptions[0].value
    }

    onPermissionsChange(newPermissions)
  }

  const defaultAdminPermission =
    currentPermissions[PERMISSION_ADMIN] ||
    adminAccountPermissionsOptions[2].value // Fallback to the last option in the admin options

  const defaultManagerPermission =
    currentPermissions[PERMISSION_MANAGER] ||
    managerAccountPermissionsOptions[3].value // Fallback to the last option in the manager options

  return (
    <>
      {/** ADMIN PERMISSIONS ROW */}
      <Pane display="flex" justifyContent="space-between">
        <Text>All admins</Text>
        <PermissonSelect
          currentPermission={defaultAdminPermission}
          handleSelectPermission={p =>
            handleSelectPermission(p, PERMISSION_ADMIN)
          }
        >
          {adminPermissionOptions.map(permission => (
            <Select.Option
              label={permission.name}
              key={permission.value}
              value={permission.value}
            >
              <Pane display="flex" flexDirection="column">
                <Text size={500}>{permission.name}</Text>
                <Text color="muted">{permission.description}</Text>
              </Pane>
            </Select.Option>
          ))}
        </PermissonSelect>
      </Pane>

      {/** MANAGER PERMISSIONS ROW */}
      <Pane display="flex" justifyContent="space-between" paddingBottom={8}>
        <Text>All managers</Text>
        <PermissonSelect
          currentPermission={defaultManagerPermission}
          handleSelectPermission={p =>
            handleSelectPermission(p, PERMISSION_MANAGER)
          }
        >
          {managerPermissionOptions.map(permission => (
            <Select.Option
              label={permission.name}
              key={permission.value}
              value={permission.value}
            >
              <Pane display="flex" flexDirection="column">
                <Text size={500}>{permission.name}</Text>
                <Text color="muted">{permission.description}</Text>
              </Pane>
            </Select.Option>
          ))}
        </PermissonSelect>
      </Pane>

      {/** INDIVIDUALS PERMISSIONS ROW */}
      <Text>Add specific users</Text>

      <Pane maxHeight={isModal ? '250px' : ''} overflowY="auto" marginTop={4}>
        {/** INDIVIDUALS SEARCH AND SELECT */}
        <PermissionsIndividualSelectWrapper
          currentPermissions={currentPermissions}
          currentIndividualIds={currentIndividualIds}
          onPermissionsChange={onPermissionsChange}
        />

        {/** INDIVIDUALS CURRENTLY IN THE PERMISSIONS SETTINGS */}
        <Pane display="flex" flexDirection="column" gap={16} marginTop={16}>
          {currentIndividuals.map(individual => (
            <Pane
              key={individual.id}
              display="flex"
              justifyContent="space-between"
            >
              <Pane display="flex" gap={16}>
                <Avatar
                  size={36}
                  name={`${individual.firstName} ${individual.lastName}`}
                />
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex" gap={8} alignItems="center">
                    <Text>
                      {individual.firstName} {individual.lastName}
                    </Text>
                    {individual.id === individualId && (
                      <Tag color="green">You</Tag>
                    )}
                  </Pane>
                  <Text>{individual.email}</Text>
                </Pane>
              </Pane>
              <PermissonSelect
                currentPermission={
                  currentPermissions[individual.id] ||
                  individualAccountPermissionsOptions[0].value
                }
                handleSelectPermission={p =>
                  handleSelectPermission(p, individual.id)
                }
              >
                {individualPermissionOptions.map(permission => (
                  <Select.Option
                    key={permission.value}
                    label={permission.name}
                    value={permission.value}
                  >
                    <Pane display="flex" flexDirection="column">
                      <Text
                        size={500}
                        style={{
                          color: permission.value === 0 ? 'red' : undefined,
                        }}
                      >
                        {permission.name}
                      </Text>
                      <Text color="muted">{permission.description}</Text>
                    </Pane>
                  </Select.Option>
                ))}
              </PermissonSelect>
            </Pane>
          ))}
        </Pane>
      </Pane>
    </>
  )
}
