import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Spin, Tag } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import {
  AddIndividualToProgramButton,
  IndividualAvatar,
  IndividualsSelection,
  Loader,
  LongDateDisplay,
  ManageMemberSidesheet,
  RewardReminderButton,
} from 'components'
import { ShipmentActionTracking } from 'components/Transactions'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE,
} from 'constants/algolia'
import { ProgramContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import {
  Program,
  ProgramStatus,
  ProgramType,
  Program_Gift_FulfilledBy,
} from 'gen/perkup/v1/program_pb'
import { useInfiniteProgramMemberData, useListMemberIdsByProgram } from 'hooks'
import useIds from 'hooks/useIds'
import { compact, isEmpty } from 'lodash-es'
import { useContext, useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { ProgramMemberData } from 'types'
import { numToDollars, redemptionStatus } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'
import { RemoveMember } from './RemoveMember'
import SendEmailButton from './SendEmailButton'

// // Columns to filter based on the program type
const manualGiftColumnsToFilter = ['access', 'perkSpent', 'perkBudget']
const programRewardColumnsToFilter = ['perkSpent', 'perkBudget']
const perkColumnsToFilter = ['thankYouMessage', 'engagement']

const columns = (isGift: boolean): ColumnsType<ProgramMemberData> => [
  {
    title: 'Name',
    dataIndex: 'individual',
    key: 'name',
    render: (individual, record) => {
      return (
        <Pane ref={record.lastMemberRef}>
          <IndividualAvatar individual={individual} />
        </Pane>
      )
    },
  },

  {
    title: 'Added',
    key: 'added',
    dataIndex: 'member',
    render: member => <LongDateDisplay date={member.created} />,
  },
  {
    title: 'Engagement',
    dataIndex: 'engagement',
    key: 'engagement',
    render: (engagement, record) => {
      if (engagement) {
        return <Tag color={engagement.color}>{engagement.label}</Tag>
      }

      const now = new Date()
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60000)
      const memberCreatedMoreThan5Minutes =
        record?.member?.created &&
        record?.member.created?.toDate() < fiveMinutesAgo

      if (record?.individual && memberCreatedMoreThan5Minutes) {
        return (
          <SendEmailButton
            program={record.program}
            individualId={record.individual.id}
            email={record.individual.email}
          />
        )
      }
      return <Text>-</Text>
    },
  },
  {
    title: 'Thank you message',
    dataIndex: 'member',
    key: 'thankYouMessage',
    render: member => {
      return <Text>{member.thanksMessage || '-'}</Text>
    },
  },
  {
    title: 'Budget',
    dataIndex: 'member',
    key: 'perkBudget',
    render: member => {
      return <Text>{numToDollars(member.budget)}</Text>
    },
  },
  {
    title: 'Spent',
    dataIndex: 'member',
    key: 'perkSpent',
    render: member => {
      return <Text>{numToDollars(member.spent)}</Text>
    },
  },
  {
    title: () => (isGift ? 'Tracking' : 'Balance'),
    dataIndex: 'member',
    key: 'access',
    render: (member, record) => {
      if (!record?.individual) return <Text>-</Text>

      const balance = member.budget - member.spent

      return (
        <Pane display="flex" alignItems="center">
          {!isGift && (
            <Pane alignSelf="center" flex={1}>
              {numToDollars(balance)}
            </Pane>
          )}
          {isGift && record?.orderFulfillments && (
            <ShipmentActionTracking
              orderFulfillments={record.orderFulfillments}
            />
          )}
        </Pane>
      )
    },
  },

  {
    title: '',
    dataIndex: 'member',
    key: 'delete',
    render: (member, record) => {
      const { isRedeemedGift } = redemptionStatus({
        program: record.program,
        member,
      })

      if (isRedeemedGift) return null

      const memberName = getIndividualDisplayName(record.individual)
      const showReminderButton =
        member.spent === 0 &&
        record.program.status === ProgramStatus.active &&
        !record.program?.isDeleted

      return (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          {showReminderButton && (
            <RewardReminderButton
              member={member}
              memberName={memberName}
              withText={false}
              buttonType="text"
              disabled={record?.sentProgramReminder}
            />
          )}
          <RemoveMember
            programId={record.program.id}
            memberId={member.id}
            memberName={memberName}
            onAfterRemove={() => record.onRemoveIndividual(member.id)}
          />
        </Pane>
      )
    },
  },
]

// Our columns we're going to render are dependent on the program type
const columnsToRender = ({ program }: { program: Program }) => {
  const isGift = !!program?.gift
  const isManualFulfillment =
    program?.gift?.fulfilledBy === Program_Gift_FulfilledBy.manual
  const columnsToFilter = columns(isGift)
  if (isManualFulfillment) {
    return columnsToFilter.filter(column => {
      if (!column?.key) return false
      return !manualGiftColumnsToFilter.includes(column.key?.toString())
    })
  }
  if (program.type === ProgramType.perks) {
    return columnsToFilter.filter(column => {
      if (!column?.key) return false
      return !perkColumnsToFilter.includes(column.key?.toString())
    })
  }
  return columnsToFilter.filter(column => {
    if (!column?.key) return false
    return !programRewardColumnsToFilter.includes(column.key?.toString())
  })
}

function TableData({
  memberIds,
  sentProgramReminder,
  onRemoveIndividual,
}: {
  memberIds: string[]
  sentProgramReminder: boolean
  onRemoveIndividual: (individualId: string) => void
}) {
  const program = useContext(ProgramContext)
  const [selectedIndividualId, setSelectedIndividualId] = useState<string>('')

  const { data, isLoadingMore, hasLoadedInitial, lastMemberRef, isLastPage } =
    useInfiniteProgramMemberData({
      memberIds,
      program,
    })

  if (!hasLoadedInitial) return <Loader />

  const dataWithSentinelRef = data.map((d, index) => {
    const attachSentinelRef = index === data.length - 1 && !isLastPage
    return {
      ...d,
      lastMemberRef: attachSentinelRef ? lastMemberRef : null,
      sentProgramReminder,
      onRemoveIndividual,
    }
  })

  return (
    <>
      <Flex vertical>
        <Table
          columns={columnsToRender({ program })}
          dataSource={dataWithSentinelRef}
          onRow={({ individual }) => ({
            style: { cursor: 'pointer' },
            onClick: () => setSelectedIndividualId(individual.id),
          })}
          pagination={false}
        />
        {isLoadingMore && <Loader />}
      </Flex>
      <ManageMemberSidesheet
        isShown={!!selectedIndividualId}
        onCloseComplete={() => setSelectedIndividualId('')}
        id={selectedIndividualId}
      />
    </>
  )
}

export function MemberTable() {
  const program = useContext(ProgramContext)

  const { orgId } = useIds()

  const [selectedIndividualId, setSelectedIndividualId] = useState<string>()
  const [sentProgramReminder, setSentProgramReminder] = useState(false)

  const searchClient = individualsSearchClient(orgId)

  const { memberIds, setMemberIds, isSendingRewards, hasLoadedInitial } =
    useListMemberIdsByProgram({
      program,
    })

  let searchFilter = STATUS_NONE_OR_INVITED_OR_ACTIVE
  if (memberIds.length > 0) {
    const memberFilter = memberIds
      .map(memberId => `objectID:${memberId}`)
      .join(' OR ')
    searchFilter = `(${memberFilter}) AND (${STATUS_NONE_OR_INVITED_OR_ACTIVE})`
  }

  if (!hasLoadedInitial) return <Loader />

  return (
    <InstantSearch
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      searchClient={searchClient}
    >
      <Pane
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginBottom={16}
        alignItems="flex-end"
      >
        <Pane>
          <Flex gap={16}>
            <Heading size={600} marginBottom={16}>
              {program.type === ProgramType.perks ? 'Members' : 'Recipients'} (
              {program.totalMembers})
            </Heading>
            {isSendingRewards && (
              <Flex gap={8}>
                <Spin indicator={<LoadingOutlined spin />} />
                <Text>Updating</Text>
              </Flex>
            )}
          </Flex>
          <IndividualsSelection
            defaultLabel="Search by name or email"
            onIndividualClick={selectedIndividualIds =>
              setSelectedIndividualId(selectedIndividualIds?.[0])
            }
            mode="single"
            selectedIndividualIds={compact([selectedIndividualId])}
            filters={searchFilter}
          />
        </Pane>
        {program.status === ProgramStatus.active && !program?.isDeleted && (
          <Pane display="flex" flexDirection="row" gap={8}>
            {memberIds.length > 0 && (
              <RewardReminderButton
                onClick={() => setSentProgramReminder(true)}
              />
            )}
            <AddIndividualToProgramButton
              enrolledIndividualIds={memberIds}
              onAddIndividual={individual => {
                setMemberIds([individual.id, ...memberIds])
              }}
            />
          </Pane>
        )}
      </Pane>
      {isEmpty(memberIds) && !isSendingRewards ? (
        <Text>No rewards sent yet</Text>
      ) : (
        <TableData
          key={selectedIndividualId}
          memberIds={selectedIndividualId ? [selectedIndividualId] : memberIds}
          sentProgramReminder={sentProgramReminder}
          onRemoveIndividual={individualId => {
            setMemberIds(memberIds.filter(id => id !== individualId))
          }}
        />
      )}
    </InstantSearch>
  )
}
