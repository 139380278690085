import { Flex } from 'antd'
import {
  BackIconButton,
  Loader,
  PageHeader,
  PerkImage,
  PerkUpLink,
} from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { Pane, Strong } from 'evergreen-ui'
import { useBulkSwagCatalogs } from 'hooks'
import { useContext } from 'react'

export function OrgSwagBulkCatalogsPage() {
  const org = useContext(OrgContext)
  const { bulkSwagCatalogs, hasLoaded } = useBulkSwagCatalogs()

  if (!hasLoaded) {
    return <Loader />
  }

  return (
    <Flex gap={24}>
      <BackIconButton to={DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT} />
      <Flex vertical gap={32} style={{ width: '100%' }}>
        <PageHeader
          title={`${org.name} Swag Catalogs`}
          description="Choose a catalog to order items in bulk."
        />
        <Pane
          display="grid"
          gridTemplateColumns={`repeat(auto-fit, minmax(288px, ${bulkSwagCatalogs.length > 2 ? '0.5fr' : '0.33fr'}  ))`}
          gap={32}
        >
          {bulkSwagCatalogs.map(catalog => {
            const { id, name, imageUrl, link } = catalog
            return (
              <PerkUpLink
                key={id}
                to={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Flex vertical gap={8} style={{ width: '100%' }}>
                  <PerkImage
                    src={imageUrl}
                    sizes={`(max-width: 1168px) ${288 * 2}px, 25vw`}
                    style={{
                      objectFit: 'cover',
                      aspectRatio: '1/1',
                      borderRadius: 8,
                      width: '100%',
                    }}
                    width="100%"
                  />
                  <Strong>{name}</Strong>
                </Flex>
              </PerkUpLink>
            )
          })}
        </Pane>
      </Flex>
    </Flex>
  )
}
