import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button as AntButton,
  Button,
  Divider,
  Flex,
  Input,
  InputRef,
  Select,
  Skeleton,
  Space,
  Tag,
} from 'antd'
import {
  GetIndividualById,
  GetOrgUserById,
  ListenToIndividualById,
  ListenToListsByOrgId,
  ListenToTransactionsByUserId,
  addLabelToIndividual,
  removeEmailFromindividual,
  removeIndividualManager,
  removeLabelFromIndividual,
  updateIndividualBlockSync,
  updateIndividualManager,
  updateIndividualRole,
  updatePrimaryEmail,
} from 'api/databaseCalls'
import { GetIntegrationById } from 'api/databaseCalls/reads/integrations'
import { callFunction } from 'api/functionCalls'
import { IndividualsSelection } from 'components/Forms'
import { TransactionStatus } from 'components/Transactions'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import { NUMBER_GREEN } from 'constants/colors'
import { isProduction } from 'constants/keys'
import { IndividualContext, OrgContext, UserContext } from 'context'
import {
  EmptyState,
  Heading,
  Link,
  Pane,
  SearchIcon,
  SideSheet,
  Strong,
  Switch,
  Text,
  Tooltip,
  toaster,
  useTheme,
} from 'evergreen-ui'
import {
  Individual,
  Individual_Role,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { OrgList, OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { useIndividualRole, useListMembershipsByIndividualId } from 'hooks'
import { useIndividualById } from 'hooks/individuals/useIndividualById'
import useIds from 'hooks/useIds'
import { capitalize, compact, isNumber, startCase } from 'lodash-es'
import BlockOrgUserButton from 'pages/ManageMember/BlockOrgUserButton'
import Permissions from 'pages/ManageMember/Permissions'
import ReAddIndividualButton from 'pages/ManageMember/ReAddMemberButton'
import RemoveMemberButton from 'pages/ManageMember/RemoveMemberButton'
import { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Index } from 'react-instantsearch'
import Stripe from 'stripe'
import { FulfilledBy } from 'types/Gift'
import {
  getAnniversaryYear,
  numToDollars,
  ordinalSuffixOf,
  sortTimestamps,
} from 'utils'
import {
  getIndividualDisplayName,
  getIndividualPrivacySettingsAllowRole,
} from 'utils/individual'
import { NewRewardButton, ResendInviteButton } from '../Buttons'
import { TooltipIconButton } from '../Buttons/TooltipIconButton'
import { IntegrationLogo } from '../IntegrationLogo'
import { Loader } from '../Loader'
import SidesheetRewardCard from '../SidesheetRewardCard'
import { UserAvatar } from '../UserAvatar'
import { IndividualDate } from './IndividualDate'
import { IndividualStatusTag } from './IndividualStatusTag'

function IndividualEmailRow({
  email,
  individual,
}: {
  email: string
  individual: Individual
}) {
  const { orgId } = useIds()
  const { isAdmin } = useIndividualRole()
  const [isHovering, setIsHovering] = useState(false)
  const [isLoadingNewPrimary, setIsLoadingNewPrimary] = useState(false)

  const isPrimary = email === individual.email

  const handleDeleteEmail = () => {
    if (individual.id) {
      removeEmailFromindividual({
        orgId,
        individualId: individual.id,
        email,
      }).then(() => {
        toaster.warning('Email deleted')
      })
    }
  }

  const handleSetPrimaryEmail = () => {
    if (individual.id) {
      setIsLoadingNewPrimary(true)
      updatePrimaryEmail({
        orgId,
        individualId: individual.id,
        email,
      })
        .then(() => {
          toaster.success(`Set ${email} as primary email`)
        })
        .finally(() => {
          setIsLoadingNewPrimary(false)
        })
    }
  }

  return (
    <Pane
      display="flex"
      alignItems="center"
      gap={16}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      height={32}
    >
      <Text>{email}</Text>
      {isPrimary && <Tag color="processing">Primary</Tag>}
      {/* Don't let admins change the email if the individual has a userId */}
      {/* Because it will cause issues with their transactions  */}
      {isAdmin && isHovering && !isPrimary && !individual.userId && (
        <Pane display="flex" marginLeft="auto">
          <Button
            type="link"
            onClick={handleSetPrimaryEmail}
            loading={isLoadingNewPrimary}
          >
            Set as primary
          </Button>
          <TooltipIconButton
            tooltipContent="Delete"
            handleClick={handleDeleteEmail}
            icon={<DeleteOutlined />}
            buttonProps={{ danger: true }}
          />
        </Pane>
      )}
    </Pane>
  )
}

function PerkCardSection({ userId }: { userId: string }) {
  const { orgId } = useIds()
  const [card, setCard] = useState<Stripe.Issuing.Card>()

  // Get card details
  useEffect(() => {
    if (orgId && userId) {
      GetOrgUserById({ orgId, userId }).then(orgUser => {
        const cardId = orgUser?.cardId
        if (cardId) {
          callFunction('stripe-RetrieveOrgCardDetails', { cardId }).then(
            cardResponse => {
              if (cardResponse?.id) {
                setCard(cardResponse)
              }
            }
          )
        }
      })
    }
  }, [orgId, userId])

  if (!card?.id) return null

  return (
    <Pane>
      <Heading marginBottom={16}>Perk Card</Heading>
      <Pane display="grid" gap={8}>
        <Pane display="flex" gap={8}>
          <Text>•••• {card.last4}</Text>
          {card.shipping?.status && (
            <Tag color="blue">{capitalize(card.shipping?.status)}</Tag>
          )}
        </Pane>
        <Pane display="flex" gap={8}>
          <Text>
            {capitalize(card.type)} {card.brand}
          </Text>
        </Pane>
        {card.shipping?.tracking_url && (
          <Link
            href={card.shipping?.tracking_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {card.shipping?.tracking_number}
          </Link>
        )}
      </Pane>
    </Pane>
  )
}

export function ManageMemberSidesheet({
  isShown,
  id,
  onCloseComplete,
}: {
  isShown: boolean
  id?: string
  onCloseComplete: Function
}) {
  const org = useContext(OrgContext)
  const currentUser = useContext(UserContext)
  const individual = useContext(IndividualContext)

  const theme = useTheme()

  const [isLoadingIndividual, setIsLoadingIndividual] = useState(false)
  const [selectedIndividual, setSelectedIndividual] = useState<Individual>()

  const [transactions, setTransactions] = useState<OrgTransaction[]>([])
  const [integration, setIntegration] = useState<Integration>()
  const [labels, setLabels] = useState<OrgList[]>([])

  const [name, setName] = useState('')
  const inputRef = useRef<InputRef>(null)

  const selectedIndividualId = selectedIndividual?.id

  const isAdmin = individual?.role === Individual_Role.admin
  const isManager = individual?.role === Individual_Role.manager

  const isInvited = selectedIndividual?.status === Individual_Status.invited
  const isRemoved = selectedIndividual?.status === Individual_Status.removed
  const orgId = org.id
  const userId = selectedIndividual?.userId

  const paramsId = id
  const isYou = userId === currentUser.id

  const email = selectedIndividual?.email
  const personFirstName =
    selectedIndividual?.firstName || selectedIndividual?.email
  const personDisplayName = selectedIndividual
    ? getIndividualDisplayName(selectedIndividual)
    : undefined
  const date = selectedIndividual?.startDate?.toDate()

  const yearsDiff = getAnniversaryYear(date)

  const role = selectedIndividual?.role
  const permsId = selectedIndividualId

  const isSyncedUser = selectedIndividual?.externalId != null
  const syncBlocked = selectedIndividual?.blockSync

  // Get person details
  useEffect(() => {
    if (orgId && paramsId) {
      setIsLoadingIndividual(true)
      ListenToIndividualById({
        orgId,
        individualId: paramsId,
        cb: individual => {
          setSelectedIndividual(individual)
          setIsLoadingIndividual(false)
        },
      })
    }
  }, [orgId, paramsId])

  // Get person's manager
  const managerId = selectedIndividual?.managerId
  const { individual: manager, isLoading: isLoadingManager } =
    useIndividualById({ individualId: managerId })

  const { memberships, setMemberships, isLoading } =
    useListMembershipsByIndividualId({
      individualId: selectedIndividualId,
    })

  // Get person's transactions
  useEffect(() => {
    if (!userId) return undefined

    return ListenToTransactionsByUserId({
      orgId,
      userId,
      cb: setTransactions,
    })
  }, [orgId, userId])

  useEffect(() => {
    if (!orgId) return
    ListenToListsByOrgId({ orgId, cb: setLabels })
  }, [orgId])

  const handleToggleBlockSync = (blockSync: boolean) => {
    if (!selectedIndividualId || !orgId) return

    updateIndividualBlockSync({
      orgId,
      individualId: selectedIndividualId,
      blockSync,
    })

    if (blockSync) {
      toaster.warning(
        `Blocked ${integration?.provider || 'HRIS'} integration for ${email}`
      )
    } else {
      toaster.success(
        `Removed block on ${
          integration?.provider || 'HRIS'
        } integration for ${email}`
      )
    }
  }

  const handleChangeManager = async (individualId?: string) => {
    if (!selectedIndividualId) return

    // If undefined, manager was removed.
    if (!individualId) {
      await removeIndividualManager({
        orgId,
        individualId: selectedIndividualId,
      })
      toaster.success('Manager removed')
      return
    }

    updateIndividualManager({
      orgId,
      individualId: selectedIndividualId,
      managerId: individualId,
    })

    const managerIndividual = await GetIndividualById({
      orgId,
      individualId,
    })
    // if manager individual role is not admin or manager, update to manager
    if (
      managerIndividual &&
      managerIndividual.role !== Individual_Role.admin &&
      managerIndividual.role !== Individual_Role.manager
    ) {
      updateIndividualRole({
        orgId,
        individualId,
        role: Individual_Role.manager,
      })
    }

    toaster.success('Manager updated')
  }

  // Retreive sync integration
  const syncId = selectedIndividual?.syncId
  useEffect(() => {
    if (syncId) {
      GetIntegrationById({ id: syncId }).then(setIntegration)
    }
  }, [syncId])

  if (!onCloseComplete) return null

  const handleCloseSidesheet = () => {
    onCloseComplete()
    setSelectedIndividual(undefined)
    setTransactions([])
  }

  const individualBalance = Number(selectedIndividual?.balance) || 0

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    key: string
  ) => {
    e.preventDefault()
    if (selectedIndividualId) {
      addLabelToIndividual({
        orgId,
        individualId: selectedIndividualId,
        key,
        value: name,
      })
      setName('')
    }
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const membershipsToRender = memberships
    .filter(m => {
      if (m?.program?.type === ProgramType.personalFunds) {
        return false
      }
      if (isManager) {
        return m?.program?.ownerId === currentUser.id
      }

      return m
    })
    .sort((a, b) => sortTimestamps(a.member?.created, b.member?.created))

  const renderCustomDropdown = (menu: any, key: string) => (
    <>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Space style={{ padding: '0 8px 4px' }}>
        <Input
          placeholder="Enter new value"
          ref={inputRef}
          value={name}
          onChange={onNameChange}
        />
        <AntButton
          type="text"
          icon={<PlusOutlined />}
          onClick={e => addItem(e, key)}
        >
          Add
        </AntButton>
      </Space>
    </>
  )

  const noEmailComponent = isLoadingIndividual ? (
    <Pane>
      <Loader />
    </Pane>
  ) : (
    <Pane>
      <EmptyState
        background="light"
        title="Person not found"
        orientation="vertical"
        icon={<SearchIcon color={theme.colors.gray500} />}
        iconBgColor={theme.colors.gray200}
      />
    </Pane>
  )

  return (
    <SideSheet
      isShown={!!isShown}
      onCloseComplete={handleCloseSidesheet}
      preventBodyScrolling
      shouldAutoFocus={false}
    >
      {!selectedIndividualId || !email ? (
        noEmailComponent
      ) : (
        <Pane display="flex">
          <Pane paddingY={32} paddingX={32} width="100%">
            <Pane>
              <Helmet>
                <title>Manage {personDisplayName}</title>
              </Helmet>
              <Pane display="flex" alignItems="flex-end">
                <Pane
                  display="flex"
                  alignItems="center"
                  marginBottom={16}
                  width="100%"
                >
                  <Pane marginRight={24}>
                    <UserAvatar
                      profilePicture={selectedIndividual?.profilePicture}
                      name={personDisplayName}
                      email={email}
                      flagSize={24}
                      pfpSize={72}
                      countryId={selectedIndividual?.countryId}
                    />
                  </Pane>
                  <Pane display="flex" flexDirection="column" width="100%">
                    <Pane display="flex" marginBottom={8} alignItems="center">
                      {isYou && <Tag color="cyan">You</Tag>}

                      <IndividualStatusTag
                        withDetail
                        individual={selectedIndividual}
                      />

                      {isInvited && (
                        <ResendInviteButton
                          email={email}
                          role={selectedIndividual.role}
                        />
                      )}
                    </Pane>
                    <Pane
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Pane>
                        <Heading size={800} marginBottom={8}>
                          {personDisplayName}
                        </Heading>
                      </Pane>
                      {isSyncedUser && (
                        <Pane display="flex" alignItems="center" gap={8}>
                          {integration?.provider ? (
                            <Pane maxWidth={64} marginBottom={8}>
                              <IntegrationLogo
                                payroll_provider_id={integration.provider}
                              />
                            </Pane>
                          ) : (
                            <Heading marginBottom={8} size={300}>
                              HRIS Sync
                            </Heading>
                          )}

                          <Tooltip
                            content={`${syncBlocked ? 'Unblock' : 'Block'} ${
                              integration?.provider || 'HRIS sync'
                            }`}
                          >
                            <Switch
                              checked={!syncBlocked}
                              disabled={!isAdmin}
                              onChange={e =>
                                handleToggleBlockSync(!e.target.checked)
                              }
                              hasCheckIcon
                            />
                          </Tooltip>
                        </Pane>
                      )}
                    </Pane>

                    <Pane display="flex" flexDirection="column" gap={4}>
                      {selectedIndividual.title && (
                        <Text color="muted">{selectedIndividual.title}</Text>
                      )}
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>

              {(isAdmin || !isProduction) && role && permsId && (
                <Pane marginBottom={32}>
                  <Permissions
                    individualId={selectedIndividualId}
                    role={role}
                  />
                </Pane>
              )}

              <Pane marginY={32}>
                <Heading marginBottom={12}>Emails</Heading>
                <Pane display="grid" gap={8}>
                  {Object.entries(selectedIndividual.emails).map(
                    ([email, value]) => {
                      if (value?.verified !== true) return null

                      return (
                        <IndividualEmailRow
                          key={email}
                          email={email}
                          individual={selectedIndividual}
                        />
                      )
                    }
                  )}
                </Pane>
              </Pane>

              <Pane
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                marginBottom={12}
              >
                <Heading>Details</Heading>
                {isNumber(yearsDiff) && (
                  <Tag color="orange" style={{ marginLeft: 'auto' }}>
                    {ordinalSuffixOf(yearsDiff)} anniversary
                  </Tag>
                )}
              </Pane>

              {isAdmin && (
                <Pane
                  marginBottom={16}
                  display="flex"
                  gap={8}
                  height={32}
                  alignItems="center"
                >
                  <Text>Balance:</Text>
                  <Text
                    color={individualBalance > 0 ? NUMBER_GREEN : 'default'}
                  >
                    {numToDollars(individualBalance, 2)}
                  </Text>
                </Pane>
              )}

              {isRemoved && (
                <Pane marginBottom={16} height={32}>
                  <Text>Balance when removed: </Text>
                  <Text>{numToDollars(selectedIndividual.removedBalance)}</Text>
                </Pane>
              )}

              {selectedIndividual.employeeId && (
                <Pane
                  marginY={16}
                  display="flex"
                  justifyContent="space-between"
                  gap={8}
                  height={32}
                  alignItems="center"
                >
                  <Text>Employee ID:</Text>
                  <Text>{selectedIndividual.employeeId}</Text>
                </Pane>
              )}

              {isAdmin && (
                <Pane>
                  <IndividualDate
                    fieldKey="startDate"
                    displayText="Work Anniversary"
                    placeholder="start date"
                    orgId={orgId}
                    individualId={selectedIndividualId}
                    dateData={selectedIndividual?.startDate}
                    isAdmin={isAdmin}
                    disabled={integration?.finchSyncSettings?.startDate}
                  />
                </Pane>
              )}
              {isAdmin && (
                <Pane marginY={16}>
                  <IndividualDate
                    fieldKey="dob"
                    displayText="Birthday"
                    placeholder="birthday"
                    orgId={orgId}
                    individualId={selectedIndividualId}
                    dateData={selectedIndividual?.dob}
                    isAdmin={isAdmin}
                    disabled={integration?.finchSyncSettings?.dob}
                    dataIsPrivate={
                      !getIndividualPrivacySettingsAllowRole({
                        individual: selectedIndividual,
                        ruleGroup: NotificationSetting_RuleGroup.birthdays,
                        role: individual.role,
                      })
                    }
                  />
                </Pane>
              )}
              <Pane
                marginBottom={16}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={8}
              >
                <Text>Manager:</Text>
                {isLoadingManager ? (
                  <Skeleton.Input size="small" style={{ width: 208 }} />
                ) : (
                  <Index
                    indexName={ALGOLIA_INDIVIDUALS_INDEX}
                    indexId="selectManager"
                  >
                    <IndividualsSelection
                      key={manager?.id}
                      selectedIndividualIds={compact([manager?.id])}
                      onIndividualClick={selectedIndividualIds => {
                        handleChangeManager(
                          selectedIndividualIds
                            ? selectedIndividualIds[0]
                            : undefined
                        )
                      }}
                      width={208}
                      defaultLabel="Select manager"
                      mode="single"
                    />
                  </Index>
                )}
              </Pane>
              <Pane>
                {labels.map(label => {
                  const displayName = startCase(label.name)
                  return (
                    <Pane
                      display="flex"
                      key={label.id}
                      marginBottom={16}
                      justifyContent="space-between"
                      alignItems="center"
                      gap={16}
                    >
                      <Text>{displayName}:</Text>
                      <Select
                        value={selectedIndividual.labels[label.id]}
                        placeholder={`Select ${displayName}`}
                        options={label.options.map(option => ({
                          value: option,
                          label: option,
                        }))}
                        style={{ minWidth: 256 }}
                        allowClear
                        onSelect={value =>
                          addLabelToIndividual({
                            orgId,
                            key: label.id,
                            value,
                            individualId: selectedIndividualId,
                          })
                        }
                        onClear={() =>
                          removeLabelFromIndividual({
                            orgId,
                            key: label.id,
                            individualId: selectedIndividualId,
                          })
                        }
                        dropdownRender={menu =>
                          renderCustomDropdown(menu, label.id)
                        }
                        disabled={!isAdmin}
                      />
                    </Pane>
                  )
                })}
              </Pane>

              {isAdmin && userId && (
                <PerkCardSection userId={userId} key={userId} />
              )}

              <Pane>
                <Flex
                  style={{
                    marginBottom: 16,
                    marginTop: 32,
                  }}
                  align="center"
                  justify="space-between"
                >
                  <Heading>Rewards</Heading>
                  <NewRewardButton
                    title={`Reward ${personFirstName}`}
                    type="primary"
                    individuals={[selectedIndividual]}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  />
                </Flex>

                {isLoading && <Loader />}
                {!isLoading &&
                  (membershipsToRender?.length > 0 ? (
                    membershipsToRender.map(membership => {
                      if (!membership.program || !membership.member) return null
                      return (
                        <SidesheetRewardCard
                          key={`${membership.program.id} + ${membership.program.name}`}
                          orderFulfillments={membership.orderFulfillments}
                          program={membership.program}
                          member={membership.member}
                          orgTransactions={membership.orgTransactions}
                          orders={membership.orders}
                          onAfterRemove={() => {
                            setMemberships(
                              memberships.filter(
                                m => m.program?.id !== membership?.program?.id
                              )
                            )
                          }}
                        />
                      )
                    })
                  ) : (
                    <Pane>
                      <Text>
                        {isManager
                          ? `You haven't sent ${personFirstName} anything yet.`
                          : 'Nothing received yet.'}
                      </Text>
                    </Pane>
                  ))}
              </Pane>

              {isAdmin && (
                <Pane>
                  <Heading marginTop={32} marginBottom={16}>
                    Transactions
                  </Heading>
                  <Flex vertical gap={8}>
                    {transactions?.length > 0 ? (
                      transactions.map(transaction => {
                        const isAmazonDirect =
                          transaction.category === FulfilledBy.AMAZON &&
                          !transaction.isGift
                        return (
                          <Pane
                            display="grid"
                            columnGap={8}
                            gridTemplateColumns="0.5fr 2fr 1fr 0.5fr"
                            justifyItems="start"
                            key={transaction.id}
                          >
                            <Text minWidth="max-content">
                              {transaction.created
                                ?.toDate()
                                .toLocaleDateString('en-us', {
                                  month: 'short',
                                  day: 'numeric',
                                })}
                            </Text>
                            <Strong overflow="hidden" textOverflow="ellipsis">
                              {isAmazonDirect
                                ? 'Amazon Direct Order'
                                : transaction.description}
                            </Strong>

                            <Pane height="fit-content">
                              <TransactionStatus
                                status={transaction.status}
                                type={transaction.type}
                                approved={transaction.approved}
                              />
                            </Pane>
                            <Text>{numToDollars(-transaction.amount, 2)}</Text>
                          </Pane>
                        )
                      })
                    ) : (
                      <Pane>
                        <Text>No transactions yet.</Text>
                      </Pane>
                    )}
                  </Flex>
                </Pane>
              )}
              <Pane marginY={32} borderBottom="muted" />
              <Pane
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                gap={16}
              >
                {isAdmin && (
                  <Pane marginRight={32}>
                    <BlockOrgUserButton
                      orgId={orgId}
                      individualId={selectedIndividual.id}
                      email={selectedIndividual.email}
                      status={selectedIndividual.status}
                      handleCloseSidesheet={handleCloseSidesheet}
                    />
                  </Pane>
                )}

                {!isYou &&
                  isAdmin &&
                  selectedIndividual?.status !== Individual_Status.removed && (
                    <RemoveMemberButton
                      individualId={selectedIndividualId}
                      email={email}
                      handleCloseSidesheet={handleCloseSidesheet}
                    />
                  )}
                {!isYou &&
                  isAdmin &&
                  selectedIndividual?.status === Individual_Status.removed && (
                    <ReAddIndividualButton
                      individualId={selectedIndividualId}
                      userId={userId}
                      email={email}
                      handleCloseSidesheet={handleCloseSidesheet}
                    />
                  )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      )}
    </SideSheet>
  )
}
