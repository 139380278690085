import { Elements } from '@stripe/react-stripe-js'
import { PaymentIntent } from '@stripe/stripe-js'
import { Alert } from 'antd'
import { callFunction } from 'api/functionCalls'
import { Loader } from 'components/Loader'
import { PerkEmptyState } from 'components/perk-empty-state'
import {
  MAX_PERSONAL_FUNDS_BALANCE,
  MIN_PERSONAL_FUNDS_DEPOSIT,
} from 'constants/money'
import { OrgContext, OrgUserContext, UserContext } from 'context'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { useOrgUserBalances } from 'hooks'
import { useStripe } from 'hooks/Stripe/useStripe'
import { isString } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { numToDollars } from 'utils'
import { StripeEmbeddedPayment } from './StripeEmbeddedPayment'

export function PersonalFundsPayment({
  amount,
  onSubmit,
  disableSubmit = false,
  isLoadingPlaceOrder = false,
}: {
  amount: number
  onSubmit: (paymentIntent: PaymentIntent) => void
  disableSubmit?: boolean
  isLoadingPlaceOrder?: boolean
}) {
  const orgUser = useContext(OrgUserContext)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const { subscriptionStatus, id: orgId } = org
  const isActiveOrg =
    subscriptionStatus === Organization_SubscriptionStatus.active
  const { customerId } = orgUser
  const { stripe, isLoading: isLoadingStripe } = useStripe()
  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const [isLoadingCreateCustomer, setIsLoadingCreateCustomer] = useState(false)
  const { orgUserPersonalBalance } = useOrgUserBalances()

  useEffect(() => {
    if (customerId) return
    setIsLoadingCreateCustomer(true)
    callFunction('stripe-CreateOrgUserCustomer', {
      name: `${user.profile?.firstName} ${user.profile?.lastName}`,
      email: user.profile?.email,
      userId: user.id,
      orgId,
      idempotencyKey: `customer_${crypto.randomUUID()}`,
    }).finally(() => setIsLoadingCreateCustomer(false))
  }, [customerId, orgId, user])

  useEffect(() => {
    if (!customerId || !isActiveOrg) {
      setIsLoadingClientSecret(false)
      return
    }

    setIsLoadingClientSecret(true)
    callFunction('stripe-CreatePersonalFundsCheckoutSession', {
      budget: amount,
      customer: customerId,
      idempotencyKey: `checkout_${crypto.randomUUID()}`,
    })
      .then(res => {
        if (!res) return
        if (isString(res)) setClientSecret(res)
      })
      .finally(() => setIsLoadingClientSecret(false))
  }, [amount, customerId, isActiveOrg])

  const getDisabledAlertText = () => {
    if (!isActiveOrg) {
      return 'Please upgrade your organization subscription to active to add personal funds'
    }
    if (amount < MIN_PERSONAL_FUNDS_DEPOSIT) {
      return `Please enter an amount greater than ${numToDollars(
        MIN_PERSONAL_FUNDS_DEPOSIT
      )}`
    }
    if (amount + orgUserPersonalBalance > MAX_PERSONAL_FUNDS_BALANCE) {
      return `Your personal funds can not exceed ${numToDollars(
        MAX_PERSONAL_FUNDS_BALANCE
      )}`
    }
    return undefined
  }

  const disabledAlertText = getDisabledAlertText()

  if (isLoadingClientSecret || isLoadingCreateCustomer || isLoadingStripe)
    return <Loader />

  if (!clientSecret || !stripe)
    return (
      <PerkEmptyState header="Looks like we made a mistake, please try again or contact suppport" />
    )

  return (
    <Elements
      stripe={stripe}
      options={{ appearance: { theme: 'stripe' }, clientSecret }}
    >
      <StripeEmbeddedPayment
        clientSecret={clientSecret}
        stripe={stripe}
        amount={amount}
        onSubmit={onSubmit}
        isLoading={isLoadingPlaceOrder}
        disabled={disableSubmit || !!disabledAlertText}
      />
      {disabledAlertText && (
        <Alert message={disabledAlertText} showIcon type="warning" />
      )}
    </Elements>
  )
}
