import CloseCircleTwoTone from '@ant-design/icons/CloseCircleTwoTone'
import { captureMessage } from '@sentry/react'
import { Button, Select } from 'antd'
import Badge from 'antd/es/badge'
import Image from 'antd/es/image'
import Spin from 'antd/es/spin'
import {
  deleteGroupBySlackPublicNotificationSetting,
  updateSlackPublicNotificationSetting,
} from 'api/databaseCalls'
import { ReactComponent as UserAddOutlined } from 'assets/icons/user-add-outlined.svg'
import { EmojiPickerButton, ThemeEditorModal, Toggle } from 'components'
import { OrgContext } from 'context'
import {
  MediaIcon,
  Menu,
  Pane,
  Paragraph,
  Popover,
  Position,
  Text,
  Tooltip,
  toaster,
  useTheme,
} from 'evergreen-ui'
import {
  GroupByType_Enum,
  NotificationSetting,
} from 'gen/perkup/v1/notification_pb'
import { filter, uniq } from 'lodash-es'
import { useContext, useState } from 'react'
import HighlightWithinTextarea from 'react-highlight-within-textarea'
import { SlackChannel } from 'types'
import {
  NotificationOccassions,
  NotificationSendTypes,
  RuleGroups,
} from 'types/Notifications'
import { getNotificationSettingId } from 'utils'
import { getSlackPublicDefaultsByOccasion } from '.'

export interface ISlackPublicConfigForm {
  channels: SlackChannel[]
  personalizeOptions: Record<string, string>[]
  notificationSetting: NotificationSetting | undefined
  occassion: NotificationOccassions
  sendType: NotificationSendTypes
  ruleGroup: RuleGroups
}

export default function SlackPublicConfigForm({
  channels,
  personalizeOptions,
  notificationSetting,
  occassion,
  sendType,
  ruleGroup,
}: ISlackPublicConfigForm) {
  const org = useContext(OrgContext)
  const orgId = org?.id

  const {
    placeholder,
    defaultImages,
    groupBy: groupByDefault,
  } = getSlackPublicDefaultsByOccasion(occassion)
  const groupBy = notificationSetting
    ? notificationSetting?.groupBy
    : groupByDefault

  const groupByDaily = groupBy === GroupByType_Enum.daily

  const [message, setMessage] = useState(
    notificationSetting?.message || placeholder
  )

  const theme = useTheme()

  const notificationSettingId: string =
    getNotificationSettingId({
      sendType,
      ruleGroup,
    }) || ''

  const personalizedOptionsArray = personalizeOptions?.map(({ beVar }) => beVar)
  const hasPersonalizedOptionsString = personalizedOptionsArray.some(
    str => message?.includes(str) || false
  )

  const handleUpdateGroupBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    // If groupBy is active, and there are personalized option strings, we need to block the user from continuing.
    const hasForbiddenPersonalizedOptions =
      hasPersonalizedOptionsString && checked

    if (hasForbiddenPersonalizedOptions) {
      toaster.warning(`Please remove variables to enable grouped notifications`)
      return
    }

    if (checked) {
      const groupBy = GroupByType_Enum.daily
      updateSlackPublicNotificationSetting({
        orgId,
        notificationSettingId,
        notificationSetting,
        updatedSettings: { groupBy },
        occassion,
      }).then(() => {
        toaster.success(`Group notifications ${GroupByType_Enum[groupBy]}`)
      })
    } else {
      deleteGroupBySlackPublicNotificationSetting({
        orgId,
        notificationSettingId,
      }).then(() => {
        toaster.warning('Grouped notifications turned off')
      })
    }
  }

  const handleUpdateChannel = (channelId: string) => {
    const selChannel = channels.find(c => c.channelId === channelId)

    if (!selChannel) {
      const errorMsg = 'Error selecting Slack Channel'
      captureMessage(errorMsg, 'error')
      toaster.warning(errorMsg)
      return
    }

    updateSlackPublicNotificationSetting({
      orgId,
      notificationSettingId,
      notificationSetting,
      updatedSettings: { channel: channelId },
      occassion,
    }).then(() => {
      toaster.success(`Selected #${selChannel.channelName} Slack channel`)
    })
  }

  const handleUpdateMessage = (newMessage: string) => {
    const messageChanged = newMessage !== notificationSetting?.message

    // Can't add personalized variables if the message is grouped by daily
    if (hasPersonalizedOptionsString && groupBy === GroupByType_Enum.daily) {
      toaster.warning(
        `Can't update message. Please remove personalized variables for grouped messages.`
      )
      return
    }

    if (messageChanged) {
      updateSlackPublicNotificationSetting({
        orgId,
        notificationSettingId,
        notificationSetting,
        updatedSettings: { message: newMessage },
        occassion,
      }).then(() => {
        setMessage(newMessage)
        toaster.success(`Updated message`)
      })
    }
  }

  const handleUpdateImages = ({
    images,
    isDelete,
  }: {
    images: string[]
    isDelete?: boolean
  }) => {
    updateSlackPublicNotificationSetting({
      orgId,
      notificationSettingId,
      notificationSetting,
      updatedSettings: { images: uniq(images) },
      occassion,
    }).then(() => {
      if (isDelete) {
        toaster.success(`Removed image`)
      } else {
        toaster.success(`Added image`)
      }
    })
  }

  const popoverContent = ({ close }: { close: () => void }) =>
    personalizeOptions?.map(({ beVar, description }) => (
      <Menu key={beVar}>
        <Menu.Item
          onSelect={() => {
            handleUpdateMessage(`${message} ${beVar}`)
            close()
          }}
        >
          <Pane display="flex" justifyContent="space-between" gap={16}>
            <Text>{description}</Text>
            <Text>{beVar}</Text>
          </Pane>
        </Menu.Item>
      </Menu>
    ))

  const imagesToRender = notificationSetting?.images || defaultImages

  return (
    <Pane marginRight={32}>
      <Pane marginBottom={22}>
        <Toggle
          checked={groupByDaily}
          onChange={handleUpdateGroupBy}
          label="Group notifications for people by day"
          hasCheckIcon
        />
      </Pane>

      <Pane marginBottom={16}>
        <Select
          placeholder="Select a channel"
          options={channels?.map(channel => ({
            value: channel.channelId,
            label: channel.channelName,
          }))}
          value={notificationSetting?.channel}
          onChange={handleUpdateChannel}
          popupMatchSelectWidth={false}
        />
      </Pane>

      <Pane>
        <Pane
          border="1px solid #D9D9D9"
          borderRadius={4}
          fontSize={theme?.fontSizes[2]}
          fontFamily={theme?.fontFamilies.ui}
          color={theme?.colors.gray800}
          paddingY={6}
          paddingX={12}
          cursor="text"
          minHeight={64}
          className="slack-message-input"
          onBlur={() => handleUpdateMessage(message)}
        >
          <HighlightWithinTextarea
            value={message}
            onChange={setMessage}
            highlight={
              groupBy
                ? personalizeOptions?.map(({ beVar }) => {
                    return { highlight: beVar, className: 'highlight-red' }
                  })
                : personalizeOptions?.map(({ beVar }) => beVar)
            }
          />
        </Pane>

        <Pane display="flex" alignItems="center">
          <Popover position={Position.BOTTOM_LEFT} content={popoverContent}>
            <Button
              disabled={groupByDaily}
              style={{
                margin: '8px 0',
              }}
            >
              <Pane marginRight={8} display="flex">
                <UserAddOutlined />
              </Pane>{' '}
              Add variables
            </Button>
          </Popover>

          <EmojiPickerButton
            onEmojiSelect={(emojiString: string) =>
              handleUpdateMessage(message + emojiString)
            }
            customStyles={{
              border: '1px solid',
              boxShadow: 'none',
              borderColor: theme?.colors.gray500,
              backgroundColor: 'white',
              marginLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px',
              paddingRight: '10px',
              height: '32px',
            }}
            buttonText={
              <Paragraph size={300} fontWeight={500}>
                Add emoji
              </Paragraph>
            }
          />
        </Pane>
        {groupBy && (
          <Paragraph size={300} color={theme.colors.muted} marginTop="-8px">
            {`Variables can't be added for grouped notifications`}
          </Paragraph>
        )}
      </Pane>
      <Pane
        display="grid"
        flexDirection="column"
        gap={8}
        gridTemplateColumns="8fr 1fr"
        marginTop={28}
      >
        <Pane display="flex" gap={8} flexWrap="wrap">
          {imagesToRender.map(image => (
            <Pane
              key={image}
              width="min-content"
              height="min-content"
              position="relative"
            >
              <Badge
                count={
                  <CloseCircleTwoTone
                    twoToneColor={theme?.colors.red500}
                    style={{
                      fontSize: 20,
                    }}
                    onClick={() =>
                      handleUpdateImages({
                        images: filter(imagesToRender, i => i !== image),
                        isDelete: true,
                      })
                    }
                  />
                }
                offset={[-4, 4]}
              >
                <Image
                  width={80}
                  style={{ borderRadius: '10px' }}
                  src={image}
                  placeholder={<Spin />}
                  preview={false}
                />
              </Badge>
            </Pane>
          ))}
        </Pane>
        <Tooltip content="Add image">
          <Pane>
            <ThemeEditorModal
              modalTrigger={
                <Button
                  icon={<MediaIcon />}
                  type="default"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    height: 80,
                    width: 80,
                  }}
                >
                  Change theme
                </Button>
              }
              onConfirmImage={(image: string) =>
                handleUpdateImages({ images: [...imagesToRender, image] })
              }
            />
          </Pane>
        </Tooltip>
      </Pane>
    </Pane>
  )
}
