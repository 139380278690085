import { ShoppingOutlined } from '@ant-design/icons'
import { Badge, Button, Drawer, Flex } from 'antd'
import { updateCartItems } from 'api/databaseCalls/writes/carts'
import { EMPTY_CART } from 'assets/contentful'
import { BulkProductRowCard, PerkEmptyState } from 'components'
import { Loader } from 'components/Loader'
import { ExchangeRateContext } from 'context'
import { Strong, toaster } from 'evergreen-ui'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors, useDisplayCurrency } from 'hooks'
import useProductVariantsByIds from 'hooks/productVariants/useProductVariantsByIds'
import { groupBy } from 'lodash-es'
import { useContext, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { calculateCartSubtotal, numToDollars } from 'utils'
import { getProductVariantQuantitiesFromCart } from 'utils/productVariant'

export function CartSidesheet({
  cart,
  openCartSidesheet,
  setOpenCartSidesheet,
  checkoutHref,
  shopHref,
  shippingAddress,
}: {
  cart: Cart
  openCartSidesheet: boolean
  setOpenCartSidesheet: (open: boolean) => void
  checkoutHref: string
  shopHref: string
  shippingAddress?: ShippingAddress
}) {
  const navigate = useNavigate()

  const { defaultColor } = useDefaultOrgColors()
  const displayCurrency = useDisplayCurrency()
  const exchangeRate = useContext(ExchangeRateContext)

  // DANGER: REMOVING THIS IS CAUSING INFINITE LOOP
  const variantIds = useMemo(
    () => cart.lineItems.map(li => li.productVariantId),
    [cart.lineItems]
  )

  const isCartEmpty = cart.lineItems.length === 0

  const { productVariants, isLoadingInitital } = useProductVariantsByIds({
    variantIds,
  })

  const productVariantsWithQuantities = getProductVariantQuantitiesFromCart(
    productVariants,
    cart
  )

  const subtotal = calculateCartSubtotal(productVariantsWithQuantities)

  const totalCartItems = cart.lineItems.reduce(
    (acc, li) => acc + li.quantity,
    0
  )

  const handleRemoveProduct = async (productId: string) => {
    const updatedLineItems = cart.lineItems.filter(
      li => li.productId !== productId
    )

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    toaster.success('Item removed from cart')
  }

  const handleRemoveVariant = async (productVariantId: string) => {
    const updatedLineItems = cart.lineItems.filter(
      li => li.productVariantId !== productVariantId
    )

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    toaster.success('Item removed from cart')
  }

  const handleUpdateQuantityOnVariant = async (
    productVariantId: string,
    newQuanity: number
  ) => {
    const updatedLineItems = cart.lineItems.map(li => {
      if (li.productVariantId === productVariantId) {
        return {
          ...li,
          quantity: Number(newQuanity),
        } as Cart_Item
      }

      return li
    })

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    toaster.success('Quantity updated')
  }

  if (isLoadingInitital) return <Loader />

  return (
    <>
      <Badge count={totalCartItems} color={defaultColor}>
        <Button
          type="text"
          onClick={() => setOpenCartSidesheet(true)}
          icon={<ShoppingOutlined />}
        />
      </Badge>
      <Drawer
        placement="right"
        open={openCartSidesheet}
        onClose={() => setOpenCartSidesheet(false)}
        title="Cart"
        width={isMobile ? '100vw' : 500}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Flex
          vertical
          justify="space-between"
          style={{
            height: '100%',
            paddingTop: isCartEmpty ? 64 : undefined,
          }}
        >
          <Flex
            vertical
            gap={16}
            style={{ overflowY: 'auto', padding: isMobile ? 8 : 24 }}
          >
            {isCartEmpty ? (
              <PerkEmptyState
                ctaProps={{
                  children: 'Shop now',
                  onClick: () => {
                    navigate(shopHref)
                    setOpenCartSidesheet(false)
                  },
                }}
                header="Your cart is empty"
                iconUrl={EMPTY_CART}
              />
            ) : (
              Object.entries(
                groupBy(productVariantsWithQuantities, 'productId')
              ).map(([productId, productVariants]) => (
                <BulkProductRowCard
                  key={productId}
                  elevateOnHover
                  onRemoveProductClick={handleRemoveProduct}
                  onRemoveVariantClick={handleRemoveVariant}
                  onQuantityChange={(pv, q) =>
                    handleUpdateQuantityOnVariant(pv, q)
                  }
                  productVariantsWithQuantities={productVariants}
                  withEstimatedShippingTime
                  shippingAddress={shippingAddress}
                />
              ))
            )}
          </Flex>

          {!isCartEmpty && (
            <Flex vertical gap={16} style={{ padding: 24 }}>
              <Flex justify="space-between">
                <Strong>Subtotal</Strong>
                <Strong>
                  {numToDollars(
                    subtotal * exchangeRate,
                    2,
                    false,
                    displayCurrency
                  )}
                </Strong>
              </Flex>

              <Button
                onClick={() => {
                  navigate(checkoutHref)
                  setOpenCartSidesheet(false)
                }}
                style={{ width: '100%' }}
                type="primary"
              >
                Checkout
              </Button>
            </Flex>
          )}
        </Flex>
      </Drawer>
    </>
  )
}
