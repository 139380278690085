import { FlatfileProvider } from '@flatfile/react'
import { setUser as setSentryUser } from '@sentry/react'
import { ConfigProvider } from 'antd'
import {
  GetCountryById,
  GetDomainById,
  GetExchangeRate,
  GetInvitesByEmail,
  ListCountries,
  ListenCreateToUserById,
  ListenToIndividualByEmail,
  ListenToOrgAccounts,
  ListenToOrgById,
  ListenToOrgUserById,
  ListenToPublicSwagCollections,
  ListenToShippingAddressesByUserId,
  ListMacroCategories,
} from 'api/databaseCalls'
import { listenToIntegrationsByOrgId } from 'api/databaseCalls/reads/integrations'
import { callFunction } from 'api/functionCalls'
import axios from 'axios'
import { Loader } from 'components'
import { PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { US_ID } from 'constants/countries'
import { FLATFILE_PUBLIC_KEY } from 'constants/keys'
import { CODE } from 'constants/params'
import * as ROUTES from 'constants/routes'
import {
  CatContext,
  CountriesContext,
  CountryContext,
  ExchangeRateContext,
  IndividualContext,
  OrgBalanceContext,
  OrgContext,
  OrgIntegrationsContext,
  OrgUserContext,
  SwagCollectionIdsContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import { ThemeProvider } from 'evergreen-ui'
import { getAnalytics, setUserProperties } from 'firebase/analytics'
import 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { Country, MacroCategory } from 'gen/perkup/v1/contentful_pb'
import { Individual, Individual_Role } from 'gen/perkup/v1/individual_pb'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { User as OrgUser } from 'gen/perkup/v1/org_user_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import { RootUser, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import sum from 'lodash-es/sum'
import { useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Outlet, useLoaderData, useRevalidator } from 'react-router-dom'
import { customEvergreenTheme } from 'themes'
import { buildFullName, getDomainFromEmail } from 'utils'
import { getFunctionsHost } from 'utils/hosts'
import { filterProductCollectionsByCountry } from 'utils/productCollections'

function useAuthRevalidator() {
  const loaderData = useLoaderData() as {
    isAuthenticated: boolean
  }

  const [authUser] = useAuthState(getAuth())

  const revalidator = useRevalidator()

  useEffect(() => {
    if (authUser && !loaderData.isAuthenticated) {
      revalidator.revalidate()
    } else if (!authUser && loaderData.isAuthenticated) {
      revalidator.revalidate()
    }
  }, [authUser, loaderData.isAuthenticated, revalidator])
}

export function Layout() {
  const [user, setUser] = useState<RootUser>()
  const [org, setOrg] = useState<Organization>()
  const [exchangeRate, setExchangeRate] = useState(1)
  const [orgUser, setOrgUser] = useState<OrgUser>()
  const [loadingClaims, setLoadingClaims] = useState(false)
  const [macroCategories, setMacroCategories] = useState<MacroCategory[]>([])

  const [countries, setCountries] = useState<Country[]>([])
  const [country, setCountry] = useState<Country>()

  const [userAtlasHash, setUserAtlasHash] = useState('')
  const [claimOrgId, setClaimOrgId] = useState('')
  const [totalAccountBalance, setTotalAccountBalance] = useState<number>(0)
  const [individual, setIndividual] = useState<Individual>()
  const [swagCollectionIds, setSwagCollectionIds] = useState<string[]>([])
  const [domain, setDomain] = useState<string>()
  const [orgIntegrations, setOrgIntegrations] = useState<Integration[]>([])
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  )
  useAuthRevalidator()

  // get current signed in user
  const [authUser, loadingAuthUser] = useAuthState(getAuth())
  const orgId = org?.id
  const userId = authUser?.uid
  const userCurrentOrganization = user?.currentOrganization
  const userEmail = user?.profile?.email

  const isChangingOrgs = userCurrentOrganization !== orgId

  const role = individual?.role

  const { pathname } = window.location

  // Set logged in user
  useEffect(() => {
    if (!userId) return undefined
    getAuth()?.currentUser?.getIdToken(true)

    return ListenCreateToUserById({
      id: userId,
      cb: setUser,
    })
  }, [userId])

  // Inject claims
  useEffect(() => {
    async function injectClaims(id: string) {
      const host = getFunctionsHost()
      const url = `${host}/firestore-InjectInfo`
      await axios.post(url, { authId: id })
    }

    function wrongClaims(claims: any) {
      if (!individual?.role) return true

      const wrongOrgIdClaim = claims?.orgId !== userCurrentOrganization
      const wrongOrgRoleClaim =
        claims?.orgRole !== Individual_Role[individual.role]

      return wrongOrgIdClaim || wrongOrgRoleClaim
    }

    async function handleAuthChange() {
      if (!authUser) {
        return
      }

      // making sure to update the JWT token outside of React as well
      await getAuth()?.currentUser?.getIdTokenResult(true)
      const { claims } = await authUser.getIdTokenResult(true)
      if (claims?.orgId) {
        const orgId = claims.orgId as string
        setClaimOrgId(orgId)
      }

      const hasWrongClaims = wrongClaims(claims)

      if (hasWrongClaims) {
        setLoadingClaims(true)
        const userId = claims.user_id as string
        await injectClaims(userId).finally(() => setLoadingClaims(false))

        // making sure to update the JWT token outside of React as well
        await getAuth()?.currentUser?.getIdTokenResult(true)
        const newAuthToken = await authUser.getIdTokenResult(true)
        if (newAuthToken.claims?.orgId) {
          const orgId = newAuthToken.claims.orgId as string
          setClaimOrgId(orgId)
        }
      }
    }

    if (authUser && userCurrentOrganization) {
      handleAuthChange()
    }
  }, [authUser, userCurrentOrganization, individual?.role])

  // Set current organization
  useEffect(() => {
    if (!claimOrgId) return undefined
    return ListenToOrgById({ id: claimOrgId, cb: setOrg })
  }, [claimOrgId])

  useEffect(() => {
    if (!userId) return undefined
    return ListenToShippingAddressesByUserId({
      userId,
      cb: setShippingAddresses,
    })
  }, [userId])

  // Set current organization to empty object if user doesn't have an organization
  useEffect(() => {
    const checkDomainsAndInvites = async () => {
      if (userEmail) {
        // 1: Check domain based on email
        if (!userCurrentOrganization) {
          const domain = getDomainFromEmail(userEmail)
          await GetDomainById({ domain }).then(async domain => {
            if (domain?.orgId && domain?.verified) {
              // Add user to org based on verified domain
              await callFunction('firestore-AddUserToOrg', {
                userId,
                orgId: domain?.orgId,
                role: Individual_Role[Individual_Role.member],
              })
            }
          })
          // 2: Check if there are any invites for email
          await GetInvitesByEmail({ email: userEmail }).then(invitesDocs => {
            invitesDocs?.forEach(invite => {
              // Add user to org based on invite
              callFunction('firestore-AddUserToOrg', {
                userId,
                orgId: invite.orgId,
                role: Individual_Role[invite.role],
              })
            })
          })
        }
      }
    }

    checkDomainsAndInvites()
  }, [userCurrentOrganization, userEmail, userId])

  useEffect(() => {
    if (userEmail) {
      const domain = getDomainFromEmail(userEmail)
      GetDomainById({ domain }).then(async domain => {
        if (domain?.orgId && domain?.verified && domain?.domain) {
          setDomain(domain?.domain)
        }
      })
    }
  }, [userEmail])

  // Set user role and orgUser based on organization
  useEffect(() => {
    if (!userId || !claimOrgId) return undefined

    return ListenToOrgUserById({
      userId,
      orgId: claimOrgId,
      cb: setOrgUser,
    })
  }, [userId, claimOrgId])

  // Set individual
  useEffect(() => {
    if (!userEmail || !claimOrgId) return undefined

    return ListenToIndividualByEmail({
      email: userEmail,
      orgId: claimOrgId,
      cb: setIndividual,
    })
  }, [userEmail, claimOrgId])

  // List macro categories
  useEffect(() => {
    if (userId) {
      ListMacroCategories().then(setMacroCategories)
    }
  }, [userId])

  // Atlas user signature
  useEffect(() => {
    if (userId) {
      callFunction('services-CreateAtlasIdentityHash', { userId }).then(
        setUserAtlasHash
      )
    }
  }, [userId])

  // Listen to org integrations
  useEffect(() => {
    const isAdmin = role === Individual_Role.admin
    if (!isAdmin) return undefined
    if (!orgId) return undefined
    return listenToIntegrationsByOrgId({ orgId, cb: setOrgIntegrations })
  }, [orgId, role])

  // Set user in Sentry, Firebase Analytics & Segment Identify
  useEffect(() => {
    if (user?.profile && userAtlasHash && org?.name && individual?.role) {
      const { email, firstName, lastName } = user.profile

      const companyData = {
        name: org.name,
        company_id: claimOrgId,
        subscriptionStatus: org.subscriptionStatus
          ? Organization_SubscriptionStatus[org.subscriptionStatus]
          : null,
        domain,
      }

      // Set Segment Identify User
      window.analytics.identify(user.id, {
        email,
        firstName,
        lastName,
        role: Individual_Role[individual.role],
        balance: individual?.balance,
        company: {
          id: claimOrgId,
          ...companyData,
        },
        customFields: {
          role: Individual_Role[individual.role],
          title: individual?.title,
          labels: individual?.labels
            ? Object.entries(individual?.labels)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ')
            : null,
        },
      })
      if (claimOrgId) {
        window.analytics?.group(claimOrgId, companyData)
      }

      window.Atlas.call('identify', {
        userId: user.id,
        name: buildFullName({ firstName, lastName }),
        email,
        userHash: userAtlasHash,
        account: {
          name: companyData?.name,
          website: domain,
          externalId: claimOrgId,
          customFields: {
            subscription_status: companyData.subscriptionStatus,
          },
        },
      })

      // Set Firebase Analytics User
      setUserProperties(getAnalytics(), {
        id: user.id,
        email,
        firstName,
        lastName,
        role: individual?.role ? Individual_Role[individual.role] : null,
      })

      // Set Sentry User
      setSentryUser({
        id: user.id,
        email,
      })
    }
  }, [
    claimOrgId,
    org?.name,
    org?.subscriptionStatus,
    individual?.balance,
    individual?.role,
    user?.id,
    user?.profile,
    userAtlasHash,
    domain,
    individual?.title,
    individual?.labels,
  ])

  // Get user's exchange rate
  const displayCurrency = user?.displayCurrency || org?.displayCurrency || 'usd'
  useEffect(() => {
    if (userId && orgId) {
      GetExchangeRate({
        fromCurrency: 'usd',
        toCurrency: displayCurrency,
      }).then(setExchangeRate)
    }
  }, [orgId, displayCurrency, userId])

  // Get Country
  useEffect(() => {
    if (userId) {
      const countryId = user?.countryId || US_ID // Default to US country id

      GetCountryById({ countryId }).then(setCountry)
    }
  }, [userId, user?.countryId])

  // List Countries
  useEffect(() => {
    if (userId) {
      ListCountries().then(setCountries)
    }
  }, [userId])

  // Get Total Account Balances

  useEffect(() => {
    const isAdminOrManager =
      role && [Individual_Role.admin, Individual_Role.manager].includes(role)
    if (!claimOrgId || !isAdminOrManager) return undefined
    return ListenToOrgAccounts({
      orgId: claimOrgId,
      cb: accounts => {
        setTotalAccountBalance(sum(accounts.map(a => Number(a.balance))))
      },
    })
  }, [claimOrgId, role])

  // Get ids for org's public swag collections
  useEffect(() => {
    if (orgId && country) {
      return ListenToPublicSwagCollections({
        orgId,
        cb: collections => {
          const countryFilteredCollections = filterProductCollectionsByCountry({
            iso3: country.iso3,
            collections,
          })

          const collectionIds = countryFilteredCollections.map(
            collection => collection.id
          )

          setSwagCollectionIds(collectionIds)
        },
      })
    }
    return undefined
  }, [orgId, country, org])

  const primaryColor = org?.primaryColor || PERKUP_PRIMARY_COLOR

  const customEGTheme = useMemo(() => {
    return customEvergreenTheme({
      primaryColor,
    })
  }, [primaryColor])

  const customAntTheme = useMemo(() => {
    return {
      components: {
        Table: {
          headerBorderRadius: 0,
        },
      },
      token: {
        colorPrimary: primaryColor,
      },
    }
  }, [primaryColor])

  const signInPath = pathname?.includes(ROUTES.SIGN_IN)
  const params = new URLSearchParams(document.location.search)
  const code = params.get(CODE)
  const SSOworkOS = signInPath && code

  // isChangingOrgs is a temp fix for org mismatch, need to refactor app.tsx
  if (loadingAuthUser || loadingClaims || isChangingOrgs) return <Loader />

  if (!authUser || SSOworkOS)
    return (
      <ConfigProvider theme={customAntTheme}>
        <Outlet
          context={{
            isAuthenticated: false,
          }}
        />
      </ConfigProvider>
    )

  if (!user || !country || !countries.length) {
    return <Loader />
  }

  return (
    <ThemeProvider value={customEGTheme}>
      <ConfigProvider theme={customAntTheme}>
        <UserContext.Provider value={user}>
          <OrgContext.Provider value={org!}>
            <IndividualContext.Provider value={individual!}>
              <OrgBalanceContext.Provider value={totalAccountBalance}>
                <OrgUserContext.Provider value={orgUser!}>
                  <ExchangeRateContext.Provider value={exchangeRate}>
                    <CatContext.Provider value={macroCategories}>
                      <CountriesContext.Provider value={countries}>
                        <CountryContext.Provider value={country}>
                          <SwagCollectionIdsContext.Provider
                            value={swagCollectionIds}
                          >
                            <UserShippingAddressesContext.Provider
                              value={shippingAddresses}
                            >
                              <OrgIntegrationsContext.Provider
                                value={orgIntegrations}
                              >
                                <FlatfileProvider
                                  publishableKey={FLATFILE_PUBLIC_KEY}
                                >
                                  <Outlet
                                    context={{
                                      isAuthenticated: true,
                                    }}
                                  />
                                </FlatfileProvider>
                              </OrgIntegrationsContext.Provider>
                            </UserShippingAddressesContext.Provider>
                          </SwagCollectionIdsContext.Provider>
                        </CountryContext.Provider>
                      </CountriesContext.Provider>
                    </CatContext.Provider>
                  </ExchangeRateContext.Provider>
                </OrgUserContext.Provider>
              </OrgBalanceContext.Provider>
            </IndividualContext.Provider>
          </OrgContext.Provider>
        </UserContext.Provider>
      </ConfigProvider>
    </ThemeProvider>
  )
}
