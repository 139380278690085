import { Tag } from 'antd'
import { Fulfillment } from 'api/tinybirdCalls/types'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { Fulfillment_ShipmentStatus_Enum } from 'gen/shopify/fulfillment_pb'

const ORDER_FULFILLMENT_SHIPMENT_STATUS_BASE: Record<
  number | string,
  {
    label: string
    color: string
    icon?: React.ReactNode
  }
> = {
  [Fulfillment_ShipmentStatus_Enum.SHIPMENT_STATUS_UNSPECIFIED]: {
    label: 'Unspecified',
    color: 'error',
  },
  [Fulfillment_ShipmentStatus_Enum.attempted_delivery]: {
    label: 'Attempted delivery',
    color: 'orange',
  },
  [Fulfillment_ShipmentStatus_Enum.delivered]: {
    label: 'Delivered',
    color: 'default',
  },
  [Fulfillment_ShipmentStatus_Enum.failure]: {
    label: 'Failure',
    color: 'error',
  },
  [Fulfillment_ShipmentStatus_Enum.in_transit]: {
    label: 'In transit',
    color: 'blue',
  },
  [Fulfillment_ShipmentStatus_Enum.out_for_delivery]: {
    label: 'Out for delivery',
    color: 'blue',
  },
  [Fulfillment_ShipmentStatus_Enum.ready_for_pickup]: {
    label: 'Ready for pickup',
    color: 'blue',
  },
}

const ORDER_FULFILLMENT_SHIPMENT_STATUS = {
  ...Object.fromEntries(
    Object.values(Fulfillment_ShipmentStatus_Enum).map(status => [
      status,
      ORDER_FULFILLMENT_SHIPMENT_STATUS_BASE[
        Fulfillment_ShipmentStatus_Enum[status as number]
      ],
    ])
  ),
  ...ORDER_FULFILLMENT_SHIPMENT_STATUS_BASE,
}

export function OrderShipmentActionsTag({
  shipmentActions,
}: {
  shipmentActions:
    | Fulfillment['shipmentActions']
    | OrderFulfillment['shipmentActions']
}) {
  if (!shipmentActions) return null

  const actionsWithTimestamps = Object.entries(shipmentActions).filter(
    ([_, value]) => value !== null
  )

  if (actionsWithTimestamps.length === 0) return null

  const actionsConvertedToHoldDates = actionsWithTimestamps.map(
    ([key, value]) => {
      return { actionEnum: key, actionDate: new Date(value) }
    }
  )

  const mostRecentAction = actionsConvertedToHoldDates.sort(
    (a, b) => b.actionDate.getTime() - a.actionDate.getTime()
  )[0]

  const { label, color } =
    ORDER_FULFILLMENT_SHIPMENT_STATUS[mostRecentAction.actionEnum]

  return (
    <Tag color={color} style={{ width: 'fit-content' }}>
      {label}
    </Tag>
  )
}
