import { Button, Drawer } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { AccountBalanceLabels } from 'constants/accounts'
import { tablePaginationSettings } from 'constants/antdesign'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Pane, Strong, Text } from 'evergreen-ui'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { useActiveProgramsByAccount } from 'hooks'
import { isEmpty, startCase, sum } from 'lodash-es'
import { useMemo, useState } from 'react'
import { numToDollars } from 'utils'

interface DataType {
  key: string
  programName: string
  href?: string
  budget: string
  spent: string
  unredeemed: string
  totals?: boolean
  childPrograms?: Program[]
  muted?: boolean
}

const getRowCTALink = (program: Program) => {
  return `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${program.id}`
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Program',
    dataIndex: 'programName',
    key: 'name',
    render: (name, record) => {
      const textColor = record.muted ? 'muted' : 'black'

      const children = record.totals ? (
        <Strong color={textColor}>{name}</Strong>
      ) : (
        <Text color={textColor}>{name}</Text>
      )

      if (!record.href) return <Pane>{children}</Pane>

      return <PerkUpLink to={record?.href}>{children}</PerkUpLink>
    },
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    key: 'budget',
    render: (budget, record) => {
      const textColor = record.muted ? 'muted' : 'black'
      return (
        <Pane>
          {record.totals ? (
            <Strong color={textColor}>{budget}</Strong>
          ) : (
            <Text color={textColor}>{budget}</Text>
          )}
        </Pane>
      )
    },
  },
  {
    title: 'Spent',
    dataIndex: 'spent',
    key: 'spent',
    render: (spent, record) => {
      const textColor = record.muted ? 'muted' : 'black'
      return (
        <Pane>
          {record.totals ? (
            <Strong color={textColor}>{spent}</Strong>
          ) : (
            <Text color={textColor}>{spent}</Text>
          )}
        </Pane>
      )
    },
  },
  {
    title: AccountBalanceLabels.UNREDEEMED_REWARDS,
    dataIndex: 'unredeemed',
    key: 'unredeemed',
    render: (unredeemed, record) => {
      const textColor = record.muted ? 'muted' : 'black'
      return (
        <Pane>
          {record.totals ? (
            <Strong color={textColor}>{unredeemed}</Strong>
          ) : (
            <Text color={textColor}>{unredeemed}</Text>
          )}
        </Pane>
      )
    },
  },
]

const expandableContent = (record: DataType) => {
  const expandedData: DataType[] = record.childPrograms
    ? record.childPrograms.map(program => {
        const href = getRowCTALink(program)
        return {
          key: program.id,
          programId: program.id,
          href,
          programName: program.name,
          budget: numToDollars(program.totalBudget) || '',
          spent: numToDollars(program.totalSpent) || '',
          unredeemed:
            numToDollars(program.totalBudget - program.totalSpent) || '',
          muted: true,
        }
      })
    : []
  return (
    <Table
      rowClassName="antd-table-light"
      columns={columns}
      dataSource={expandedData}
      pagination={tablePaginationSettings}
      showHeader={false}
      tableLayout="fixed"
    />
  )
}

export function OutstandingFundsDrawer({ accountId }: { accountId: string }) {
  const ANNIVERSARIES = 'anniversaries'
  const BIRTHDAYS = 'birthdays'
  const [showDrawer, setShowDrawer] = useState(false)
  const { accountPrograms, birthdayPrograms, anniversaryPrograms } =
    useActiveProgramsByAccount(accountId)

  const data: DataType[] = accountPrograms
    .filter(
      program =>
        program.type !== ProgramType.anniversaries &&
        program.type !== ProgramType.birthdays
    )
    .map(program => {
      const { totalBudget, totalSpent } = program
      const unredeemed = totalBudget - totalSpent

      const href = getRowCTALink(program)

      return {
        key: program.id,
        programId: program.id,
        href,
        programName: program.name,
        budget: numToDollars(totalBudget) || '',
        spent: numToDollars(totalSpent) || '',
        unredeemed: numToDollars(unredeemed) || '',
      }
    })

  const formattedBirthdayTotals = useMemo((): DataType => {
    const totalBirthdayBudget = sum(
      birthdayPrograms.map(program => program.totalBudget)
    )
    const totalBirthdaySpent = sum(
      birthdayPrograms.map(program => program.totalSpent)
    )
    const totalBirthdayUnredeemed = totalBirthdayBudget - totalBirthdaySpent

    const href = DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.SENT

    return {
      key: BIRTHDAYS,
      href,
      programName: startCase(BIRTHDAYS),
      budget: numToDollars(totalBirthdayBudget) || '',
      spent: numToDollars(totalBirthdaySpent) || '',
      unredeemed: numToDollars(totalBirthdayUnredeemed) || '',
      childPrograms: birthdayPrograms,
    }
  }, [birthdayPrograms])

  const formattedAnniversaryTotals = useMemo((): DataType => {
    const totalAnniversaryBudget = sum(
      anniversaryPrograms.map(program => program.totalBudget)
    )
    const totalAnniversarySpent = sum(
      anniversaryPrograms.map(program => program.totalSpent)
    )
    const totalAnniversaryUnredeemed =
      totalAnniversaryBudget - totalAnniversarySpent
    const href = DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.SENT

    return {
      key: ANNIVERSARIES,
      href,
      programName: startCase(ANNIVERSARIES),
      budget: numToDollars(totalAnniversaryBudget) || '',
      spent: numToDollars(totalAnniversarySpent) || '',
      unredeemed: numToDollars(totalAnniversaryUnredeemed) || '',
      childPrograms: anniversaryPrograms,
    }
  }, [anniversaryPrograms])

  const accountTotals = useMemo(() => {
    const totalAccountBudget = sum(
      accountPrograms.map(program => program.totalBudget)
    )
    const totalAccountSpent = sum(
      accountPrograms.map(program => program.totalSpent)
    )
    const totalAccountUnredeemed = totalAccountBudget - totalAccountSpent

    return {
      key: accountId,
      programName: 'Totals',
      budget: numToDollars(totalAccountBudget) || '',
      spent: numToDollars(totalAccountSpent) || '',
      unredeemed: numToDollars(totalAccountUnredeemed) || '',
      totals: true,
    }
  }, [accountId, accountPrograms])

  // Only include birthdays and anniversaries tabs if those programs exist
  if (!isEmpty(formattedBirthdayTotals.childPrograms)) {
    data.unshift(formattedBirthdayTotals)
  }

  if (!isEmpty(formattedAnniversaryTotals.childPrograms)) {
    data.unshift(formattedAnniversaryTotals)
  }

  const completeData = [...data, accountTotals]

  return (
    <Pane>
      <Pane style={{ marginLeft: '-16px' }}>
        <Button onClick={() => setShowDrawer(true)} type="link">
          {AccountBalanceLabels.UNREDEEMED_REWARDS}
        </Button>
      </Pane>

      <Drawer
        open={showDrawer}
        placement="bottom"
        onClose={() => setShowDrawer(false)}
        title={AccountBalanceLabels.UNREDEEMED_REWARDS}
        closable
        height="85%"
      >
        <Pane display="flex" flexDirection="column" gap={16}>
          <Text>
            Total amount of active rewards, gifts, and programs minus amount
            spent and redeemed.
          </Text>

          <Table
            columns={columns}
            dataSource={completeData}
            pagination={tablePaginationSettings}
            expandable={{
              expandedRowRender: expandableContent,
              rowExpandable: record =>
                record.key === ANNIVERSARIES || record.key === BIRTHDAYS,
            }}
            tableLayout="fixed"
          />
        </Pane>
      </Drawer>
    </Pane>
  )
}
