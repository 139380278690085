import { AppstoreOutlined, SkinOutlined } from '@ant-design/icons'
import { Flex, Modal, Segmented } from 'antd'
import { SWAG_COLLECTION, SWAG_PRODUCTS } from 'constants/newReward'
import { OCCASION_COLLECTION_IDS } from 'constants/productCollections'
import {
  AlgoliaBrowseCollectionProducts,
  AlgoliaBrowseProducts,
  BrowseProductCollections,
  ProductDetails,
} from 'features'
import {
  ProductCollection,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { keys } from 'lodash-es'
import { ReactNode, useState } from 'react'
import { Selectable } from 'types'
import {
  constructUnifiedOptionsText,
  getUniqAndFormatWithSelectable,
} from 'utils/productVariant'
import { useSelectRewardContext } from '../SelectRewardContext'

function BrowseSwagProducts({
  selectedProductIds,
  onProductVariantClick,
  extraElementsInFirstRow,
  bottomOffsetAmount,
  minCollectionPermission,
}: {
  selectedProductIds: string[]
  onProductVariantClick: (
    productVariant: ProductVariant,
    allSelectedVariants: ProductVariant[]
  ) => void | Promise<void>
  extraElementsInFirstRow?: ReactNode
  bottomOffsetAmount?: number
  minCollectionPermission: ProductCollection_Permission
}) {
  const [productVariantToShow, setProductVariantToShow] =
    useState<Selectable<ProductVariant>>()

  const { productVariants } = useListAllProductVariantsByProductIds({
    productIds: selectedProductIds,
  })

  const uniqueSelectedVariants = getUniqAndFormatWithSelectable(productVariants)

  const handleCtaClick = async (productVariant: ProductVariant) => {
    setProductVariantToShow(undefined)
    await onProductVariantClick(productVariant, uniqueSelectedVariants)
  }

  return (
    <>
      <AlgoliaBrowseProducts>
        <AlgoliaBrowseProducts.Swag
          title="Select swag"
          onProductCardClick={pv => setProductVariantToShow(pv)}
          selectedVariants={uniqueSelectedVariants}
          onCardSelectButtonClick={handleCtaClick}
          showSelectedVariantsFirst={false}
          bottomOffsetAmount={bottomOffsetAmount}
          minPermission={minCollectionPermission}
          childrenNextToProductCount={extraElementsInFirstRow}
        />
      </AlgoliaBrowseProducts>
      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            showInStockText
            disableOutOfStock={false}
            onSubmit={handleCtaClick}
            disableVariantOptions
            submitButtonProps={{
              type: productVariantToShow.isSelected ? 'default' : 'primary',
              danger: productVariantToShow.isSelected,
              children: productVariantToShow.isSelected
                ? 'Remove swag'
                : 'Add swag',
            }}
            alertProps={
              keys(productVariantToShow?.options || {}).length >= 1
                ? {
                    type: 'info',
                    message: `Recipients will select ${constructUnifiedOptionsText(productVariantToShow)}`,
                  }
                : undefined
            }
          />
        )}
      </Modal>
    </>
  )
}

export function SwagForm() {
  const { selectedReward, swagForm } = useSelectRewardContext()

  const [collectionToShow, setCollectionToShow] = useState<ProductCollection>()

  const isSwagCollection =
    selectedReward?.gift?.productCollectionId &&
    !OCCASION_COLLECTION_IDS.includes(selectedReward.gift.productCollectionId)

  const [selectedTab, setSelectedTab] = useState<
    typeof SWAG_PRODUCTS | typeof SWAG_COLLECTION
  >(isSwagCollection ? SWAG_COLLECTION : SWAG_PRODUCTS)

  const swagFormToggle = (
    <Segmented
      options={[
        { value: SWAG_PRODUCTS, label: SWAG_PRODUCTS, icon: <SkinOutlined /> },
        {
          value: SWAG_COLLECTION,
          label: SWAG_COLLECTION,
          icon: <AppstoreOutlined />,
        },
      ]}
      style={{ width: 'fit-content', marginLeft: 16 }}
      className="small-custom-segment"
      size="large"
      value={selectedTab}
      onChange={setSelectedTab}
    />
  )

  if (selectedTab === SWAG_PRODUCTS) {
    return (
      <Flex vertical style={{ width: '100%', marginBottom: 64 }}>
        <BrowseSwagProducts
          minCollectionPermission={ProductCollection_Permission.send}
          selectedProductIds={selectedReward?.gift?.productIds ?? []}
          onProductVariantClick={swagForm.handleSelectSwagProductVariant}
          extraElementsInFirstRow={swagFormToggle}
          bottomOffsetAmount={73}
        />
      </Flex>
    )
  }

  return (
    <>
      <Flex
        style={{
          width: '100%',
          marginBottom: 64,
        }}
      >
        <BrowseProductCollections.Selectable
          currentProductCollectionId={selectedReward?.gift?.productCollectionId}
          firstRowSuffix={swagFormToggle}
          onCollectionSelect={swagForm.handleSelectSwagProductCollection}
          onCollectionRemoveClick={swagForm.handleRemoveCollection}
          onCollectionCardClick={c => setCollectionToShow(c)}
        />
      </Flex>

      {collectionToShow && (
        <Modal
          open={!!collectionToShow}
          title={collectionToShow.name}
          onCancel={() => {
            setCollectionToShow(undefined)
          }}
          footer={null}
          width="min(80%, 800px)"
        >
          <AlgoliaBrowseCollectionProducts
            productCollectionId={collectionToShow.id}
          />
        </Modal>
      )}
    </>
  )
}
