import { Account_Permission } from 'gen/perkup/v1/account_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import {
  Permission,
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
} from 'types/Permissions'

/**
 * Use this everywhere when calling getHighestPermission for accounts.
 * The order is very important, this is the specific hierarchy that we have for accounts.
 */
export const ACCOUNT_LEVELS = [
  Account_Permission.full,
  Account_Permission.send,
  Account_Permission.view,
]
/**
 * Use this everywhere when calling getHighestPermission for product collections.
 * The order is very important, this is the specific hierarchy that we have for product collections.
 */
export const PRODUCT_COLLECTION_LEVELS = [
  ProductCollection_Permission.full,
  ProductCollection_Permission.send,
  ProductCollection_Permission.view,
]

/**
 *
 * @param levels The order / hierarchy in which the permissions are checked.
 * @returns A function that takes in the role, individualId, and permissions. That function then returns the highest permission based on the hierarchy of levels passed in.
 */
export function getHighestPermission<T extends Permission>(levels: T[]) {
  return ({
    role,
    individualId,
    permissions,
  }: {
    role: Individual_Role
    individualId: string
    permissions: Record<string, T>
  }): T => {
    const adminPermission =
      (role === Individual_Role.admin && permissions[PERMISSION_ADMIN]) ||
      undefined
    const managerPermission =
      (role === Individual_Role.manager && permissions[PERMISSION_MANAGER]) ||
      undefined
    const individualPermission = permissions[individualId] || undefined

    const permissionBank = [
      adminPermission,
      managerPermission,
      individualPermission,
    ]

    // Depending on the heirarchy of levels passed, we immediately return the first hit. This is why the order of levels passed in is important!
    for (let i = 0; i < levels.length; i += 1) {
      const level = levels[i]
      if (permissionBank.includes(level)) return level
    }

    return Account_Permission.PERMISSION_UNSPECIFIED as T
  }
}

/**
 * Get the highest permission from an array of objects that each contain a set of permissions.
 * This function compares permissions across multiple objects and returns the highest
 * permission for each key, excluding any permissions with a value of `0`.
 *
 *
 * @param arr - An array of objects,
 * where each object contains an `from` and a `permissions` object. The `permissions` object is
 * a record of permission keys and their corresponding values of type `T`.
 *
 * @returns - A record where the keys are
 * permission names, and the values are objects containing the `from` (the object
 * where the highest permission was found) and the `permission` itself.
 */
export function getHighestPermissionByKey<T extends Permission, F>(
  arr: {
    from: F
    permissions: Record<string, T>
  }[]
) {
  type PermissionsObject = Record<
    string,
    {
      from: F
      permission: T
    }
  >
  const permissions = arr.reduce<PermissionsObject>((acc, curr) => {
    // iterate through all permissions
    // from each array element
    Object.entries(curr.permissions).forEach(([key, value]) => {
      // if the permission is higher than the current permission in the accumulator
      // then replace it
      if (!acc[key] || acc[key].permission > value) {
        // make sure we don't add 0 permissions
        // since 0 is the default value from the proto enum
        if (value !== 0) acc[key] = { from: curr.from, permission: value }
      }
    })
    return acc
  }, {})

  return permissions
}

export const formatCollectionPermission = (
  permission: ProductCollection_Permission
) => {
  switch (permission) {
    case ProductCollection_Permission.full:
      return 'Full access'
    case ProductCollection_Permission.send:
      return 'Can send'
    case ProductCollection_Permission.view:
      return 'Can view'
    default:
      return ''
  }
}
