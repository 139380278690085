import { LeftOutlined, RightOutlined, StarOutlined } from '@ant-design/icons'
import { Button, Flex, Tag } from 'antd'
import { PerkImage } from 'components'
import { WidthBreakpoints } from 'constants/layout'
import useEmblaCarousel from 'embla-carousel-react'
import { Pane } from 'evergreen-ui'
import { ProductVariant_Image } from 'gen/perkup/v1/product_variant_pb'
import {
  useDefaultOrgColors,
  usePrevNextButtons,
  useThumbnailButton,
} from 'hooks'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { EmblaStyles } from 'types/embla'

const emblaStyles: EmblaStyles = {
  embla: {
    overflow: 'hidden',
  },
  viewport: {},
  container: {
    display: 'flex',
    width: '30vw',
    minWidth: 220,
    maxWidth: 400,
  },
  slide: {
    flex: '0 0 100%',
  },
}

/**
 *
 * Used to display images in product details view.
 * Carousel logic/JSX can be extrapolated into a new compound component should we need to use it elsewhere in the future.
 */
export function ProductImages({
  productImages,
  startIndex = 0,
  isVertical = false,
  onConfirmThumbnail,
}: {
  productImages: ProductVariant_Image[]
  startIndex?: number
  isVertical?: boolean
  onConfirmThumbnail?: (thumbnail: ProductVariant_Image) => void
}) {
  const { defaultColor } = useDefaultOrgColors()

  const [isThumbnailButtonLoading, setIsThumbnailButtonLoading] =
    useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({ startIndex })

  const { selectedIndex, onThumbnailButtonClick } = useThumbnailButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  const verticalLayout = isVertical || isMobile
  const showSetThumbnailButton = !!onConfirmThumbnail
  const isSelectedImageTheThumbnailImage = selectedIndex === 0

  return (
    <Flex
      flex="1"
      gap={16}
      style={{
        flexDirection: verticalLayout ? 'column-reverse' : undefined,
        width: '100%',
      }}
      align={verticalLayout ? 'center' : undefined}
    >
      {/** THUMBNAILS */}
      <Flex
        vertical={!verticalLayout}
        gap={16}
        style={{
          overflow: 'scroll',
          maxWidth: verticalLayout ? 320 : undefined,
          maxHeight: verticalLayout ? undefined : 480,
        }}
        justify={verticalLayout ? 'start' : undefined}
      >
        {productImages.slice(0, 20).map((image, index) => {
          return (
            <Pane
              key={image.id}
              cursor="pointer"
              onClick={() => onThumbnailButtonClick(index)}
            >
              <PerkImage
                src={image.src}
                alt={image.id}
                sizes={`(max-width: ${WidthBreakpoints.XS}px) 64px,
              100px`}
                style={{
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  width: isVertical ? 64 : 100,
                  height: isVertical ? 64 : 100,
                  borderRadius: 8,
                  border:
                    selectedIndex === index
                      ? `1px solid ${defaultColor}`
                      : undefined,
                }}
              />
            </Pane>
          )
        })}
      </Flex>

      {/** MAIN CAROUSEL */}
      <Flex flex={1} vertical gap={16} align="center">
        <Pane display="flex" alignItems="center" gap={16}>
          {productImages.length > 1 && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              size="large"
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          )}
          <Pane style={emblaStyles.embla} ref={emblaRef}>
            <Pane style={emblaStyles.container}>
              {productImages.map(image => (
                <Pane key={image.src} style={emblaStyles.slide}>
                  <PerkImage
                    preview
                    src={image.src}
                    sizes={verticalLayout ? '360px' : '30vw'}
                    style={{
                      width: '100%',
                      borderRadius: 4,
                      minWidth: 220,
                      maxWidth: verticalLayout ? 360 : undefined,
                      height: 'fit-content',
                    }}
                  />
                </Pane>
              ))}
            </Pane>
          </Pane>
          {productImages.length > 1 && (
            <Button
              icon={<RightOutlined />}
              type="text"
              size="large"
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          )}
        </Pane>
        {showSetThumbnailButton && isSelectedImageTheThumbnailImage && (
          <Tag
            icon={<StarOutlined />}
            color="success"
            style={{
              fontSize: 14,
              height: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Thumbnail
          </Tag>
        )}

        {showSetThumbnailButton && !isSelectedImageTheThumbnailImage && (
          <Button
            size="small"
            loading={isThumbnailButtonLoading}
            disabled={isThumbnailButtonLoading}
            icon={<StarOutlined />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              onConfirmThumbnail(productImages[selectedIndex])
              setIsThumbnailButtonLoading(true)
            }}
          >
            Set as thumbnail
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
