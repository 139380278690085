import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Badge, Button, Flex } from 'antd'
import { PerkImage } from 'components'
import { CountryIconGroup } from 'components/Countries'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  PUBLIC_GIFT_TAG,
} from 'constants/algolia'
import { NUMBER_GREEN, NUMBER_RED } from 'constants/colors'
import { WidthBreakpoints } from 'constants/layout'
import { OrgContext, UserContext } from 'context'
import useEmblaCarousel from 'embla-carousel-react'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { ProductVariant_Image } from 'gen/perkup/v1/product_variant_pb'
import { usePrevNextButtons } from 'hooks'
import { isEmpty } from 'lodash-es'
import { PropsWithChildren, useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { EmblaStyles } from 'types/embla'
import { AlgoliaProductClicked } from 'utils/Algolia'
import { PrepaidBadge } from './PrepaidBadge'

const emblaStyles: EmblaStyles = {
  embla: {
    width: '100%',
    position: 'relative',
  },
  viewport: {
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    marginLeft: '-16px',
  },
  slide: {
    display: 'flex',
    flex: '0 0 100%',
    paddingLeft: '16px',
  },
}

/**
 * This does not follow HTML standards, as there are many buttons within the a tag.
 * However, we're making a compromise on accessibility for the sake of allowing users to swipe through the carousel images.
 */
function ConditionalLink({ to, children }: PropsWithChildren<{ to?: string }>) {
  return to ? <Link to={to}>{children}</Link> : <article>{children}</article>
}

function ProductImageArrowButton({
  direction,
  onClick,
  hidden,
}: {
  direction: 'left' | 'right'
  onClick: () => void
  hidden?: boolean
}) {
  const [isHovering, setIsHovering] = useState(false)

  const isLeft = direction === 'left'

  return (
    <Button
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      icon={isLeft ? <LeftOutlined /> : <RightOutlined />}
      type="text"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      style={{
        transition: 'all 0.2s',
        zIndex: 1,
        backgroundColor: isHovering
          ? 'rgba(255, 255, 255)'
          : 'rgba(255, 255, 255, 0.93)',
        position: 'absolute',
        left: isLeft ? 4 : undefined,
        right: isLeft ? undefined : 4,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: hidden ? 0 : 1,
      }}
    />
  )
}

export function ProductGridCard({
  toPrefix,
  onClick,
  price,
  position,
  queryID,
  eventProps,
  productId,
  productVariantId,
  productImages,
  vendor,
  productName,
  cardWidth,
  selectedRibbonText = 'Selected',
  showSelectedRibbon = false,
  outOfStock = false,
  showShipping = false,
  tagline,
  tags,
  cardCTA,
  iso3s,
  showPrepaidBadge = false,
}: {
  toPrefix?: string // Makes the card a link, to the specified to path
  onClick?: () => void
  productVariantId: string
  productId: string
  productName: string
  vendor: string | undefined
  productImages: ProductVariant_Image[]
  queryID?: string // Algolia queryID
  price?: string
  position?: number
  eventProps?: any
  cardWidth?: number
  selectedRibbonText?: string
  showSelectedRibbon?: boolean
  outOfStock?: boolean
  showShipping?: boolean
  tagline?: string
  tags: string[]
  cardCTA?: JSX.Element
  iso3s?: string[]
  showPrepaidBadge?: boolean
}) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const [emblaRef, emblaApi] = useEmblaCarousel()

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  const [cardIsHovered, setCardIsHovered] = useState(false)

  const imageFit = tags.includes(PUBLIC_GIFT_TAG) ? 'cover' : 'contain'
  const showVendor = tags.includes(PUBLIC_GIFT_TAG)
  const cardIsInteractive = !!onClick || !!toPrefix
  const showCarouselArrows =
    productImages.length > 1 && (cardIsHovered || isMobile)

  return (
    <ConditionalLink to={toPrefix ? `${toPrefix}/${productId}` : undefined}>
      <div
        style={{
          position: 'relative',
          zIndex: 0,
          height: '100%',
        }}
        onMouseEnter={() => setCardIsHovered(true)}
        onMouseLeave={() => setCardIsHovered(false)}
      >
        {showPrepaidBadge && (
          <Flex
            style={{
              position: 'absolute',
              top: 8,
              left: 8,
              zIndex: 10,
            }}
          >
            <PrepaidBadge />
          </Flex>
        )}

        {showSelectedRibbon && (
          <Badge.Ribbon
            text={selectedRibbonText}
            style={{
              position: 'absolute',
              zIndex: 1,
              top: 8,
              right: -8,
            }}
          />
        )}

        <Pane
          display="flex"
          flexDirection="column"
          padding={16}
          height="100%"
          gap={16}
          cursor={cardIsInteractive ? 'pointer' : undefined}
          onClick={() => {
            if (!onClick) return
            AlgoliaProductClicked({
              orgId: org.id,
              userId: user.id,
              userToken: user.id,
              productId,
              productVariantId,
              index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
              eventType: 'click',
              queryID,
              objectIDs: [productVariantId],
              positions: [position],
              ...eventProps,
            })
            onClick()
          }}
          borderRadius={8}
          hoverElevation={cardIsInteractive ? 1 : undefined}
          width={cardWidth || '100%'}
          overflow="hidden"
        >
          {/** IMAGES CAROUSEL */}

          <section style={emblaStyles.embla}>
            <ProductImageArrowButton
              direction="left"
              onClick={onPrevButtonClick}
              hidden={!showCarouselArrows || prevBtnDisabled}
            />

            <div style={emblaStyles.viewport} ref={emblaRef}>
              <div style={emblaStyles.container}>
                {productImages.map(image => (
                  <div key={image.src} style={emblaStyles.slide}>
                    <PerkImage
                      src={image?.src}
                      width="100%"
                      sizes={`(max-width: ${WidthBreakpoints.XS}px) 50vw, (max-width: ${WidthBreakpoints.XL}px) 25vw, 15vw`}
                      style={{
                        width: '100%',
                        aspectRatio: '1/1',
                        objectFit: imageFit,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <ProductImageArrowButton
              direction="right"
              onClick={onNextButtonClick}
              hidden={!showCarouselArrows || nextBtnDisabled}
            />
          </section>

          {/** PRODUCT INFO */}
          <Flex flex={1} align="start" vertical gap={8}>
            <Flex flex={1} vertical gap={8}>
              {showVendor && (
                <Heading size={300} color={org.primaryColor}>
                  {vendor?.toUpperCase()}
                </Heading>
              )}
              <Text className="two-line-truncate">{productName}</Text>
              {price && (
                <Flex gap={8}>
                  <Strong color={NUMBER_GREEN}>{price}</Strong>
                  {showShipping && <Text color="muted">FREE shipping</Text>}
                </Flex>
              )}
            </Flex>
            {iso3s && !isEmpty(iso3s) && <CountryIconGroup iso3s={iso3s} />}
            {outOfStock && <Text color={NUMBER_RED}>Out of stock</Text>}
            {cardCTA}
            {tagline && <Text color="muted">{tagline}</Text>}
          </Flex>
        </Pane>
      </div>
    </ConditionalLink>
  )
}
