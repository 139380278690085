import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Flex, Modal, Tooltip } from 'antd'
import { PersonalFundsPayment } from 'components/Stripe'
import { PersonalFundsDisclaimer } from 'components/Typography'
import { DEFAULT_PERSONAL_FUNDS_DEPOSIT } from 'constants/money'
import { Pane, toaster } from 'evergreen-ui'
import { SelectAmount } from 'features/Programs/SelectAmount'
import { useState } from 'react'

export function AddPersonalFundsButton({
  defaultAmount = DEFAULT_PERSONAL_FUNDS_DEPOSIT,
  asIcon = false,
  oneClickCheckout = false,
  ctaProps,
  onAfterSubmit,
}: {
  defaultAmount?: number
  asIcon?: boolean
  oneClickCheckout?: boolean
  ctaProps?: ButtonProps
  onAfterSubmit?: () => void
}) {
  const [isShown, setIsShown] = useState(false)
  const [amount, setAmount] = useState(defaultAmount)

  return (
    <Pane>
      {!asIcon && (
        <Button onClick={() => setIsShown(true)} {...ctaProps}>
          {ctaProps?.children || 'Add Personal Funds'}
        </Button>
      )}
      {asIcon && (
        <Tooltip title="Add personal funds">
          <Button
            onClick={() => setIsShown(true)}
            type="text"
            size="small"
            icon={<PlusCircleOutlined />}
            {...ctaProps}
          />
        </Tooltip>
      )}
      <Modal
        open={isShown}
        title="Add Personal Funds"
        onClose={() => setIsShown(false)}
        okText="Checkout"
        width={590}
        footer={null}
        destroyOnClose
        onCancel={() => setIsShown(false)}
      >
        <Flex vertical gap={16}>
          {!oneClickCheckout && (
            <SelectAmount amount={amount} onAmountChange={setAmount} />
          )}

          <PersonalFundsPayment
            key={amount}
            amount={amount}
            onSubmit={paymentIntent => {
              if (paymentIntent.status === 'succeeded') {
                setIsShown(false)
                toaster.success('Funds are being added to your account...', {
                  duration: 10,
                })
                if (onAfterSubmit) onAfterSubmit()
              }
            }}
          />

          <PersonalFundsDisclaimer />
        </Flex>
      </Modal>
    </Pane>
  )
}
