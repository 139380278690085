import { ContactsTwoTone, UserAddOutlined } from '@ant-design/icons'
import { Checkbox, Flex, Tooltip, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Select, { SelectProps } from 'antd/es/select'
import { LabelSelect } from 'components'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE,
} from 'constants/algolia'
import { eligibleIndividualStatuses } from 'constants/individuals'
import { OrgContext } from 'context'
import { Text, useTheme } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Program_RecipientFilters } from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors, useIndividualIds } from 'hooks'
import { useInfiniteSelectableIndividuals } from 'hooks/individuals/useInfiniteSelectableIndividuals'
import { intersectionWith } from 'lodash-es'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Configure, InstantSearch, useSearchBox } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { getIndividualIds } from 'services/individuals'
import { isEmail } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'

interface RecipientsControlCenterContextType {
  allIndividualIds: string[]
}

const RecipientsControlCenterContext =
  createContext<RecipientsControlCenterContextType>(
    {} as RecipientsControlCenterContextType
  )

type OptionRender = SelectProps['optionRender']

interface OptionData {
  key: string
  label: string
  value: string
  individual: Individual
  ref: (node?: Element | null | undefined) => void | null
  inOrgColor: string
}

const MAX_TAG_COUNT = 10
const INCREMENT_AMOUNT = 10
const GHOST_INDIVIDUAL_ID = 'ghost-individual'

const recipientOptionRender: OptionRender = ({ data, label, key }) => {
  const { individual, ref, inOrgColor } = data as OptionData
  const isGhostIndividual = individual?.id?.includes(GHOST_INDIVIDUAL_ID)

  return (
    <Flex key={key} ref={ref} justify="space-between" align="center">
      <Flex gap={8} align="center">
        <Text>{label}</Text>
        <Text color="muted" size={300}>
          {individual.email}
        </Text>
      </Flex>

      <Tooltip
        title={
          isGhostIndividual
            ? 'This individual is not yet in your organization'
            : 'This individual is already in your organization'
        }
      >
        {isGhostIndividual ? (
          <UserAddOutlined />
        ) : (
          <ContactsTwoTone twoToneColor={inOrgColor} />
        )}
      </Tooltip>
    </Flex>
  )
}

function RecipientControlCenterInput({
  selectedIds,
  selectedEmails,
  selectedFilter,
  onChange,
}: {
  selectedIds: string[]
  selectedEmails: string[]
  selectedFilter: Program_RecipientFilters | undefined
  onChange: ({
    selectedIds,
    selectedEmails,
    selectedFilter,
  }: {
    selectedIds: string[]
    selectedEmails: string[]
    selectedFilter: Program_RecipientFilters | undefined
  }) => void
}) {
  const { allIndividualIds: selectableIds } = useContext(
    RecipientsControlCenterContext
  )
  const { query, refine, clear } = useSearchBox()

  const theme = useTheme()
  const { defaultColor } = useDefaultOrgColors()

  const [messageApi, contextHolder] = message.useMessage()

  const isAllSelected = !!selectedFilter?.allSelected

  const [selectableEmails, setSelectableEmails] =
    useState<string[]>(selectedEmails)

  // This returned list of individuals not within the org is affected by the query above
  const individualsNotInOrg: Individual[] = selectableEmails.map(email => {
    return new Individual({ id: `${GHOST_INDIVIDUAL_ID}-${email}`, email })
  })

  // This returned list of individuals within the org is affected by the query above
  const { individuals: individualsInOrg, sentinelRef } =
    useInfiniteSelectableIndividuals({
      selectedIndividualIds: selectedIds,
      incrementAmount: INCREMENT_AMOUNT,
    })

  // Algolia gave us a partial list of individuals, so let's compare with the ids on program
  const selectedIndividualsInOrg = intersectionWith(
    individualsInOrg,
    selectedIds,
    (individual, id) => individual.id === id
  )

  // All of these individuals are already selected because they are already on the program
  const selectedIndividualsNotInOrg = intersectionWith(
    individualsNotInOrg,
    selectedEmails,
    (individual, email) => individual.email === email
  )

  const handleSelectIndividual = (individualId: string) => {
    const isGhostIndividual = individualId.includes(GHOST_INDIVIDUAL_ID)
    if (isGhostIndividual) {
      const email = individualId.split('-')[2]

      const newSetOfEmailsToSave = [...selectedEmails, email]

      const userJustSelectedFinalRemainingEmail =
        newSetOfEmailsToSave.length === selectableEmails.length &&
        selectedIds.length === selectableIds.length

      onChange({
        selectedIds,
        selectedEmails: newSetOfEmailsToSave,
        selectedFilter: userJustSelectedFinalRemainingEmail
          ? new Program_RecipientFilters({
              allSelected: true,
            })
          : undefined,
      })
    } else {
      const newSetOfIdsToSave = [...selectedIds, individualId]

      const userJustSelectedFinalRemainingId =
        newSetOfIdsToSave.length === selectableIds.length &&
        selectedEmails.length === selectableEmails.length

      onChange({
        selectedIds: newSetOfIdsToSave,
        selectedEmails,
        selectedFilter: userJustSelectedFinalRemainingId
          ? new Program_RecipientFilters({
              allSelected: true,
            })
          : undefined,
      })
    }
    clear()
  }

  const handleDeselectIndividual = (individualId: string) => {
    const isGhostIndividual = individualId.includes(GHOST_INDIVIDUAL_ID)
    if (isGhostIndividual) {
      const emailToRemove = individualId.split('-')[2]
      onChange({
        selectedIds,
        selectedEmails: selectedEmails.filter(
          selectedEmail => selectedEmail !== emailToRemove
        ),
        selectedFilter: undefined,
      })
    } else {
      onChange({
        selectedIds: selectedIds.filter(
          selectedId => selectedId !== individualId
        ),
        selectedEmails,
        selectedFilter: undefined,
      })
    }
    clear()
  }

  const handleAddNewEmail = () => {
    if (!isEmail(query)) {
      messageApi.open({
        type: 'warning',
        content: 'Please enter a valid email address',
      })
      return
    }

    setSelectableEmails([...selectableEmails, query])
    onChange({
      selectedIds,
      selectedEmails: [...selectedEmails, query],
      selectedFilter: undefined,
    })
    clear()
  }

  const handleSelectAll = async (e: CheckboxChangeEvent) => {
    const wantsAllToBeSelected = e.target.checked

    // When the user selects all, we want to punch in both our selecable arrays
    if (wantsAllToBeSelected) {
      onChange({
        selectedIds: selectableIds, // All the possible selectable ids
        selectedEmails: selectableEmails, // All the possible selectable emails
        selectedFilter: new Program_RecipientFilters({
          allSelected: true,
        }),
      })
    } else {
      onChange({
        selectedIds: [],
        selectedEmails: [],
        selectedFilter: undefined,
      })
    }

    clear()
  }

  const handleSelectLabel = async ({
    value,
    key,
  }: {
    value: string
    key: string
  }) => {
    const { ids: idsFromLabel } = await getIndividualIds({
      labels: { [key]: value },
      statuses: eligibleIndividualStatuses,
    })

    if (idsFromLabel.length === 0) {
      messageApi.open({
        type: 'warning',
        content: 'This label is empty',
      })
      return
    }

    onChange({
      selectedIds: idsFromLabel,
      selectedEmails: [],
      selectedFilter: new Program_RecipientFilters({
        label: JSON.stringify({ [key]: value }),
        allSelected: false,
      }),
    })
  }

  const totalRecipients = selectedIds.length + selectedEmails.length

  return (
    <>
      <Flex vertical>
        {/** TOTAL SELECTED AND FILTERS */}
        <Flex align="center" justify="space-between">
          <Text size={300}>{totalRecipients} selected</Text>

          {/** FILTER */}
          <Flex align="center">
            <LabelSelect
              selectedLabel={selectedFilter?.label}
              onLabelSelect={handleSelectLabel}
            />
            <Flex align="center" gap={8}>
              <Text size={300}>Select all</Text>
              <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
            </Flex>
          </Flex>
        </Flex>

        {/** RECIPIENTS INPUT */}
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          placeholder="Enter a name or email"
          value={[
            ...selectedIndividualsNotInOrg.map(({ id }) => id),
            ...selectedIndividualsInOrg.map(({ id }) => id),
          ]}
          onSelect={(_, option) => handleSelectIndividual(option.key)}
          onDeselect={(_, option) => handleDeselectIndividual(option.key)}
          onBlur={clear}
          onSearch={refine}
          searchValue={query}
          menuItemSelectedIcon={null}
          showSearch
          notFoundContent={
            <Text width="100%" cursor="pointer" onClick={handleAddNewEmail}>
              Add
              <Text
                backgroundColor={theme.colors.gray200}
                padding={4}
                borderRadius={4}
                marginLeft={4}
              >
                {query}
              </Text>
            </Text>
          }
          maxTagCount={MAX_TAG_COUNT}
          maxTagPlaceholder={
            totalRecipients <= MAX_TAG_COUNT
              ? ''
              : `+ ${totalRecipients - MAX_TAG_COUNT} others...`
          }
          filterOption={false}
          options={[
            ...individualsNotInOrg.filter(({ email }) => email.includes(query)),
            ...individualsInOrg.filter(
              ({ email }) =>
                !query.includes('@')
                  ? true // if the user is querying by name, show all results returned from Algolia
                  : email.includes(query) // if the user is querying by email, override the Algolia results
            ),
          ].map((individual, index) => {
            const isLastIndex = index === individualsInOrg.length - 1
            return {
              key: individual.id,
              value: individual.id,
              label: getIndividualDisplayName(individual) || individual.email,
              individual,
              ref: isLastIndex ? sentinelRef : null,
              inOrgColor: defaultColor,
            }
          })}
          optionRender={recipientOptionRender}
        />

        {/** RECIPIENTS BREAKDOWN */}
        <Flex vertical gap={4} style={{ marginTop: 8 }}>
          <Flex gap={8} align="center">
            <ContactsTwoTone
              style={{ fontSize: '14px' }}
              twoToneColor={defaultColor}
            />
            <Text size={300}>
              Recipients already in your organization: {selectedIds.length}
            </Text>
          </Flex>

          <Flex gap={8} align="center">
            <UserAddOutlined style={{ fontSize: '14px' }} />
            <Text size={300}>
              Recipients that will be added to your organization:{' '}
              {selectedEmails.length}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {contextHolder}
    </>
  )
}

function RecipientsControlCenter({ children }: PropsWithChildren) {
  const { id: orgId } = useContext(OrgContext)

  const { individualIds: allIndividualIds, hasLoaded } = useIndividualIds({
    statuses: eligibleIndividualStatuses,
  })

  const searchClient = individualsSearchClient(orgId)

  const recipientsControlCenterContext: RecipientsControlCenterContextType =
    useMemo(
      () => ({
        allIndividualIds,
      }),
      [allIndividualIds]
    )

  if (!searchClient || !hasLoaded) return null

  return (
    <InstantSearch
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      searchClient={searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure
        hitsPerPage={INCREMENT_AMOUNT}
        filters={STATUS_NONE_OR_INVITED_OR_ACTIVE}
      />
      <RecipientsControlCenterContext.Provider
        value={recipientsControlCenterContext}
      >
        {children}
      </RecipientsControlCenterContext.Provider>
    </InstantSearch>
  )
}

RecipientsControlCenter.Input = RecipientControlCenterInput

export default RecipientsControlCenter
