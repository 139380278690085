import { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { Button, Input, Modal } from 'antd'
import { UserContext } from 'context'
import { Heading, Pane, PlusIcon, Text, toaster } from 'evergreen-ui'
import {
  ShippingAddress,
  ShippingAddress_LocalizationExtensions,
} from 'gen/perkup/v1/root_user_pb'
import { StripeAddress } from 'gen/perkup/v1/stripe_data_pb'
import { capitalize, isNaN } from 'lodash-es'
import { useContext, useState } from 'react'
import { StripeAddressData } from 'types/Stripe'
import {
  minValidFieldLength,
  stripeAddressToShippingAddress,
  verifyAddress,
} from 'utils'
import { AddressForm } from './address-form'

const BR_CPF_LENGTH = 11
const BRAZIL = 'BR'

const verifyCPF = (cpf?: string) => {
  if (!cpf) return false
  if (cpf.length !== BR_CPF_LENGTH) return false
  if (isNaN(Number(cpf))) return false
  return true
}

export function AddressButton({
  addAddressCTALabel,
  submitLabel,
  dialogTitle,
  icon,
  asSmallButton = false,
  asPrimaryButton = false,
  bigButtonWidth = 312,
  bigButtonMinHeight = 192,
  defaultAddress,
  onAddressSubmit,
}: {
  addAddressCTALabel: string
  submitLabel: string
  dialogTitle: string
  icon?: JSX.Element
  asSmallButton?: boolean
  asPrimaryButton?: boolean
  bigButtonMinHeight?: number | string
  bigButtonWidth?: number | string
  defaultAddress?: ShippingAddress
  onAddressSubmit: (newAddress: ShippingAddress) => void
}) {
  const { id: userId } = useContext(UserContext)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [currentAddress, setCurrentAddress] = useState<StripeAddressData>()
  const [addressFormCompleted, setAddressFormCompleted] = useState(false)
  const [showLocalizationInput, setShowLocalizationInput] = useState(false)

  const handleModalClose = () => {
    setCurrentAddress(undefined)
    setShowAddressForm(false)
    setAddressFormCompleted(false)
    setShowLocalizationInput(false)
  }

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    if (event.complete && event.value.phone) {
      setAddressFormCompleted(true)

      const eventAddress = event.value.address

      const cityStates = ['SG', 'MC', 'VA']

      const address = new StripeAddress({
        line1: eventAddress.line1,
        line2: eventAddress.line2 ?? undefined,
        city: eventAddress.city || eventAddress.state,
        state: eventAddress.state,
        country: eventAddress.country,
        postalCode: eventAddress.postal_code || '00000',
      })
      if (cityStates.includes(eventAddress.country)) {
        address.city = ''
        address.state = ''
      }

      const fullAddress: StripeAddressData = {
        name: event.value.name,
        address,
        phone: event.value.phone,
      }
      setCurrentAddress(fullAddress)
    } else setAddressFormCompleted(false)

    if (event.value.address.country === BRAZIL) {
      // If Brazil, show CFP input field
      setShowLocalizationInput(true)
    } else if (showLocalizationInput) {
      setShowLocalizationInput(false)

      if (currentAddress) {
        setCurrentAddress({
          ...currentAddress,
          localizationExtensions: undefined,
        })
      }
    }
  }

  const handleConfirmAddress = () => {
    if (!addressFormCompleted) {
      toaster.warning('Please enter a valid address & phone number')
      const stripeModal = document.querySelector('.ant-modal-body')
      if (stripeModal) {
        stripeModal.scrollTo(0, 100000)
      }
      return
    }

    if (
      currentAddress?.address?.country === BRAZIL &&
      !verifyCPF(currentAddress?.localizationExtensions?.shippingCredentialBr)
    ) {
      toaster.warning('Please enter a valid CPF')
      const stripeModal = document.querySelector('.ant-modal-body')
      if (stripeModal) {
        stripeModal.scrollTo(0, 100000)
      }
      return
    }

    if (!currentAddress || !userId) return

    const stripeToShippingAddress = new ShippingAddress({
      ...currentAddress.address,
      name: currentAddress.name,
    })

    const addressValidation = verifyAddress(stripeToShippingAddress)
    const { invalidFields } = addressValidation
    if (!addressValidation.validationSuccessful) {
      const message = 'Invalid address'
      let description = ''
      invalidFields.forEach((field, index) => {
        const fieldDisplayValue = (field: keyof ShippingAddress) => {
          if (field === 'postalCode') return 'Postal code'
          if (field === 'line1') return 'Address line 1'
          if (field === 'name') return 'First and last name'
          return capitalize(field.toString())
        }
        // If more than 1 invalid field, list them by number
        description += `${
          invalidFields.length > 1 ? `${index + 1}. ` : ''
        } ${fieldDisplayValue(
          field
        )} must be at least ${minValidFieldLength} characters long. <br />`
      })

      toaster.warning(message, {
        description: <Text dangerouslySetInnerHTML={{ __html: description }} />,
      })
      return
    }
    const convertedAddress = stripeAddressToShippingAddress(currentAddress)
    onAddressSubmit(
      new ShippingAddress({
        ...convertedAddress,
        id: defaultAddress?.id,
      })
    )
    handleModalClose()
  }

  return (
    <>
      {!asSmallButton ? (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={bigButtonWidth}
          minHeight={bigButtonMinHeight}
          border
          borderStyle="dashed"
          cursor="pointer"
          onClick={() => {
            setShowAddressForm(true)
          }}
          gap={8}
        >
          <PlusIcon />

          <Heading size={500}>{addAddressCTALabel}</Heading>
        </Pane>
      ) : (
        <Button
          type={asPrimaryButton ? 'primary' : 'default'}
          style={{ width: 'fit-content' }}
          onClick={() => setShowAddressForm(true)}
          icon={icon}
        >
          {addAddressCTALabel}
        </Button>
      )}

      <Modal
        okText={submitLabel}
        closeIcon={null}
        open={showAddressForm}
        afterClose={handleModalClose}
        onOk={handleConfirmAddress}
        onCancel={handleModalClose}
        width={720}
        styles={{
          body: {
            height: '60vh',
            overflow: 'auto',
          },
        }}
        zIndex={1000}
        destroyOnClose
      >
        <Pane>
          <Heading size={600} marginBottom={24}>
            {dialogTitle}
          </Heading>

          <AddressForm
            defaultAddress={defaultAddress}
            onAddressChange={handleAddressChange}
          />
          {showLocalizationInput && (
            <Pane
              paddingX={16}
              display="flex"
              flexDirection="column"
              gap={8}
              marginTop={16}
            >
              <Text>CPF (Cadastro de Pessoa Física) number</Text>
              <Input
                size="large"
                defaultValue={
                  defaultAddress?.localizationExtensions?.shippingCredentialBr
                }
                onChange={e => {
                  if (!currentAddress) return

                  const { value } = e.target

                  const localizationExtensions =
                    new ShippingAddress_LocalizationExtensions({
                      shippingCredentialBr: value,
                    })
                  setCurrentAddress({
                    ...currentAddress,
                    localizationExtensions,
                  })
                }}
              />
            </Pane>
          )}
        </Pane>
      </Modal>
    </>
  )
}
