import { Alert, AlertProps, Button, ButtonProps, Flex, message } from 'antd'
import { CountryIconGroup, ProductImages, ProductOptions } from 'components'
import { MetricsUpdater } from 'components/ProductVariants/MetricsUpdater'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/VariantEstimatedShippingTime'
import { Slot, getSlots } from 'components/Slot'
import { PUBLIC_GIFT_TAG } from 'constants/algolia'
import { NUMBER_GREEN } from 'constants/colors'
import { PRODUCT_VARIANT_SELECTED } from 'constants/events'
import { IN_STOCK } from 'constants/productVariants'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_Image,
} from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import { useIsFlagged } from 'hooks/useIsFlagged'
import parse from 'html-react-parser'
import { compact } from 'lodash-es'
import { PropsWithChildren, useContext, useState } from 'react'
import { SelectedVariantOptions } from 'types'
import { logEvent, numToDollars } from 'utils'
import {
  constructUnifiedOptionsText,
  determineInventoryStatus,
  extractProductCategoryName,
  getProductVariantOptions,
  isNearCashProductVariant,
} from 'utils/productVariant'

interface DefaultSelected {
  options?: SelectedVariantOptions
  filterOtherOptions: boolean
}

const SLOTS = [
  'aboveSubmit',
  'nextToSubmit',
  'underSubmit',
  'aboveOptions',
  'shippingEstimation',
] as const

function ProductDetails({
  productVariant: defaultVariant,
  defaultSelected,
  withAmountInUsd = false,
  showPrice = false,
  showShipping = false,
  showShippingCountries = false,
  showInStockText = false,
  isVerticalLayout = false,
  disableVariantOptions = false,
  disableOutOfStock = true,
  disableSubmit = false,
  submitButtonProps = {
    type: 'primary',
    children: 'Select',
  },
  onSubmit,
  onSelectedVariantChange,
  alertProps,
  maxAmount,
  children,
}: PropsWithChildren<{
  productVariant: ProductVariant
  defaultSelected?: DefaultSelected
  withAmountInUsd?: boolean
  showPrice?: boolean
  showShipping?: boolean
  showShippingCountries?: boolean
  showInStockText?: boolean
  isVerticalLayout?: boolean
  disableVariantOptions?: boolean
  disableOutOfStock?: boolean
  disableSubmit?: boolean
  submitButtonProps?: ButtonProps
  onSubmit?: (productVariant: ProductVariant) => void
  onSelectedVariantChange?: (productVariant?: ProductVariant) => void
  alertProps?: AlertProps
  maxAmount?: number
}>) {
  const {
    productId,
    productName,
    categories,
    description,
    options,
    vendor,
    tags,
    shippingCountries,
    productTagline,
  } = defaultVariant

  const {
    aboveSubmit,
    nextToSubmit,
    underSubmit,
    aboveOptions,
    shippingEstimation,
  } = getSlots(SLOTS, children)

  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const exchangeRate = useContext(ExchangeRateContext)

  const [messageApi, contextHolder] = message.useMessage()
  const isFlagged = useIsFlagged()
  const { defaultColor } = useDefaultOrgColors()

  const isNearCash = isNearCashProductVariant(defaultVariant)
  const secondLevelCategoryName = extractProductCategoryName(categories, 1)
  const optionKeys = Object.keys(options || {})
  const hasOptions = optionKeys.length > 0
  const hasOptionChoice =
    hasOptions && optionKeys.every(key => options[key].values.length > 1)

  const [userClickedSubmit, setUserClickedSubmit] = useState(false)
  const [selectedProductVariant, setSelectedProductVariant] = useState(
    hasOptionChoice && !defaultSelected?.options ? undefined : defaultVariant
  )

  const handleSubmit = () => {
    if (!onSubmit) return

    setUserClickedSubmit(true)

    // Sometimes we want to select all variants at once
    if (disableVariantOptions) {
      onSubmit(defaultVariant)
      return
    }

    // We required something to be selected, and there isn't one selected
    if (!selectedProductVariant) {
      const unifiedOptionsText = constructUnifiedOptionsText(defaultVariant)

      messageApi.open({
        key: '1',
        type: 'info',
        content: `Please select a ${unifiedOptionsText || 'variant'}`,
      })
      return
    }

    logEvent(PRODUCT_VARIANT_SELECTED, {
      userId: user.id,
      orgId: org.id,
      userToken: user.id,
      productVariant: selectedProductVariant.id,
      ...selectedProductVariant,
    })
    onSubmit(selectedProductVariant)
  }

  const handleSelectedVariantChange = (productVariant?: ProductVariant) => {
    setSelectedProductVariant(productVariant)
    if (onSelectedVariantChange) onSelectedVariantChange(productVariant)
  }

  const indexToSliceDescription = isNearCash // Plum descriptions need to be sliced at the first colon
    ? description?.indexOf(':')
    : undefined

  const slicedProductDescription = indexToSliceDescription
    ? description?.slice(indexToSliceDescription + 1)
    : description

  const displayAmountInUsd = numToDollars(
    Number(selectedProductVariant?.amount || defaultVariant.amount)
  )
  const displayAmountInLocal = numToDollars(
    Number(selectedProductVariant?.amount || defaultVariant.amount) *
      exchangeRate,
    2,
    false,
    user.displayCurrency || org?.displayCurrency
  )
  const displayAmount = withAmountInUsd
    ? displayAmountInUsd
    : displayAmountInLocal

  const vendorTitle = isNearCash ? productName : vendor

  const inventoryStatus = determineInventoryStatus({
    productVariants: [selectedProductVariant || defaultVariant],
    displayInStockThreshold: 100,
    level: selectedProductVariant ? 'variant' : 'product',
  })

  const hideInStockText = inventoryStatus.text === IN_STOCK && !showInStockText // Hide "In stock" if it's the only thing to show and we're in member view

  const selectedProductAmount = selectedProductVariant?.amount

  const selectedProductImageIndex = defaultVariant.productImages.findIndex(
    image => selectedProductVariant?.imageUrl === image.src
  )

  // Don't show vendor for swag - ever
  const showVendor = tags.includes(PUBLIC_GIFT_TAG) || isNearCash

  const selectedVariantTooExpensive =
    !!maxAmount && !!selectedProductAmount && selectedProductAmount > maxAmount

  const selectedVariantOutOfStock =
    (!defaultVariant.productIsAvailable ||
      (selectedProductVariant && !selectedProductVariant?.isAvailable)) &&
    disableOutOfStock

  const subheading = productTagline || secondLevelCategoryName

  // Temporary fallback image for Plum, unfortunately these product variants don't have a productImage array (seems like just a local problem?), so we need to create a new one...
  const tempFallBackImageForPlum: ProductVariant_Image =
    new ProductVariant_Image({
      id: 'plum-fallback',
      src: defaultVariant.imageUrl,
    })

  const productImagesToRender =
    defaultVariant.productImages.length === 0
      ? [tempFallBackImageForPlum]
      : defaultVariant.productImages.slice(0, 20) // Limit to 20 images for the products variants that do have productImages

  return (
    <>
      {contextHolder}
      <Flex
        vertical={isVerticalLayout}
        gap={16}
        wrap="wrap"
        style={{ width: '100%' }}
      >
        <ProductImages
          productImages={productImagesToRender}
          startIndex={selectedProductImageIndex}
          isVertical={isVerticalLayout}
        />

        <Flex flex="1" vertical gap={16} style={{ maxWidth: '100%' }}>
          <Flex vertical gap={8}>
            {showVendor && (
              <Heading size={400} color={defaultColor}>
                {vendorTitle?.toUpperCase()}
              </Heading>
            )}
            <Heading size={800}>{productName}</Heading>
            {subheading && (
              <Text textTransform="capitalize" color="muted">
                {subheading}
              </Text>
            )}
            <Flex gap={16}>
              {showPrice && (
                <Flex gap={8}>
                  <Text color={NUMBER_GREEN}>{displayAmount}</Text>
                  {!isNearCash && showShipping && (
                    <Text color="muted">FREE shipping</Text>
                  )}
                </Flex>
              )}
              {!hideInStockText && (
                <Text color={inventoryStatus.color}>
                  {inventoryStatus.text}
                </Text>
              )}
            </Flex>
          </Flex>

          {alertProps && <Alert {...alertProps} />}

          {aboveOptions}

          {hasOptions && (
            <ProductOptions
              key={productId}
              product={defaultVariant}
              onSelectProductVariant={handleSelectedVariantChange}
              defaultOptions={
                !hasOptionChoice
                  ? getProductVariantOptions({ productVariant: defaultVariant })
                  : defaultSelected?.options
              }
              maxBudget={maxAmount}
              hideOptionsAlerts={!userClickedSubmit}
              asStatic={disableVariantOptions}
              filterNonSelected={defaultSelected?.filterOtherOptions}
            />
          )}

          {aboveSubmit}

          <Flex vertical gap={8}>
            <Flex gap={8}>
              {!!onSubmit && (
                <Button
                  style={{ flex: 1 }}
                  type="primary"
                  disabled={
                    selectedVariantOutOfStock ||
                    selectedVariantTooExpensive ||
                    disableSubmit
                  }
                  onClick={handleSubmit}
                  {...submitButtonProps}
                />
              )}
              {nextToSubmit}
            </Flex>
            {selectedVariantTooExpensive && (
              <Alert message="Variant not available" type="warning" showIcon />
            )}
            {underSubmit}
          </Flex>

          {showShippingCountries && compact(shippingCountries).length > 0 && (
            <CountryIconGroup
              iso3s={shippingCountries}
              label={isNearCash ? 'Available in' : 'Ships to'}
            />
          )}

          {shippingEstimation || (
            <VariantEstimatedShippingTime productVariant={defaultVariant} />
          )}

          {slicedProductDescription && (
            <Text overflow="scroll" maxHeight={300}>
              {parse(slicedProductDescription)}
            </Text>
          )}
          {isFlagged && (
            <Text size={300} color="muted">
              PRODUCT ID: {productId}
              <br />
              PRODUCT VARIANT ID: {defaultVariant.id}
            </Text>
          )}
          {isFlagged && <MetricsUpdater productVariant={defaultVariant} />}
        </Flex>
      </Flex>
    </>
  )
}

ProductDetails.Slot = Slot<(typeof SLOTS)[number]> // Expose the slots for the parent component to use. User of <ProductDetails /> can define as many <ProductDetails.Slot /> components as they want

export default ProductDetails
