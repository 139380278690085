import { SwapOutlined } from '@ant-design/icons'
import { ConnectError } from '@connectrpc/connect'
import { captureException } from '@sentry/react'
import { Alert, Button, Flex } from 'antd'
import {
  completeUserOnboarding,
  createShippingAddress,
  updateUserProfile,
} from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { AddressDisplay, ItemInfo, Loader } from 'components'
import { ChangeAddressButton } from 'components/Addresses'
import { SwapGiftButton } from 'components/Buttons/SwapGiftButton'
import { UserNameForm } from 'components/Forms/UserNameForm'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/VariantEstimatedShippingTime'
import { CONFIRM_NAME_CLICKED } from 'constants/events'
import {
  REWARD_ACCEPTANCE,
  REWARD_ACCEPTANCE_CONFIRMATION,
  REWARD_ACCEPTANCE_REVEAL,
} from 'constants/routes'
import { DRAW_FROM_PERSONAL_FUNDS } from 'constants/sessionOrLocalStorage'
import {
  CountryContext,
  IndividualContext,
  MemberContext,
  OrgContext,
  ProgramContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import { Heading, Pane, Text, Tooltip, toaster } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_Provider,
} from 'gen/perkup/v1/product_variant_pb'
import { Item, Member_ConvertedTo } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { VendorProduct } from 'gen/perkup/v1/vendor_pb'
import {
  useCollectionById,
  useGiftRedeemed,
  useListenToPersonalFundsProgram,
  useMemberships,
  useShopifyCalcDraftOrder,
} from 'hooks'
import { isEmpty } from 'lodash-es'
import toLower from 'lodash-es/toLower'
import toUpper from 'lodash-es/toUpper'
import { validateFullName } from 'pages/NewReward/utils/uiUtils'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { placeAmazonOrder } from 'services/amazon'
import { placeManualOrder } from 'services/manual'
import { AmazonProduct, PageDirection } from 'types'
import { ConvertableTo } from 'types/Gift'
import {
  buildFullName,
  getAmazonOrderAmounts,
  getAmazonUnitAmount,
  getCountryIsoAlpha2,
  getCountryNameFromIso2,
  isSwappableGift,
  logEvent,
} from 'utils'
import { insertElementIf } from 'utils/arrays'
import { getOrderErrorMessage } from 'utils/errors'
import { invalidCollectionShippingCountry } from 'utils/productCollections'
import {
  buildProductVariantDisplayName,
  getProductVariantOutOfStockMessage,
  invalidShippingCountryVariants,
  isNearCashProductVariant,
  saveProductVariantsToSessionStorage,
} from 'utils/productVariant'
import { useProductInfo, useProductType } from 'utils/rewardAcceptance'
import { isUnavailableInCountry } from 'utils/vendors'
import { AnimatedPage } from './components'
import { GiftAcceptanceAlert } from './components/GiftAcceptanceAlert'
import { RewardGiftThumbnail } from './components/RewardThumbnails'
import {
  useMemberProduct,
  useMemberVendorProductPrices,
  useProductVariantsFromSessionStorage,
} from './hooks'

interface DisabledButtonAlert {
  title: string
  isShown: boolean
}

function ButtonAlert({
  disabledButtonAlerts,
}: {
  disabledButtonAlerts: DisabledButtonAlert[]
}) {
  const shownAlert = disabledButtonAlerts.find(
    disabledButtonAlert => disabledButtonAlert.isShown
  )
  if (!shownAlert) return null
  return <Alert type="warning" showIcon message={shownAlert.title} />
}

export function RewardAcceptanceCheckoutPage() {
  const org = useContext(OrgContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const program = useContext(ProgramContext)
  const { firstName: defaultFirstName, lastName: defaultLastName } =
    useContext(IndividualContext)

  const { id: programId } = program
  const orgId = org?.id

  const user = useContext(UserContext)
  const userId = user?.id

  const member = useContext(MemberContext)
  const memberId = member.id
  const country = useContext(CountryContext)
  const giftRedeemed = useGiftRedeemed()

  const {
    isAmazonGift,
    isProductVariantGift,
    isVendorGift,
    isCollectionGift,
    isMultiProductGift,
  } = useProductType()

  const navigate = useNavigate()

  const { memberships } = useMemberships()

  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState(defaultFirstName || '')
  const [lastName, setLastName] = useState(defaultLastName || '')
  const [shippingAddress, setShippingAddress] = useState<
    ShippingAddress | undefined
  >(shippingAddresses.find(sa => sa.id === user.defaultShippingAddressId))

  const {
    savedVariants,
    setSavedVariants,
    isLoading: isLoadingProductVariants,
  } = useProductVariantsFromSessionStorage({ programId })

  const { program: personalFundsProgram } = useListenToPersonalFundsProgram()

  const { collection } = useCollectionById({
    id: program?.gift?.productCollectionId,
  })

  const invalidCountryVariants = invalidShippingCountryVariants({
    productVariants: savedVariants,
    shippingAddress,
  })

  const invalidCollectionCountry = invalidCollectionShippingCountry({
    collection,
    shippingAddress,
  })

  const invalidShippingCountry =
    invalidCollectionCountry || !isEmpty(invalidCountryVariants)

  // Gets programMemberProduct if ITS AMAZON OR VENDOR GIFT.
  const { product: programMemberProduct } = useMemberProduct({
    shippingAddress,
  })

  const { productTitle, productVendorTitle, productImage } = useProductInfo({
    product: programMemberProduct,
    selectedProductVariants: savedVariants,
    isAmazonGift,
    isProductVariantGift,
    isVendorGift,
    isCollectionGift,
    isMultiProductGift,
  })

  const isMultipleAcceptanceGift = isCollectionGift || isMultiProductGift

  const { vendorProductPrices } = useMemberVendorProductPrices()

  const items = useMemo(() => {
    return savedVariants.map(productVariant => {
      return new Item({
        productVariantId: productVariant.id,
        quantity: 1,
        provider: productVariant.provider,
        productId: productVariant.productId,
      })
    })
  }, [savedVariants])

  const { draftOrderCalculation } = useShopifyCalcDraftOrder({
    programItems: items,
    address: shippingAddress,
  })

  const membership = memberships.find(m => m?.program?.id === program.id)

  const convertableTo = program?.gift?.convertableTo
  const canSwapForGiftOrCash =
    convertableTo?.includes(ConvertableTo.cash) &&
    convertableTo?.includes(ConvertableTo.gift)

  const isGroupGift = !!program.gift?.groupId
  const isGiftSwappable = isSwappableGift({ gift: program?.gift })

  const wasAccepted = membership?.member ? membership?.member?.spent > 0 : false

  // VendorProduct availability
  const isAddressCountryMismatch =
    toLower(shippingAddress?.country) !==
    getCountryIsoAlpha2(toLower(country.iso3))

  const isUnavailableInUserCountry = isUnavailableInCountry({
    programBudget: program.budget,
    userCountryCode: country.iso3,
    productPrices: vendorProductPrices,
  })

  const isVendorProductUnavailable =
    isVendorGift && (isAddressCountryMismatch || isUnavailableInUserCountry)

  const isGroupGiftUnavailable = isVendorProductUnavailable && isGroupGift

  const isConvertedToGift =
    member?.convertedTo === Member_ConvertedTo.alternativeGift

  // AmazonProduct availability
  const isAmazonProductUnavailable =
    isAmazonGift && toLower(shippingAddress?.country) !== program?.gift?.country

  const outOfStockProductVariant = savedVariants.some(pv => !pv.isAvailable)

  useEffect(() => {
    if (outOfStockProductVariant) {
      const errorMessage = getProductVariantOutOfStockMessage({
        variants: savedVariants,
      })
      toaster.warning('The following items have insufficient inventory:', {
        description: (
          <Text dangerouslySetInnerHTML={{ __html: errorMessage }} />
        ),
      })
    }
  }, [outOfStockProductVariant, savedVariants])

  const disabledButtonAlerts: DisabledButtonAlert[] = [
    {
      title:
        'Selected gift not available, swap gift or change shipping address.',
      isShown: isVendorProductUnavailable,
    },
    {
      title: `Item only ships to ${toUpper(program?.gift?.country)}`,
      isShown: isAmazonProductUnavailable,
    },
    {
      title: `Item not available in ${getCountryNameFromIso2(
        shippingAddress?.country
      )}. Update your country and select something else.`,
      isShown: invalidShippingCountry,
    },
  ]

  const handleNavigateBack = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        programId || ''
      )}${REWARD_ACCEPTANCE_REVEAL}`,
      { state: { direction: PageDirection.BACK } }
    )
  }

  const handleNavigateForward = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        programId || ''
      )}${REWARD_ACCEPTANCE_CONFIRMATION}`,
      { state: { direction: PageDirection.FORWARD, confetti: true } }
    )
  }

  const autoOnboard = () => {
    if (user?.onboarding?.complete === false) {
      logEvent(CONFIRM_NAME_CLICKED, { orgId, userId })

      updateUserProfile({ userId: user.id, data: { firstName, lastName } })
    }
    completeUserOnboarding({ userId: user.id })
  }

  const handleAcceptance = async () => {
    const validFullName = validateFullName({ firstName, lastName })
    if (!validFullName) return

    const shippingAddressToUse = program?.predefinedAddress || shippingAddress

    if (!shippingAddressToUse) {
      toaster.warning('Shipping address missing, please add address')
      captureException('Missing user shipping address', {
        contexts: {
          RewardPreview: {
            shippingAddress,
            user,
          },
        },
      })
      return
    }

    if (isAmazonGift) {
      const amazonProduct = programMemberProduct as AmazonProduct
      const offer =
        amazonProduct?.includedDataTypes?.OFFERS &&
        amazonProduct?.includedDataTypes?.OFFERS[0]

      if (!offer) {
        toaster.warning(
          'Sorry, this product is not available anymore on Amazon Business'
        )

        captureException('No offer available on Amazon Business', {
          contexts: {
            AmazonCheckout: { amazonProduct, offer, user },
          },
        })

        return
      }

      const unitAmount = getAmazonUnitAmount({ offer, useListPrice: true })

      if (!unitAmount) {
        toaster.warning(
          'Something went wrong with this product, please contact support'
        )

        captureException('Missing unit amount', {
          contexts: {
            AmazonCheckout: { amazonProduct, user, unitAmount },
          },
        })
        return
      }

      autoOnboard()

      const { itemTotal, estimatedTax, orderTotal } = getAmazonOrderAmounts({
        unitAmount,
        quantity: 1,
        isGift: true,
      })

      const addrOrganization =
        shippingAddressToUse?.name ?? buildFullName({ firstName, lastName })

      const imageUrl = amazonProduct?.includedDataTypes?.IMAGES?.length
        ? amazonProduct.includedDataTypes.IMAGES[0].medium.url
        : undefined

      setIsLoading(true)

      placeAmazonOrder({
        addrOrganization,
        offer,
        product: amazonProduct,
        shippingAddress: shippingAddressToUse,
        unitAmount,
        itemTotal,
        estimatedTax,
        orderTotal,
        quantity: 1,
        imageUrl,
        programIds: [program.id],
        orgId,
        userId,
      })
        .then(response => {
          if (response) {
            toaster.success('Gift successfully accepted')

            callFunction('firestore-UpdateMemberActions', {
              orgId,
              programId: program.id,
              memberId,
              action: 'accepted',
            })

            handleNavigateForward()
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (isProductVariantGift || isMultipleAcceptanceGift) {
      if (isEmpty(savedVariants)) {
        toaster.warning(`Please select options for this product`)
        return
      }

      autoOnboard()

      setIsLoading(true)

      const drawFromPfStorage = sessionStorage.getItem(
        `${programId}_${DRAW_FROM_PERSONAL_FUNDS}`
      )
      const canDrawFromPersonalFunds = drawFromPfStorage
        ? JSON.parse(drawFromPfStorage)
        : false

      const programIds = [
        program.id,
        ...insertElementIf(canDrawFromPersonalFunds, personalFundsProgram?.id),
      ]

      placeManualOrder({
        firstName,
        lastName,
        shippingAddress: shippingAddressToUse,
        items,
        programIds,
        shippingRateHandle:
          draftOrderCalculation?.shippingLine?.shippingRateHandle,
        orgId,
        userId,
        noPerkupFee: true,
      })
        .then(response => {
          if (response) {
            toaster.success('Gift successfully accepted')
            callFunction('firestore-UpdateMemberActions', {
              orgId,
              programId: program.id,
              memberId,
              action: 'accepted',
            })

            handleNavigateForward()
          }
        })
        .catch((error: ConnectError) => {
          const { title, description } = getOrderErrorMessage({
            error,
          })

          toaster.warning(title, {
            description: description ? (
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            ) : null,
          })
        })
        .finally(() => setIsLoading(false))
    }

    if (isVendorGift) {
      const vendorProduct = programMemberProduct as VendorProduct

      autoOnboard()

      setIsLoading(true)

      const orderItem = new Item({
        productVariantId: vendorProduct.id,
        quantity: 1,
        provider: ProductVariant_Provider.manual,
      })

      placeManualOrder({
        shippingAddress: shippingAddressToUse,
        firstName,
        lastName,
        items: [orderItem],
        programIds: [program.id],
        orgId,
        userId,
      })
        .then(response => {
          if (response) {
            toaster.success('Gift successfully accepted')

            callFunction('firestore-UpdateMemberActions', {
              orgId,
              programId: membership?.program?.id || program.id,
              memberId,
              action: 'accepted',
            })

            handleNavigateForward()
          }
        })
        .catch((error: ConnectError) => {
          const { title, description } = getOrderErrorMessage({
            error,
          })

          toaster.warning(title, {
            description: description ? (
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            ) : null,
          })
        })

        .finally(() => setIsLoading(false))
    }
  }

  const handleSwapClick = (product: ProductVariant) => {
    saveProductVariantsToSessionStorage({
      productVariants: [product],
      programId: program?.id,
    })
    setSavedVariants([product])
  }

  const missingShippingAddress = !shippingAddress && !program?.predefinedAddress

  const disableAcceptBtn =
    missingShippingAddress ||
    wasAccepted ||
    firstName.length <= 0 ||
    lastName.length <= 0 ||
    isVendorProductUnavailable ||
    isAmazonProductUnavailable ||
    giftRedeemed ||
    outOfStockProductVariant ||
    invalidShippingCountry

  const validDefaultName = validateFullName({
    firstName: defaultFirstName,
    lastName: defaultLastName,
    throwWarnings: false,
  })

  // Allow swap if: gift is swappable, group gift is unavailable, or gift has already been swapped
  const allowGiftSwap =
    isGiftSwappable || isGroupGiftUnavailable || isConvertedToGift

  if (isLoadingProductVariants) return <Loader />

  const giftCTA = 'Accept gift'

  return (
    <AnimatedPage>
      <Pane
        display="flex"
        flexDirection="column"
        gap={32}
        width={isMobile ? undefined : 720}
      >
        <Flex vertical gap={16}>
          {!isMultipleAcceptanceGift && (
            <RewardGiftThumbnail
              imageUrl={productImage}
              productTitle={productTitle}
              companyTitle={isVendorGift ? productVendorTitle : undefined}
              programTitle={program.name}
              imageFit={
                savedVariants[0] && isNearCashProductVariant(savedVariants[0])
                  ? 'contain'
                  : 'cover'
              }
            />
          )}
          {isMultipleAcceptanceGift && (
            <Flex vertical gap={16}>
              {savedVariants.map(pv => {
                const displayName = buildProductVariantDisplayName({
                  productVariant: pv,
                })
                return (
                  <ItemInfo
                    image={pv.imageUrl}
                    name={displayName}
                    key={pv.id}
                    containerStyles={{
                      justifyContent: 'center',
                    }}
                    allowRemoval
                    iconButtonStyles={{ alignSelf: 'center' }}
                    imageFit={
                      isNearCashProductVariant(pv) ? 'contain' : 'cover'
                    }
                    removeIcon={
                      <Tooltip content="Swap gift">
                        <SwapOutlined />
                      </Tooltip>
                    }
                    onRemoveItem={() => {
                      const filteredVariants = savedVariants.filter(
                        variant => variant.id !== pv.id
                      )
                      saveProductVariantsToSessionStorage({
                        productVariants: filteredVariants,
                        programId,
                      })
                      handleNavigateBack()
                    }}
                  />
                )
              })}
            </Flex>
          )}
          {!isLoading && <GiftAcceptanceAlert />}
        </Flex>

        {!validDefaultName && (
          <UserNameForm
            firstName={firstName}
            lastName={lastName}
            onFirstNameChange={setFirstName}
            onLastNameChange={setLastName}
          />
        )}

        <Flex vertical gap={32}>
          <Heading size={600}>Shipping details</Heading>
          {program?.predefinedAddress ? (
            <Flex vertical gap={16}>
              <AddressDisplay address={program?.predefinedAddress} />
              <Alert
                type="info"
                showIcon
                message="Shipping address set by sender."
                style={{ width: 'fit-content' }}
              />
            </Flex>
          ) : (
            <ChangeAddressButton
              selectedAddress={shippingAddress}
              shippingAddresses={shippingAddresses}
              onAddressChange={sa => {
                setShippingAddress(sa)
                if (sa && !sa?.id) {
                  createShippingAddress({
                    shippingAddress: sa,
                    userId: user.id,
                  })
                }
              }}
            />
          )}
          <VariantEstimatedShippingTime
            productVariant={savedVariants[0]}
            shippingAddress={shippingAddress}
          />
        </Flex>

        <Flex vertical gap={16}>
          <Flex vertical align={isMobile ? undefined : 'end'} gap={16}>
            <Flex
              gap={8}
              style={{ flexDirection: isMobile ? 'column' : 'row-reverse' }}
            >
              <Button
                type="primary"
                onClick={handleAcceptance}
                disabled={disableAcceptBtn}
                loading={isLoading}
              >
                {giftCTA}
              </Button>
              {allowGiftSwap && (
                <SwapGiftButton
                  program={program}
                  memberId={memberId}
                  onSwap={handleSwapClick}
                />
              )}
            </Flex>

            {canSwapForGiftOrCash && (
              <Text size={400}>
                By clicking {giftCTA} you are confirming your selection. This
                cannot be undone.
              </Text>
            )}
          </Flex>

          {shippingAddress && (
            <Flex justify="center">
              <ButtonAlert disabledButtonAlerts={disabledButtonAlerts} />
            </Flex>
          )}
        </Flex>
      </Pane>
    </AnimatedPage>
  )
}
