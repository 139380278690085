import {
  CheckCircleOutlined,
  EditOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Badge, Button, Flex, Popover, Tag, Tooltip } from 'antd'
import { CountriesWidget, PerkImage, PermissionsBreakdown } from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import {
  ProductCollection,
  ProductCollection_CollectionAccess_Enum,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { keys } from 'lodash-es'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Mode } from 'types/edit'
import { makePlural, numToDollars } from 'utils'
import {
  PRODUCT_COLLECTION_LEVELS,
  getHighestPermission,
} from 'utils/permissions'
import { getCollectionRange } from 'utils/productCollections'

interface ProductCollectionCardContextType {
  productCollection: ProductCollection
  isHovered: boolean
}

const ProductCollectionCardContext =
  createContext<ProductCollectionCardContextType>(
    {} as ProductCollectionCardContextType
  )

function ProductCollectionCardMenu() {
  const { productCollection, isHovered } = useContext(
    ProductCollectionCardContext
  )

  const navigate = useNavigate()

  const { permissions, shippingCountries, access } = productCollection

  const { role, id: individualId } = useContext(IndividualContext)

  const highestCollectionPermission = getHighestPermission(
    PRODUCT_COLLECTION_LEVELS
  )({
    role,
    individualId,
    permissions,
  })

  const individualHasFullAccess =
    highestCollectionPermission === ProductCollection_Permission.full
  const isStorePublished =
    access === ProductCollection_CollectionAccess_Enum.public

  if (!isHovered) return null

  return (
    <Flex
      style={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }}
      gap={8}
    >
      <Popover
        placement="bottomRight"
        content={
          <Flex gap={16} style={{ padding: 8 }}>
            <PermissionsBreakdown permissions={productCollection.permissions} />

            {isStorePublished && (
              <Flex vertical>
                <Text color="muted">Available in</Text>
                <CountriesWidget
                  title="Available in"
                  countryIso3s={shippingCountries}
                  onCountriesChange={console.log}
                />
              </Flex>
            )}
          </Flex>
        }
      >
        <Button
          icon={<TeamOutlined />}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          Shared
        </Button>
      </Popover>

      {individualHasFullAccess && (
        <Tooltip title="Edit collection" placement="bottomRight">
          <Button
            icon={<EditOutlined />}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              navigate(
                `${DEFAULT_ROUTES.ORGANIZATION.SWAG.COLLECTIONS}/${productCollection.id}`,
                { state: { mode: Mode.edit } }
              )
            }}
          />
        </Tooltip>
      )}
    </Flex>
  )
}

function ProductCollectionCardBadge() {
  const { productCollection } = useContext(ProductCollectionCardContext)

  const { access } = productCollection

  if (access === ProductCollection_CollectionAccess_Enum.private) return null

  return (
    <Tag
      style={{ position: 'absolute', top: 20, left: 16 }}
      icon={<CheckCircleOutlined />}
      color="success"
    >
      Swag Store
    </Tag>
  )
}

function ProductCollectionCardCtaRow({ cta }: { cta: React.ReactNode }) {
  const { productCollection } = useContext(ProductCollectionCardContext)

  const collectionProductsLength = Object.keys(
    productCollection.products
  ).length

  return (
    <Flex justify="space-between" align="center">
      <Text color="muted">
        {makePlural('product', collectionProductsLength, true)}
      </Text>
      {cta}
    </Flex>
  )
}

function ProductCollectionCardDetails({
  hidePrices = false,
}: {
  hidePrices?: boolean
}) {
  const { productCollection } = useContext(ProductCollectionCardContext)

  const { minCollectionValue, maxCollectionValue } =
    getCollectionRange(productCollection)

  return (
    <Flex flex={1} vertical gap={8}>
      <Heading size={600}>{productCollection.name}</Heading>
      {keys(productCollection?.products).length > 0 && !hidePrices && (
        <Text color={NUMBER_GREEN}>
          {numToDollars(minCollectionValue, 2, false)}
          {' - '}
          {numToDollars(maxCollectionValue, 2, false)}
        </Text>
      )}
    </Flex>
  )
}

function ProductCollectionCard({
  toPrefix,
  productCollection,
  isSelected = false,
  onClick,
  children,
}: PropsWithChildren<{
  toPrefix?: string // Makes the card a link, to the specified to path
  productCollection: ProductCollection
  isSelected?: boolean
  onClick?: () => void
}>) {
  const [isHovered, setIsHovered] = useState(false)

  const cardContext: ProductCollectionCardContextType = useMemo(
    () => ({
      productCollection,
      isHovered,
    }),
    [productCollection, isHovered]
  )

  const cardIsInteractive = !!onClick || !!toPrefix

  return (
    <ProductCollectionCardContext.Provider value={cardContext}>
      <Pane
        position="relative"
        display="flex"
        flexDirection="column"
        onMouseEnter={() =>
          cardIsInteractive ? setIsHovered(true) : undefined
        }
        onMouseLeave={() =>
          cardIsInteractive ? setIsHovered(false) : undefined
        }
        borderRadius={8}
        border="muted"
        height="100%"
        onClick={onClick}
        cursor={cardIsInteractive ? 'pointer' : undefined}
        hoverElevation={cardIsInteractive ? 1 : undefined}
      >
        {isSelected && (
          <Badge.Ribbon
            text="Selected"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: 8,
              right: -8,
            }}
          />
        )}

        <PerkImage
          sizes="(max-width: 768px) 90vw, (max-width: 1200px) 33vw"
          src={productCollection.imageUrl}
          style={{
            width: '100%',
            objectFit: 'cover',
            height: 160,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
        />

        {/**
         * As per https://inclusive-components.design/cards/
         *
         * This adheres to HTML standards.
         *
         * */}
        {toPrefix && (
          <Link
            to={`${toPrefix}/${productCollection.id}`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}

        <Flex vertical gap={8} style={{ padding: 16, height: '100%' }}>
          {children}
        </Flex>
      </Pane>
    </ProductCollectionCardContext.Provider>
  )
}

ProductCollectionCard.Menu = ProductCollectionCardMenu
ProductCollectionCard.Badge = ProductCollectionCardBadge
ProductCollectionCard.CtaRow = ProductCollectionCardCtaRow
ProductCollectionCard.Details = ProductCollectionCardDetails

export default ProductCollectionCard
