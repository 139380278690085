import { Flex } from 'antd'
import { Loader, PerkEmptyState, ProductsGrid } from 'components'
import { PRODUCT } from 'constants/productVariants'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useInfiniteProductVariants from 'hooks/productVariants/use-infinite-product-variants'
import { unionBy } from 'lodash-es'
import { Selectable } from 'types'
import { makePlural } from 'utils'

export function AlgoliaProductsGrid({
  toPrefix,
  onCardClick,
  withProductAmounts = false,
  withAmountsInUsd = false,
  withShipping = false,
  selectedVariants,
  onCardSelectButtonClick,
  selectedCardUiProps,
  showSelectedVariantsFirst = true,
  showPrepaidBadge,
}: {
  toPrefix?: string
  onCardClick?: (productVariant: ProductVariant) => void
  withShipping?: boolean
  withProductAmounts?: boolean
  withAmountsInUsd?: boolean
  selectedVariants?: Selectable<ProductVariant[]>
  onCardSelectButtonClick?: (productVariant: ProductVariant) => void
  selectedCardUiProps?: {
    buttonText?: string
    ribbonText?: string
  }
  showSelectedVariantsFirst?: boolean
  showPrepaidBadge?: boolean
}) {
  const {
    productVariants,
    totalProductVariantCount,
    isLoadingInitial,
    isLoadingMore,
    isEmpty,
    sentinelRef,
  } = useInfiniteProductVariants()

  const sortVariantsToRender = () => {
    if (!selectedVariants) return productVariants

    if (showSelectedVariantsFirst) {
      return unionBy(selectedVariants, productVariants, 'productId')
    }

    return productVariants.map(pv => {
      if (
        selectedVariants.find(addedPv => addedPv.productId === pv.productId)
      ) {
        Object.assign(pv, { isSelected: true })
      }
      return pv
    })
  }

  if (isLoadingInitial) return <Loader />

  if (isEmpty) {
    return (
      <section style={{ marginTop: 64 }}>
        <PerkEmptyState
          header="No results found"
          description={"We couldn't find exactly what you were looking for"}
        />
      </section>
    )
  }

  return (
    <Flex vertical justify="space-between" style={{ height: '100%' }}>
      <ProductsGrid
        toPrefix={toPrefix}
        products={sortVariantsToRender()}
        onCardClick={onCardClick}
        withPrices={withProductAmounts}
        withShipping={withShipping}
        withAmountsInUsd={withAmountsInUsd}
        onCardSelectButtonClick={onCardSelectButtonClick}
        showPrepaidBadge={showPrepaidBadge}
        selectedCardUiProps={selectedCardUiProps}
      />
      {isLoadingMore && <Loader />}
      <div id="scroll-sentinel" ref={sentinelRef} aria-hidden="true" />
      <Pane marginTop={16} alignSelf="end">
        <Text color="muted">
          {productVariants.length} of {totalProductVariantCount}{' '}
          {makePlural(PRODUCT, totalProductVariantCount)}
        </Text>
      </Pane>
    </Flex>
  )
}
