import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Empty, Flex, Modal } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { AddPersonalFundsButton, Loader, PerkActionFooter } from 'components'
import { SwapGiftButton } from 'components/Buttons/SwapGiftButton'
import { USA_ISO3 } from 'constants/countries'
import { ACCEPTANCE_FLOW_WIDTH } from 'constants/layout'
import { ALL_ORGS, FEATURED_COLLECTION_ID } from 'constants/productCollections'
import { CHOOSE_YOUR_GIFT } from 'constants/rewards'
import {
  DEFAULT_ROUTES,
  REWARD_ACCEPTANCE,
  REWARD_ACCEPTANCE_CHECKOUT,
} from 'constants/routes'
import { DRAW_FROM_PERSONAL_FUNDS } from 'constants/sessionOrLocalStorage'
import {
  CountryContext,
  ExchangeRateContext,
  MemberContext,
  ProgramContext,
} from 'context'
import {
  EmptyState,
  Heading,
  Pane,
  SearchIcon,
  Text,
  toaster,
  useTheme,
} from 'evergreen-ui'
import { SelectProductVariants } from 'features'
import { Account } from 'gen/perkup/v1/account_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useCollectionById, usePersonalFundsAccount, useUserById } from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { useDisplayCurrency } from 'hooks/useDisplayCurrency'
import { differenceWith, isEmpty, isNaN, uniqBy } from 'lodash-es'
import { MaxGiftRedemption } from 'pages/NewReward/components/MaxQuantityForm'
import { useContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { PageDirection, Selectable } from 'types'
import { isSwappableGift, numToDollars } from 'utils'
import { getAlgoliaFeaturedCollectionFilter } from 'utils/Algolia'
import { sortCollectionProductsByRank } from 'utils/productCollections'
import {
  buildProductVariantDisplayName,
  saveProductVariantsToSessionStorage,
} from 'utils/productVariant'
import { useProductVariantsFromSessionStorage } from '../hooks/useProductVariantsFromSessionStorage'
import { GiftAcceptanceAlert } from './GiftAcceptanceAlert'

// This needs to be its own component so that we remain in a loading state while waiting for the funds to appear - and remounting when they do
function PersonalFundsDropdown({
  personalFundsAccount,
  drawFromPersonalFunds,
  onChangeDrawFromPersonalFunds,
}: {
  personalFundsAccount?: Account
  drawFromPersonalFunds: boolean
  onChangeDrawFromPersonalFunds: (value: boolean) => void
}) {
  const { id: programId } = useContext(ProgramContext)
  const displayCurrency = useDisplayCurrency()
  const [isAddingFunds, setIsAddingFunds] = useState(false)

  if (!personalFundsAccount)
    return (
      <AddPersonalFundsButton
        ctaProps={{
          children: 'Add personal funds',
          icon: <PlusOutlined />,
          style: { width: '100%' },
          type: 'primary',
          loading: isAddingFunds,
        }}
        onAfterSubmit={() => {
          sessionStorage.setItem(
            `${programId}_${DRAW_FROM_PERSONAL_FUNDS}`,
            JSON.stringify(true)
          )
          onChangeDrawFromPersonalFunds(true)
          setIsAddingFunds(true)
        }}
      />
    )

  const items: ItemType[] = [
    {
      key: 1,
      disabled: !personalFundsAccount?.balance,
      label: (
        <Button
          type="text"
          style={{ width: '100%', justifyContent: 'start', display: 'flex' }}
          icon={
            drawFromPersonalFunds ? <MinusCircleOutlined /> : <UserOutlined />
          }
          onClick={() => {
            sessionStorage.setItem(
              `${programId}_${DRAW_FROM_PERSONAL_FUNDS}`,
              JSON.stringify(!drawFromPersonalFunds)
            )
            onChangeDrawFromPersonalFunds(!drawFromPersonalFunds)
          }}
        >
          {drawFromPersonalFunds
            ? 'Remove personal funds'
            : `Use existing personal funds (${numToDollars(
                Number(personalFundsAccount?.balance),
                2,
                false,
                displayCurrency
              )})`}
        </Button>
      ),
      style: { padding: 0 },
    },
    {
      key: 2,
      label: (
        <AddPersonalFundsButton
          ctaProps={{
            children: 'Add additional personal funds',
            icon: <PlusCircleOutlined />,
            style: { width: '100%', display: 'flex', justifyContent: 'start' },
            type: 'text',
          }}
          onAfterSubmit={() => {
            sessionStorage.setItem(
              `${programId}_${DRAW_FROM_PERSONAL_FUNDS}`,
              JSON.stringify(true)
            )
            onChangeDrawFromPersonalFunds(true)
            setIsAddingFunds(true)
          }}
        />
      ),
      style: { padding: 0 },
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      overlayStyle={{ padding: 0 }}
      disabled={isAddingFunds}
    >
      <Button icon={<PlusOutlined />} loading={isAddingFunds} type="primary">
        Add funds
      </Button>
    </Dropdown>
  )
}

export function MultiProductGiftRevealed() {
  const program = useContext(ProgramContext)
  const { id: programId } = program
  const member = useContext(MemberContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const country = useContext(CountryContext)

  const [modal, contextHolder] = Modal.useModal()

  const drawFromPfStorage = sessionStorage.getItem(
    `${programId}_${DRAW_FROM_PERSONAL_FUNDS}`
  )
  const defaultDrawFromPf = drawFromPfStorage
    ? JSON.parse(drawFromPfStorage)
    : false

  const [drawFromPersonalFunds, setDrawFromPersonalFunds] =
    useState(defaultDrawFromPf)

  const displayCurrency = useDisplayCurrency()
  const navigate = useNavigate()
  const theme = useTheme()

  const gift = program?.gift
  const currentIso3 = country?.iso3 || USA_ISO3
  const collectionId = gift?.productCollectionId
  const productIds = gift?.productIds
  const isFeaturedCollection =
    gift?.productCollectionId === FEATURED_COLLECTION_ID

  const { account: personalFundsAccount } = usePersonalFundsAccount()
  const {
    savedVariants,
    setSavedVariants,
    isLoading: isLoadingSavedVariants,
  } = useProductVariantsFromSessionStorage({ programId })
  const { user: programOwner } = useUserById({ userId: program?.ownerId })
  const { collection, hasLoaded: hasLoadedCollection } = useCollectionById({
    id: collectionId,
  })

  const isGlobalCollection = collection?.orgId === ALL_ORGS
  const invalidShippingCountry =
    isGlobalCollection && !collection.shippingCountries.includes(currentIso3)

  const collectionProductIds = useMemo(() => {
    if (isGlobalCollection) return []
    if (collection) return Object.keys(collection?.products)
    if (productIds) return productIds
    return []
  }, [collection, productIds, isGlobalCollection])

  const {
    productVariants: allPossibleVariants,
    isLoadingInitial: isLoadingCollectionVariants,
  } = useListAllProductVariantsByProductIds({
    productIds: collectionProductIds,
  })

  const allPossibleVariantsWithinBudget = useMemo(() => {
    return allPossibleVariants
      .filter(variant => {
        const price = variant?.amount || 0
        return price <= program.budget
      })
      .filter(pv => {
        if (pv.shippingCountries && !isEmpty(pv.shippingCountries)) {
          return pv.shippingCountries.includes(currentIso3)
        }
        return true
      })
  }, [allPossibleVariants, currentIso3, program.budget])

  const collectionProductsWithinBudget = uniqBy(
    allPossibleVariantsWithinBudget,
    'productId'
  )

  const variantsWithSelectable: Selectable<ProductVariant>[] =
    collectionProductsWithinBudget.map(variant => {
      const isSelected = savedVariants.some(
        pv => pv.productId === variant.productId
      )
      Object.assign(variant, {
        selectable: isSelected,
      })

      return variant
    })

  const variantsToRender = sortCollectionProductsByRank({
    uniqueHits: variantsWithSelectable,
    collectionProducts: collection?.products || {},
  })

  const getGiftsToRedeem = () => {
    if (gift?.redeemableQuantity) {
      // Looking at variantsToRender because if this is less than redeemableQuantity, we need to adjust the number that they can redeem to be lower. If it's empty that means algolia is rendering and we dont edit.
      return !isEmpty(variantsToRender) &&
        gift.redeemableQuantity > variantsToRender.length
        ? variantsToRender.length
        : gift?.redeemableQuantity
    }
    return undefined
  }
  const giftsToRedeem = getGiftsToRedeem()
  const redemptionType: MaxGiftRedemption = giftsToRedeem ? 'Items' : 'Budget'
  const isBudgetGift = redemptionType === 'Budget'

  const hasMaxQuantity = !!gift?.redeemableQuantity

  const availableBudget =
    program.budget +
    (drawFromPersonalFunds ? Number(personalFundsAccount?.balance) || 0 : 0)
  const displayBudget = exchangeRate * availableBudget

  const selectedVariantsSum = savedVariants.reduce(
    (acc, variant) => acc + Number(variant?.amount || 0),
    0
  )

  const headingDisplayString = () => {
    if (giftsToRedeem && giftsToRedeem > 1) {
      return `Choose your ${giftsToRedeem} gifts`
    }
    if (isBudgetGift) {
      return `You have ${numToDollars(
        (availableBudget - selectedVariantsSum) * exchangeRate,
        2,
        false,
        displayCurrency
      )} to spend`
    }
    return CHOOSE_YOUR_GIFT
  }

  const budgetRemaining = isBudgetGift
    ? availableBudget - selectedVariantsSum
    : undefined

  const subHeadingDisplayString = useMemo(() => {
    if (giftsToRedeem && giftsToRedeem === 1) {
      return undefined
    }
    if (hasMaxQuantity) {
      return `${savedVariants.length} of ${giftsToRedeem} selected`
    }

    return `${numToDollars(
      selectedVariantsSum * exchangeRate,
      2,
      false,
      displayCurrency
    )} / ${numToDollars(displayBudget, 2, false, displayCurrency)} spent`
  }, [
    savedVariants.length,
    selectedVariantsSum,
    displayBudget,
    displayCurrency,
    exchangeRate,
    giftsToRedeem,
    hasMaxQuantity,
  ])

  const remainingVariants = useMemo(() => {
    const remainingVariants = differenceWith(
      allPossibleVariantsWithinBudget,
      savedVariants,
      (allPvs, addedPvs) => allPvs.productId === addedPvs.productId
    )

    const filteredVariants = isBudgetGift
      ? remainingVariants.filter(
          variant =>
            (variant?.amount || 0) <= availableBudget - selectedVariantsSum
        )
      : remainingVariants

    return uniqBy(filteredVariants, 'productId')
  }, [
    allPossibleVariantsWithinBudget,
    savedVariants,
    isBudgetGift,
    availableBudget,
    selectedVariantsSum,
  ])

  const maxGiftsSelected = useMemo(() => {
    if (!isBudgetGift) {
      return savedVariants.length === giftsToRedeem
    }
    return isEmpty(remainingVariants)
  }, [savedVariants.length, giftsToRedeem, isBudgetGift, remainingVariants])

  const handleContinue = ({ skipChecks = false }: { skipChecks?: boolean }) => {
    if (budgetRemaining && budgetRemaining < 0) {
      toaster.warning(
        `You are ${numToDollars(Math.abs(budgetRemaining), 2, false)} over budget. Remove one or more items to continue.`
      )
      return
    }

    if (
      !skipChecks &&
      budgetRemaining &&
      remainingVariants.some(pv => pv.amount && pv.amount <= budgetRemaining)
    ) {
      const remainingBalanceTitle = `You still have ${numToDollars(
        budgetRemaining * exchangeRate,
        2,
        false,
        displayCurrency
      )} remaining`
      modal.confirm({
        title: remainingBalanceTitle,
        icon: <ExclamationCircleOutlined />,
        content:
          'Once your order is placed, you will not be able to return to add more items. Are you sure you want to proceed?',
        okText: 'Add more items',
        cancelText: 'Continue to shipping',
        onCancel: () => handleContinue({ skipChecks: true }),
        width: isMobile ? '100%' : 512,
      })
      return
    }
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        programId || ''
      )}${REWARD_ACCEPTANCE_CHECKOUT}`,
      { state: { direction: PageDirection.FORWARD } }
    )
  }

  const giftsRemaining =
    !isBudgetGift && giftsToRedeem
      ? giftsToRedeem - savedVariants.length
      : undefined

  const onConfirmSelectProduct = (selectedProductVariant: ProductVariant) => {
    const variantToRemove = savedVariants.find(
      pv => pv.productId === selectedProductVariant.productId
    )

    if (variantToRemove) {
      const filteredVariants = savedVariants.filter(
        pv => pv.productId !== selectedProductVariant.productId
      )
      setSavedVariants(filteredVariants)
      saveProductVariantsToSessionStorage({
        productVariants: filteredVariants,
        programId: program?.id,
      })
      const removedVariantName = buildProductVariantDisplayName({
        productVariant: variantToRemove,
      })
      toaster.success(`${removedVariantName} removed`)
      return
    }

    const variantsToSave = [...savedVariants, selectedProductVariant]
    setSavedVariants(variantsToSave)
    saveProductVariantsToSessionStorage({
      productVariants: variantsToSave,
      programId: program?.id,
    })
    const addedVariantName = buildProductVariantDisplayName({
      productVariant: selectedProductVariant,
    })
    toaster.success(`${addedVariantName} selected`)
    // If last choice, nav forward
    if (giftsRemaining && giftsRemaining === 1) {
      handleContinue({ skipChecks: true })
    }
  }

  const isSwappable = isSwappableGift({ gift })

  const showContinueButton =
    (!isBudgetGift && maxGiftsSelected) ||
    (isBudgetGift && !isEmpty(savedVariants))

  const disableSwap = !isNaN(giftsRemaining) && giftsRemaining === 0

  const withAlgolia = isGlobalCollection || isFeaturedCollection

  const continueFooter = showContinueButton ? (
    <PerkActionFooter>
      <Button
        size="large"
        type="primary"
        onClick={() => handleContinue({ skipChecks: false })}
        style={{ width: isMobile ? '100%' : 248 }}
      >
        Continue
      </Button>
    </PerkActionFooter>
  ) : undefined

  const selectGiftsHeader = (
    <Pane display="flex" flexDirection="column" gap={8} width="100%">
      <Pane
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        width="100%"
        justifyContent="space-between"
        gap={8}
      >
        <Flex align="center" gap={16}>
          <Heading size={900}>{headingDisplayString()}</Heading>
          {/* If swag budget gift, allow using personal funds to select more */}
          {!withAlgolia && isBudgetGift && (
            <PersonalFundsDropdown
              key={
                // Default key to 0 or else it remounts when pf account created and before funds are added
                !personalFundsAccount
                  ? 0
                  : Number(personalFundsAccount?.balance)
              }
              personalFundsAccount={personalFundsAccount}
              drawFromPersonalFunds={drawFromPersonalFunds}
              onChangeDrawFromPersonalFunds={setDrawFromPersonalFunds}
            />
          )}
        </Flex>
        {isSwappable && !disableSwap && (
          <Pane
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={8}
          >
            <SwapGiftButton
              program={program}
              memberId={member.id}
              onSwap={onConfirmSelectProduct}
              maxBudget={program.budget - selectedVariantsSum}
            />
          </Pane>
        )}
      </Pane>
      {subHeadingDisplayString && <Text>{subHeadingDisplayString}</Text>}

      <GiftAcceptanceAlert />
    </Pane>
  )

  const collectionFilter = isFeaturedCollection
    ? ''
    : ` AND collectionIds:${collectionId}`

  const algoliaSearchFilter =
    getAlgoliaFeaturedCollectionFilter({
      maxAmount: program.budget,
      currentIso3,
    }) + collectionFilter

  if (
    isLoadingSavedVariants ||
    isLoadingCollectionVariants ||
    !hasLoadedCollection
  )
    return <Loader />

  if (invalidShippingCountry) {
    return (
      <EmptyState
        background="light"
        title={`Collection not available in ${country.name}`}
        orientation="horizontal"
        icon={<SearchIcon color={theme.colors.gray500} />}
        iconBgColor={theme.colors.gray200}
        primaryCta={
          <Button
            type="primary"
            onClick={() => navigate(DEFAULT_ROUTES.CARD.ROOT)}
          >
            Return to dashboard
          </Button>
        }
        description={`Contact ${
          programOwner?.profile?.email || 'your program owner'
        } to get this resolved.`}
      />
    )
  }

  if (!withAlgolia && isEmpty(variantsToRender)) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={
          <Flex vertical gap={8}>
            <Heading>No products found</Heading>
            <Text>{`Gift is not available in your country. Contact ${programOwner?.profile?.email} to get this resolved.`}</Text>
          </Flex>
        }
      />
    )
  }

  return (
    <>
      <Flex
        vertical
        style={{
          width: ACCEPTANCE_FLOW_WIDTH,
          height: '100%',
        }}
      >
        <SelectProductVariants
          selectedVariants={savedVariants}
          productVariantsAvailable={variantsToRender}
          onAddVariant={onConfirmSelectProduct}
          showPrice={isBudgetGift}
          budgetRemaining={budgetRemaining}
          giftsRemaining={giftsRemaining}
          continueFooter={continueFooter}
          header={selectGiftsHeader}
          withAlgolia={withAlgolia}
          searchFilter={algoliaSearchFilter}
          maxAmount={availableBudget}
        />
      </Flex>
      {contextHolder}
    </>
  )
}
