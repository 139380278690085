import { SWAG_CARD } from 'assets/contentful'
import {
  OCCASION_COLLECTION_ID_TO_NAME,
  OCCASION_COLLECTION_IDS,
} from 'constants/productCollections'
import { deleteField } from 'firebase/firestore'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import {
  ProductVariant,
  ProductVariant_Provider,
} from 'gen/perkup/v1/product_variant_pb'
import {
  Program_Gift,
  Program_Gift_FulfilledBy,
} from 'gen/perkup/v1/program_pb'
import { isEmpty } from 'lodash-es'
import { Reward } from 'types'

export const buildProgramGiftAsGenericProducts = ({
  productIds,
  title,
  convertableTo,
  redeemableQuantity,
}: {
  productIds: string[]
  title: string
  convertableTo: string[] | undefined
  redeemableQuantity: number | undefined
}) => {
  return new Program_Gift({
    id: '',
    title,
    productIds,
    fulfilledBy: undefined,
    redeemableQuantity,
    imageUrl: SWAG_CARD,
    convertableTo,
  })
}

export const buildProgramGiftAsGenericProduct = ({
  productVariant,
  convertableTo,
}: {
  productVariant: ProductVariant
  convertableTo?: string[]
}) => {
  return new Program_Gift({
    id: '',
    title: productVariant.productName,
    productIds: [productVariant.productId],
    fulfilledBy:
      productVariant.provider === ProductVariant_Provider.shopify
        ? Program_Gift_FulfilledBy.shopify
        : Program_Gift_FulfilledBy.manual,
    redeemableQuantity: 1,
    imageUrl: productVariant.imageUrl,
    convertableTo,
    productVariantType: productVariant.type,
  })
}

export const buildProgramGiftAsSpecificProductVariant = ({
  productVariant,
  convertableTo,
}: {
  productVariant: ProductVariant
  convertableTo?: string[]
}) => {
  return new Program_Gift({
    id: '',
    title: productVariant.productName,
    productVariantId: productVariant.id,
    fulfilledBy:
      productVariant.provider === ProductVariant_Provider.shopify
        ? Program_Gift_FulfilledBy.shopify
        : Program_Gift_FulfilledBy.manual,
    convertableTo,
    imageUrl: productVariant.imageUrl,
    productVariantType: productVariant.type,
  })
}

export const buildProgramGiftFromSwagProductCollection = (
  productCollection: ProductCollection
) => {
  return new Program_Gift({
    id: productCollection.id,
    title: productCollection.name,
    productCollectionId: productCollection.id,
    imageUrl: productCollection.imageUrl,
    redeemableQuantity: 1,
  })
}

export const buildProgramGiftFromProductCollection = (
  productCollectionId: string
) => {
  return new Program_Gift({
    id: productCollectionId,
    title: OCCASION_COLLECTION_ID_TO_NAME[productCollectionId],
    imageUrl: SWAG_CARD, // @todo (ENG-5596) dynamically get the image based on the id
    productCollectionId,
    fulfilledBy: Program_Gift_FulfilledBy.shopify,
    redeemableQuantity: 1,
  })
}

export const formatGiftFieldValues = ({ gift }: { gift: Program_Gift }) => {
  const updatedGift = {
    ...gift,
    productCollectionId: gift?.productCollectionId
      ? gift?.productCollectionId
      : deleteField(),
    productIds:
      gift?.productIds && !isEmpty(gift?.productIds)
        ? gift?.productIds
        : deleteField(),
    vendorId: gift?.vendorId ? gift?.vendorId : deleteField(),
    country: deleteField(),
    redeemableQuantity: gift?.redeemableQuantity
      ? gift?.redeemableQuantity
      : deleteField(),
    shopId: gift?.shopId ? gift?.shopId : deleteField(),
    productVariantId: gift?.productVariantId
      ? gift?.productVariantId
      : deleteField(),
    groupId: gift?.groupId ? gift?.groupId : deleteField(),
    fulfilledBy: gift?.fulfilledBy ? gift?.fulfilledBy : deleteField(),
    productVariantType: gift?.productVariantType
      ? gift?.productVariantType
      : deleteField(),
  }

  return updatedGift
}

/** @deprecated try to get rid of this */
export const giftHasBudgetMaximum = ({
  gift,
  budget,
}: {
  gift?: Program_Gift
  budget?: number
}) => {
  const hasMultipleProductIds = gift?.productIds && gift?.productIds.length > 1

  const hasProductsAvailable =
    !!gift?.productCollectionId || !!hasMultipleProductIds

  const hasBudget = !!budget

  const hasNoRedeemQuantity = !gift?.redeemableQuantity

  const hasBudgetMaximum =
    hasProductsAvailable && hasNoRedeemQuantity && hasBudget

  return hasBudgetMaximum
}

export const calculateGiftCosts = ({
  newTotalCost,
  newProductPrice,
  newCollectionMinPrice,
  selectedQuantity,
  maxBudget,
}: {
  newTotalCost: number
  newProductPrice: number
  newCollectionMinPrice: number
  selectedQuantity: number
  maxBudget?: number
}) => {
  const totalCost = maxBudget || newTotalCost * selectedQuantity

  const productPrice = maxBudget || newProductPrice * selectedQuantity

  const collectionMinPrice = newCollectionMinPrice * selectedQuantity

  return {
    totalCost,
    productPrice,
    collectionMinPrice,
  }
}

export const getRewardMetadata = ({ gift, budget }: Partial<Reward>) => {
  const isGift = !!gift
  const isSwagCollection =
    !!gift?.productCollectionId &&
    !OCCASION_COLLECTION_IDS.includes(gift.productCollectionId)
  const isSwagGift = !!gift?.productIds && gift.productIds.length > 0

  const isSwag = isSwagCollection || isSwagGift

  const isSingleProduct = !isSwag && !!gift?.productVariantId

  const isGiftCollection = !isSwag && !!gift?.productCollectionId

  const isZeroDollarBudget = budget === 0

  const isPerkupDollars = !isGift
  const isGiftsCatalog = isGift && !isSwag

  return {
    isGift,
    isSwag,
    isSingleProduct,
    isSwagCollection,
    isSwagGift,
    isGiftCollection,
    isZeroDollarBudget,
    isPerkupDollars,
    isGiftsCatalog,
  }
}
