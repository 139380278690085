import {
  CloseOutlined,
  DollarCircleOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { Button, ButtonProps, Drawer, Modal, Popconfirm } from 'antd'
import { convertIntoPerkUpBalance, updateSwappedGift } from 'api/databaseCalls'
import { TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE } from 'constants/algolia'
import { USA_ISO3 } from 'constants/countries'
import { PERKUP_DOLLARS } from 'constants/newReward'
import { CountryContext } from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program } from 'gen/perkup/v1/program_pb'
import { useGiftRedeemed } from 'hooks'
import useIds from 'hooks/useIds'
import { buildProgramGiftAsGenericProduct } from 'pages/NewReward/utils/program-gifts'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { ConvertableTo } from 'types/Gift'

export function SwapGiftButton({
  program,
  memberId,
  swapGiftCta = 'Select',
  swapGiftButtonProps,
  onSwap,
  maxBudget,
}: {
  program: Program
  memberId: string
  swapGiftCta?: string
  swapGiftButtonProps?: ButtonProps
  onSwap?: (product: ProductVariant) => void
  maxBudget?: number
}) {
  const { orgId } = useIds()

  const country = useContext(CountryContext)

  const [showDrawer, setShowDrawer] = useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const giftRedeemed = useGiftRedeemed()

  const programId = program.id
  const currentIso3 = country?.iso3 || USA_ISO3

  const handleSelectProduct = async (newProduct: ProductVariant) => {
    const newGift = buildProgramGiftAsGenericProduct({
      productVariant: newProduct,
      convertableTo: program?.gift?.convertableTo,
    })

    await updateSwappedGift({
      orgId,
      programId,
      memberId,
      newGift,
    })

    if (onSwap) {
      onSwap(newProduct)
    }
    setShowDrawer(false)
    setProductVariantToShow(undefined)
  }

  const handleConvertToPerkUpBalance = () => {
    convertIntoPerkUpBalance({
      orgId,
      programId,
      memberId,
    })
  }

  const canSwapForCash = program?.gift?.convertableTo?.includes(
    ConvertableTo.cash
  )
  const canSwapForGift = program?.gift?.convertableTo?.includes(
    ConvertableTo.gift
  )

  const searchFilter = `${TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE} AND amount < ${
    maxBudget || program.budget
  } AND amount > 0 AND shippingCountries:${currentIso3}`

  const defaultGiftCtaText = canSwapForCash ? 'Swap for gift' : 'Swap'

  if (!canSwapForCash && !canSwapForGift) return null

  return (
    <>
      {canSwapForGift && (
        <Button
          type="default"
          disabled={giftRedeemed}
          icon={<SwapOutlined />}
          style={{ width: '100%' }}
          onClick={() => setShowDrawer(true)}
          {...swapGiftButtonProps}
        >
          {swapGiftButtonProps?.children || defaultGiftCtaText}
        </Button>
      )}
      {canSwapForCash && (
        <Popconfirm
          placement="bottom"
          title={`Convert to ${PERKUP_DOLLARS}`}
          description="Once you convert, you cannot go back."
          okText="Convert"
          onConfirm={handleConvertToPerkUpBalance}
        >
          <Button
            style={{ width: '100%' }}
            disabled={giftRedeemed}
            icon={<DollarCircleOutlined />}
          >
            Convert to {PERKUP_DOLLARS}
          </Button>
        </Popconfirm>
      )}
      <Drawer
        zIndex={5}
        open={showDrawer}
        placement="bottom"
        width="100vw"
        height="100vh"
        closeIcon={null}
        title={
          <Pane
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            zIndex={2}
          >
            <Heading size={500}>Swap Gift</Heading>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setShowDrawer(false)}
            />
          </Pane>
        }
        styles={{
          header: {
            borderBottom: 'none',
            width: '100%',
          },
          body: {
            paddingTop: '0px',
          },
        }}
        destroyOnClose
      >
        <AlgoliaBrowseProducts>
          <AlgoliaBrowseProducts.Old
            searchFilter={searchFilter}
            withCategoriesFilter
            onProductCardClick={setProductVariantToShow}
          />
        </AlgoliaBrowseProducts>
        <Modal
          width={isMobile ? '100vw' : 1024}
          centered
          title="‎"
          open={!!productVariantToShow}
          footer={false}
          onCancel={() => {
            setProductVariantToShow(undefined)
          }}
          styles={{
            body: {
              height: 600,
              overflowY: 'auto',
            },
          }}
        >
          {productVariantToShow && (
            <ProductDetails
              key={productVariantToShow.id}
              productVariant={productVariantToShow}
              submitButtonProps={{ children: swapGiftCta }}
              onSubmit={handleSelectProduct}
              maxAmount={maxBudget}
            />
          )}
        </Modal>
      </Drawer>
    </>
  )
}
