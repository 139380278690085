import { Button, Flex } from 'antd'
import { PerkActionFooter } from 'components'
import { ActiveProductsPopover } from 'components/ProductVariants/active-products-popover'
import { PAGE_WIDTH_MINUS_SIDEBAR } from 'constants/layout'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { AlgoliaBrowseProducts } from 'features'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import {
  buildProgramGiftAsGenericProduct,
  buildProgramGiftAsGenericProducts,
} from 'pages/NewReward/utils/program-gifts'
import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { NewRewardLocationState, OrderProductsLocationState } from 'types'
import { calculateMaxVariantPriceByProductIds } from 'utils'
import { getUniqAndFormatWithSelectable } from 'utils/productVariant'

export default function OrgSwagProductsPage() {
  const { id: orgId } = useContext(OrgContext)
  const navigate = useNavigate()

  const [productVariantsToSend, setProductVariantsToSend] = useState<
    ProductVariant[]
  >([])

  const handleAddOrRemoveProductVariantToSend = (
    productVariant: ProductVariant
  ) => {
    const isProductVariantInList = productVariantsToSend.some(
      pv => pv.id === productVariant.id
    )

    if (isProductVariantInList) {
      setProductVariantsToSend(
        productVariantsToSend.filter(pv => pv.id !== productVariant.id)
      )
    } else {
      setProductVariantsToSend([...productVariantsToSend, productVariant])
    }
  }

  const handleNavigateToOrderProductsWithState = () => {
    const stateToSend: OrderProductsLocationState = {
      state: {
        productVariants: productVariantsToSend.map(productVariant =>
          Object.assign(productVariant, {
            selectedQuantity: 1,
          })
        ),
      },
    }
    navigate(DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG, stateToSend)
  }

  const handleNavigateToNewRewardWithState = async () => {
    let gift: Program_Gift | undefined
    let budget: number | undefined

    // Have to build a generic single gift
    if (productVariantsToSend.length === 1) {
      const productVariantToSend = productVariantsToSend[0]
      gift = buildProgramGiftAsGenericProduct({
        productVariant: productVariantToSend,
        convertableTo: [],
      })
      budget = Number(productVariantToSend.amount)
    }

    // Have to build a generic multi gift
    else if (productVariantsToSend.length > 1) {
      const genericProductIdsToSend = productVariantsToSend.map(
        pv => pv.productId
      )
      gift = buildProgramGiftAsGenericProducts({
        productIds: genericProductIdsToSend,
        title: 'Swag',
        convertableTo: [],
        redeemableQuantity: 1,
      })

      budget = await calculateMaxVariantPriceByProductIds({
        productIds: gift.productIds,
        orgId,
      })
    }

    const stateToSend: NewRewardLocationState = {
      state: {
        budget,
        gift,
      },
    }

    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, stateToSend)
  }

  const uniqueSelectedVariants = getUniqAndFormatWithSelectable(
    productVariantsToSend
  )

  return (
    <>
      <Helmet>
        <title>Products | Swag</title>
      </Helmet>

      <main style={{ width: '100%', padding: '16px 32px' }}>
        <AlgoliaBrowseProducts>
          <AlgoliaBrowseProducts.Swag
            title="Products"
            stickyNavTopAmount={60}
            bottomOffsetAmount={96}
            selectedVariants={uniqueSelectedVariants}
            toPrefix={DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS}
            minPermission={ProductCollection_Permission.send}
            showSelectedVariantsFirst={false}
            onCardSelectButtonClick={handleAddOrRemoveProductVariantToSend}
          />
        </AlgoliaBrowseProducts>
        <PerkActionFooter
          width={PAGE_WIDTH_MINUS_SIDEBAR}
          height={82} // Overriding auto height with static height to not have footer jump when adding / removing products
        >
          <Flex gap={48} align="center">
            <ActiveProductsPopover
              productVariants={productVariantsToSend}
              onRemoveProductVariant={handleAddOrRemoveProductVariantToSend}
            />
            <Flex gap={8} align="center">
              <Button
                size="large"
                onClick={handleNavigateToOrderProductsWithState}
              >
                Send to address
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={handleNavigateToNewRewardWithState}
              >
                Send as reward
              </Button>
            </Flex>
          </Flex>
        </PerkActionFooter>
      </main>
    </>
  )
}
