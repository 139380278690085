import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Avatar, Flex, Tag, Tooltip } from 'antd'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { CountryIconGroup } from 'components/Countries'
import { PerkImage } from 'components/PerkImage'
import { NUMBER_GREEN } from 'constants/colors'
import { Text } from 'evergreen-ui'
import { ComponentProps, PropsWithChildren } from 'react'
import { numToDollars, removeQuotes } from 'utils'

function ProductThumbnailOptions({
  options,
}: {
  options: Record<string, { value: string } | undefined> | undefined
}) {
  const productOptions = Object.entries(options ?? {}).map(([key, data]) => ({
    key,
    ...data,
  }))

  return (
    <Flex gap={12}>
      {productOptions.map(option => (
        <Text color="muted" key={option.key} minWidth="max-content">
          {removeQuotes(option.value ?? '')}
        </Text>
      ))}
    </Flex>
  )
}

function ProductThumbnailImage({
  imageUrl,
}: {
  imageUrl: string | undefined | null
}) {
  return (
    <Avatar
      shape="square"
      size={48}
      style={{
        minWidth: 48,
        minHeight: 48,
        backgroundColor: 'transparent',
      }}
      icon={
        <PerkImage
          preview
          src={imageUrl || undefined}
          sizes="48px"
          style={{ objectFit: 'cover', height: 48 }}
        />
      }
    />
  )
}

function ProductThumbnailName({ children }: PropsWithChildren) {
  return (
    <Tooltip title={children}>
      <Text className="one-line-truncate">{children}</Text>
    </Tooltip>
  )
}

function ProductThumbnailShippingCountries({
  iso3ShippingCountries = [],
  maxCount = 4,
  labelColor = 'muted',
}: {
  iso3ShippingCountries: string[] | undefined
  maxCount?: number
  labelColor?: ComponentProps<typeof CountryIconGroup>['labelColor']
}) {
  if (!iso3ShippingCountries.length) return null

  return (
    <CountryIconGroup
      iso3s={iso3ShippingCountries}
      maxCount={maxCount}
      labelColor={labelColor}
    />
  )
}

function ProductThumbnailBadge({
  color,
  children,
  icon,
}: PropsWithChildren<{
  color?: ComponentProps<typeof Tag>['color']
  icon?: ComponentProps<typeof Tag>['icon']
}>) {
  return (
    <Tag
      color={color}
      style={{ width: 'fit-content', margin: 0 }}
      icon={icon === undefined ? <CheckCircleOutlined /> : icon}
    >
      {children}
    </Tag>
  )
}

function ProductThumbnailPrice({
  price,
  ...props
}: { price: bigint | number | undefined } & ComponentProps<typeof Text>) {
  return (
    <Text color={NUMBER_GREEN} {...props}>
      {numToDollars(Number(price))}
    </Text>
  )
}

function ProductThumbnailRemoveButton(
  props: ComponentProps<typeof AsyncButton>
) {
  return (
    <AsyncButton danger type="text" icon={<MinusCircleOutlined />} {...props} />
  )
}

function ProductThumbnail({ children }: PropsWithChildren) {
  return (
    <Flex gap={8} align="center">
      {children}
    </Flex>
  )
}

ProductThumbnail.Name = ProductThumbnailName
ProductThumbnail.Image = ProductThumbnailImage
ProductThumbnail.Options = ProductThumbnailOptions
ProductThumbnail.ShippingCountries = ProductThumbnailShippingCountries
ProductThumbnail.Badge = ProductThumbnailBadge
ProductThumbnail.Price = ProductThumbnailPrice
ProductThumbnail.RemoveButton = ProductThumbnailRemoveButton

export default ProductThumbnail
