import { CATEGORY_COLLECTION_ID_TO_NAME } from '@repo/constants/algolia'
import { ProductVariant_SourceType } from 'gen/perkup/v1/product_variant_pb'
import { isProduction } from './keys'
import { OCCASION_COLLECTION_ID_TO_NAME } from './productCollections'

export const ALGOLIA_ID = 'FEC4RTKUL1'

export const ALGOLIA_INFINITE_HITS_TOKEN = '8a0a1e19f31a3e65c583b5c503c3ccbf'

export const TEST_ORG_ID = 'ZxK6hQLaa9sd55dIZhT7'

export const ALGOLIA_INDIVIDUALS_INDEX = isProduction
  ? 'prod_individuals'
  : 'dev_individuals'

export const ALGOLIA_PRODUCT_VARIANTS_INDEX = isProduction
  ? 'prod_productvariants'
  : 'dev_productvariants'

export const STATUS_NONE_OR_INVITED_OR_ACTIVE =
  'status:none OR status:invited OR status:active'

export const TAGS_SCRAPE_IT_OR_CARRO =
  "tags:scrapeIt OR tags:'Carro Brands Product'"

export const TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE =
  'tags:publicGift AND isAvailable:true'

export const STATUS_NONE_OR_INVITED_OR_ACTIVE_OR_BLOCKED = `${STATUS_NONE_OR_INVITED_OR_ACTIVE} OR status:blocked`

export const ALGOLIA_FOOD_FACET_VALUE = 'Food & Restaurant'

export const TYPE_NEAR_CASH = 'type:nearCash'

export const ALGOLIA_PRODUCT_ID_FACET_VALUE = 'productId'

export const ALGOLIA_PRODUCT_TYPE_FACET_VALUE = 'productType'

export const ALGOLIA_COUNTRY_CODE_FACET_VALUE = 'shippingCountries'

export const ALGOLIA_TAGS_FACET_VALUE = 'tags'

export const ALGOLIA_PROVIDER_FACET_VALUE = 'provider'

export const ALGOLIA_DELIVERY_TYPE_FACET_VALUE = 'deliveryType'

export const CARRO_TAG = 'fulfillment-carro-partnerships'

export const SYNCEE_TAG = 'fulfillment-syncee'

export const BUNDLE_TAG = 'bundle'

export const SHOPIFY_TAG = 'shopify'

export const SCRAPE_IT_TAG = 'scrapeIt'

export const PUBLIC_GIFT_TAG = 'publicGift'

export const PV_DEFAULT_TAGS = [
  TEST_ORG_ID,
  CARRO_TAG,
  SYNCEE_TAG,
  SCRAPE_IT_TAG,
]

export const PLUM = 'plum'

export const PRINTFUL = 'printful'

export const INITIAL_AMOUNT_FILTER = '2500:5000'

export const FILTER_DISPLAY_NAMES: Record<string, string> = {
  vendor: 'Brands',
  amount: 'Price',
  productCategories: 'Categories',
  categories: 'Categories',
  productType: 'Categories',
  shippingCountries: 'Country',
}
export const AMOUNT = 'amount'

export const ALL_PRODUCTS = 'all'

export const COLLECTION_IDS = 'collectionIds'

export const LABELS = {
  ...OCCASION_COLLECTION_ID_TO_NAME,
  ...CATEGORY_COLLECTION_ID_TO_NAME,
  [ProductVariant_SourceType[ProductVariant_SourceType.fullPrepaid]]: 'Prepaid',
}
