import { CloseOutlined, GiftOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Alert,
  AlertProps,
  Button,
  Drawer,
  Flex,
  Input,
  InputNumber,
  InputNumberProps,
  Modal,
} from 'antd'
import { createShippingAddress } from 'api/databaseCalls'
import {
  AddressButton,
  AddressDisplay,
  BulkProductRowCard,
  PriceBreakdown,
  ProductThumbnail,
  ShippingAddressBlock,
} from 'components'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
} from 'constants/algolia'
import { USA_ISO3 } from 'constants/countries'
import { DEFAULT_DIRECT_MAIL_ORDER_TITLE } from 'constants/rewards'
import { Heading, Pane, Paragraph, Strong, Text, toaster } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import useIds from 'hooks/useIds'
import { groupBy, isEmpty } from 'lodash-es'
import InternalMemoForm from 'pages/NewReward/components/InternalMemoForm'
import { useState } from 'react'
import { WithSelectedQuantity } from 'types'

export function OrderGiftsPage({
  totalCost,
  selectedProductVariants,
  setSelectedProductVariants,
  shippingAddress,
  setShippingAddress,
  setOrderTitle,
  setInternalMemo,
  productAlertProps,
}: {
  totalCost?: number
  selectedProductVariants: WithSelectedQuantity<ProductVariant>[]
  setSelectedProductVariants: (
    pvs: WithSelectedQuantity<ProductVariant>[]
  ) => void
  shippingAddress?: ShippingAddress
  setShippingAddress: (newAddress?: ShippingAddress) => void
  setOrderTitle: (newTitle: string) => void
  setInternalMemo: (newMemo: string) => void
  productAlertProps?: AlertProps // Doesn't feel right needing to drill this down, this might be related to state being too far away!
}) {
  const [showProductsModal, setShowProductsModal] = useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()
  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const { userId } = useIds()

  const handleAddressChange = (shippingAddress?: ShippingAddress) => {
    if (shippingAddress && !shippingAddress?.id) {
      createShippingAddress({
        userId,
        shippingAddress,
      }).then(() => toaster.success('Successfully created shipping address'))
    }
    setShippingAddress(shippingAddress)
  }

  const handleAddProductVariant = (productVariant: ProductVariant) => {
    const pvWithQuantity: WithSelectedQuantity<ProductVariant> = Object.assign(
      productVariant,
      { selectedQuantity }
    )
    // Check if the product variant is already in the list
    const filteredSelectedProductVariants = selectedProductVariants.filter(
      pv => pv.id !== pvWithQuantity.id
    )
    setSelectedProductVariants([
      ...filteredSelectedProductVariants,
      pvWithQuantity,
    ])
    setShowProductsModal(false)
    setProductVariantToShow(undefined)
    setSelectedQuantity(1)
  }

  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') setSelectedQuantity(value)
  }

  const addProductsCTAIsButton = !isEmpty(selectedProductVariants)
  return (
    <Flex style={{ height: '100%' }} vertical align="center" gap={32}>
      <Flex vertical gap={16} align="center">
        <Heading size={900}>Order gift</Heading>
        <Text>
          Surprise an employee with a gift sent directly to their address.
        </Text>
      </Flex>
      <Modal
        width={1280}
        centered
        zIndex={2}
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            showInStockText={false}
            submitButtonProps={{ children: 'Select gift' }}
            withAmountInUsd
            onSubmit={handleAddProductVariant}
          >
            <ProductDetails.Slot name="aboveSubmit">
              <Flex vertical gap={4}>
                <Heading size={400}>Quantity</Heading>
                <InputNumber
                  min={1}
                  value={selectedQuantity}
                  onChange={handleChangeQuantity}
                />
              </Flex>
            </ProductDetails.Slot>
          </ProductDetails>
        )}
      </Modal>

      <Flex gap={32} style={{ width: '100%' }}>
        <Flex vertical gap={16} flex={1}>
          <Flex vertical gap={8}>
            <Strong>Order title</Strong>
            <Input
              defaultValue={DEFAULT_DIRECT_MAIL_ORDER_TITLE}
              placeholder="Order title"
              onBlur={e => setOrderTitle(e.target.value)}
            />
          </Flex>

          {addProductsCTAIsButton ? (
            <Button
              icon={<PlusOutlined />}
              style={{ width: 'fit-content' }}
              onClick={() => setShowProductsModal(true)}
            >
              Add product
            </Button>
          ) : (
            <Pane
              onClick={() => setShowProductsModal(true)}
              cursor="pointer"
              display="flex"
              borderRadius={8}
              border="muted"
              hoverElevation={1}
              width="100%"
              overflow="hidden"
              height={120}
              gap={16}
              paddingY={16}
              paddingX={32}
              alignItems="center"
            >
              <GiftOutlined size={48} style={{ fontSize: 32 }} />

              <Pane display="flex" flexDirection="column" gap={8}>
                <Heading size={600}>Select gift</Heading>
                <Paragraph size={400}>
                  Choose from a selection of curated gift options.
                </Paragraph>
              </Pane>
            </Pane>
          )}
          {Object.entries(groupBy(selectedProductVariants, 'productId')).map(
            ([productId, productVariants]) => (
              <BulkProductRowCard
                key={productId}
                productVariantsWithQuantities={productVariants}
                withEstimatedShippingTime
                shippingAddress={shippingAddress}
                onRemoveProductClick={productId => {
                  const newPvs = selectedProductVariants.filter(
                    pv => pv.productId !== productId
                  )
                  setSelectedProductVariants(newPvs)
                }}
                onRemoveVariantClick={pvId => {
                  const newPvs = selectedProductVariants.filter(
                    pv => pv.id !== pvId
                  )
                  setSelectedProductVariants(newPvs)
                }}
                onQuantityChange={(pv, q) => {
                  setSelectedProductVariants(
                    selectedProductVariants.map(pv => {
                      if (pv.productId === productId) {
                        const newPv = Object.assign(pv, { selectedQuantity: q })
                        return newPv
                      }
                      return pv
                    })
                  )
                }}
              />
            )
          )}
          {shippingAddress ? (
            <ShippingAddressBlock
              shippingAddress={shippingAddress}
              onAddressChange={handleAddressChange}
            />
          ) : (
            <AddressButton
              submitLabel="Add address"
              addAddressCTALabel="Add address"
              onAddressSubmit={handleAddressChange}
              dialogTitle="Add new address"
            />
          )}

          <Pane marginTop={16}>
            <InternalMemoForm setInternalMemo={setInternalMemo} />
          </Pane>
        </Flex>
        <Flex vertical gap={16} flex={1}>
          <Pane elevation={1} padding={32} borderRadius={8}>
            <Flex vertical gap={16}>
              <Flex vertical gap={8}>
                <Flex justify="space-between">
                  <Heading size={500}>Order summary</Heading>
                  {selectedProductVariants.length > 0 && (
                    <Text color="muted">
                      {selectedProductVariants.length} selected
                    </Text>
                  )}
                </Flex>
              </Flex>

              {selectedProductVariants.map(productVariant => (
                <ProductThumbnail key={productVariant.id}>
                  <Flex
                    gap={18}
                    align="center"
                    style={{
                      width: '100%',
                    }}
                  >
                    <ProductThumbnail.Image
                      imageUrl={productVariant.imageUrl}
                    />

                    <Flex vertical gap={4}>
                      <ProductThumbnail.Name>
                        {productVariant.productName}
                      </ProductThumbnail.Name>

                      <ProductThumbnail.ShippingCountries
                        iso3ShippingCountries={productVariant.shippingCountries}
                      />
                    </Flex>

                    <Flex align="center" justify="end" flex={1}>
                      <ProductThumbnail.Price
                        alignSelf="end"
                        price={
                          productVariant.selectedQuantity *
                            Number(productVariant.amount) || 0
                        }
                      />
                    </Flex>
                  </Flex>
                </ProductThumbnail>
              ))}
              {!!totalCost && (
                <PriceBreakdown
                  productPrice={totalCost}
                  shippingCost={0}
                  totalCost={totalCost}
                  totalLabel="Total:"
                  estimatedTax={0}
                  perkUpFee={0}
                  hideTaxAndPerkupFee
                />
              )}
              {shippingAddress?.country && (
                <AddressDisplay address={shippingAddress} hasBadge />
              )}
            </Flex>
          </Pane>
          <Paragraph marginTop={12}>
            When using Direct mail, the recipient will not receive an email
            notification once the order is placed. You can view the delivery
            status on the order details page.
          </Paragraph>
          {productAlertProps && (
            <Alert
              showIcon
              type="warning"
              {...productAlertProps}
              style={{
                width: 'fit-content',
                alignSelf: 'center',
                marginBottom: 16,
              }}
            />
          )}
        </Flex>
      </Flex>

      <Drawer
        zIndex={1}
        open={showProductsModal}
        placement="bottom"
        width="100vw"
        height="100vh"
        closeIcon={null}
        title={
          <Pane
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            zIndex={2}
          >
            <Heading size={500}>Add gift</Heading>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setShowProductsModal(false)}
            />
          </Pane>
        }
        styles={{
          header: {
            borderBottom: 'none',
            width: '100%',
          },
          body: {
            paddingTop: '0px',
          },
        }}
        destroyOnClose
      >
        <AlgoliaBrowseProducts
          initialUiState={{
            [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
              numericMenu: {
                amount: '0:2500',
              },
              menu: {
                shippingCountries: USA_ISO3,
              },
            },
          }}
        >
          <AlgoliaBrowseProducts.Old
            onProductCardClick={setProductVariantToShow}
            withOccasionsFilter={false}
            withCategoriesFilter
            withPriceFilter
            withProductAmounts
            withCountryFilter
            bottomOffsetAmount={128}
            searchFilter={TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE}
          />
        </AlgoliaBrowseProducts>
      </Drawer>
    </Flex>
  )
}
