import { Flex, Grid } from 'antd'
import { Pane } from 'evergreen-ui'
import { useIsImagePortrait } from 'hooks/images/use-is-image-portrait'
import { isMobile } from 'react-device-detect'
import { PerkImage } from './PerkImage'

const { useBreakpoint } = Grid

export function CardWithImage({
  imageUrl,
  children,
}: React.PropsWithChildren<{ imageUrl?: string }>) {
  const screens = useBreakpoint()

  const isLargeScreen = screens.md || screens.lg || screens.xl

  const { isImagePortrait } = useIsImagePortrait({
    imageUrl,
  })

  return (
    <Flex
      vertical={!isImagePortrait || !isLargeScreen}
      gap={16}
      align="center"
      justify="center"
      style={{ width: '100%' }}
    >
      {isImagePortrait ? (
        <PerkImage
          src={imageUrl}
          sizes="80vh"
          height="auto"
          alt="Reward image"
          style={{
            boxSizing: 'border-box',
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: isLargeScreen ? '100%' : 280,
            borderRadius: 16,
            boxShadow:
              '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          }}
        />
      ) : (
        <PerkImage
          src={imageUrl}
          sizes="(max-width: 583px) 100vw, 720px"
          alt="Reward image"
          width="auto"
          style={{
            boxSizing: 'border-box',
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: 320,
            borderRadius: 16,
            boxShadow:
              '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          }}
        />
      )}

      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={isMobile ? 16 : 32}
        borderRadius={16}
        background="white"
        width="100%"
        style={{ maxWidth: isImagePortrait ? 520 : 720 }}
      >
        {children}
      </Pane>
    </Flex>
  )
}
