// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/product_variant.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /productVariants/:id/
 *
 * @generated from message perkup.v1.ProductVariant
 */
export class ProductVariant extends Message<ProductVariant> {
  /**
   * Shopify variant id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * variant s, m, l, xl, xxl share same productId
   *
   * @generated from field: string product_id = 2;
   */
  productId = "";

  /**
   * Name of the product - product title in Shopify product object
   *
   * @generated from field: string product_name = 3;
   */
  productName = "";

  /**
   * Initial price
   *
   * @generated from field: optional string compare_at_price = 4;
   */
  compareAtPrice?: string;

  /**
   * Actual (optionally discounted) price
   *
   * @generated from field: optional int64 price = 5 [deprecated = true];
   * @deprecated
   */
  price?: bigint;

  /**
   * @generated from field: optional string sku = 6;
   */
  sku?: string;

  /**
   * @generated from field: optional int64 position = 7;
   */
  position?: bigint;

  /**
   * @generated from field: optional string fulfillment_service = 9;
   */
  fulfillmentService?: string;

  /**
   * which vendor or service manage the inventory
   *
   * @generated from field: optional google.protobuf.Timestamp created_at = 14;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp updated_at = 15;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: optional bool taxable = 16;
   */
  taxable?: boolean;

  /**
   * @generated from field: optional string barcode = 17;
   */
  barcode?: string;

  /**
   * weight in grams of the product
   *
   * @generated from field: optional int64 grams = 18;
   */
  grams?: bigint;

  /**
   * @generated from field: optional string image_id = 19;
   */
  imageId?: string;

  /**
   * weight for shipping - might include package, box, etc.
   *
   * @generated from field: optional float weight = 20;
   */
  weight?: number;

  /**
   * @generated from field: optional string weight_unit = 21;
   */
  weightUnit?: string;

  /**
   * @generated from field: optional bool requires_shipping = 25;
   */
  requiresShipping?: boolean;

  /**
   * @generated from field: string admin_graphql_api_id = 26;
   */
  adminGraphqlApiId = "";

  /**
   * Name of the variant
   *
   * @generated from field: string variant_name = 27;
   */
  variantName = "";

  /**
   * category
   *
   * @generated from field: optional string product_type = 28;
   */
  productType?: string;

  /**
   * @generated from field: optional string vendor = 29;
   */
  vendor?: string;

  /**
   * URL handle for the product
   *
   * @generated from field: optional string handle = 30;
   */
  handle?: string;

  /**
   * @generated from field: repeated string tags = 31;
   */
  tags: string[] = [];

  /**
   * Description is the body_html field of Shopify product object
   *
   * @generated from field: optional string description = 32;
   */
  description?: string;

  /**
   * Total count of the variants of the related product
   *
   * @generated from field: optional int64 variants_count = 33;
   */
  variantsCount?: bigint;

  /**
   * Hash containing the option name as key e.g. Size, Color...
   *
   * @generated from field: map<string, perkup.v1.ProductVariant.Option> options = 34;
   */
  options: { [key: string]: ProductVariant_Option } = {};

  /**
   * product published time
   *
   * @generated from field: optional google.protobuf.Timestamp published_at = 35;
   */
  publishedAt?: Timestamp;

  /**
   * Array of product collection names this product belongs to
   *
   * @generated from field: repeated string collections = 36;
   */
  collections: string[] = [];

  /**
   * @generated from field: repeated string collection_ids = 37;
   */
  collectionIds: string[] = [];

  /**
   * true if collectionIds array is empty
   *
   * @generated from field: optional bool has_no_collection = 45;
   */
  hasNoCollection?: boolean;

  /**
   * @generated from field: optional string image_url = 38;
   */
  imageUrl?: string;

  /**
   * @generated from field: optional string org_id = 39;
   */
  orgId?: string;

  /**
   * the platform which the product is on
   *
   * @generated from field: perkup.v1.ProductVariant.Provider provider = 40;
   */
  provider = ProductVariant_Provider.PROVIDER_UNSPECIFIED;

  /**
   * @generated from field: optional bool is_near_cash = 41 [deprecated = true];
   * @deprecated
   */
  isNearCash?: boolean;

  /**
   * @generated from field: optional int64 order_quantity_limit = 42;
   */
  orderQuantityLimit?: bigint;

  /**
   * 3-letter ISO currency code
   *
   * @generated from field: optional string currency_code = 43;
   */
  currencyCode?: string;

  /**
   * @generated from field: optional string currency_name = 44;
   */
  currencyName?: string;

  /**
   * @generated from field: optional string country_name = 46;
   */
  countryName?: string;

  /**
   * @generated from field: repeated perkup.v1.ProductVariant.Country countries = 47;
   */
  countries: ProductVariant_Country[] = [];

  /**
   * @generated from field: optional int64 max_value = 48;
   */
  maxValue?: bigint;

  /**
   * @generated from field: optional int64 min_value = 49;
   */
  minValue?: bigint;

  /**
   * Realtime or Delayed
   *
   * @generated from field: optional perkup.v1.ProductVariant.DeliveryType delivery_type = 50;
   */
  deliveryType?: ProductVariant_DeliveryType;

  /**
   * @generated from field: optional int64 discount = 51;
   */
  discount?: bigint;

  /**
   * Exchange rate in comparison to client Base currency. e.g. 1 USD = 26 VND
   *
   * @generated from field: optional float exchangeRate = 52;
   */
  exchangeRate?: number;

  /**
   * @generated from field: optional bool is_phone_number_mandatory = 53;
   */
  isPhoneNumberMandatory?: boolean;

  /**
   * @generated from field: optional perkup.v1.ProductVariant.ValueType value_type = 54;
   */
  valueType?: ProductVariant_ValueType;

  /**
   * Any extra fees levied on this product
   *
   * @generated from field: optional int64 extra_fee = 55;
   */
  extraFee?: bigint;

  /**
   * amount in USD in cents
   *
   * @generated from field: optional int64 amount = 56;
   */
  amount?: bigint;

  /**
   * local currency amount in cents
   *
   * @generated from field: optional int64 currency_amount = 57;
   */
  currencyAmount?: bigint;

  /**
   * @generated from field: repeated perkup.v1.ProductVariant.Image product_images = 58;
   */
  productImages: ProductVariant_Image[] = [];

  /**
   * the source of the product current options are `fullPrepaid`
   *
   * @generated from field: optional perkup.v1.ProductVariant.SourceType source_type = 59;
   */
  sourceType?: ProductVariant_SourceType;

  /**
   * mapped plum categories to product categories
   *
   * @generated from field: repeated string product_categories = 60 [deprecated = true];
   * @deprecated
   */
  productCategories: string[] = [];

  /**
   * policy on continue selling when 0 inventory - 'deny' means not continue selling when not enough inventory, continue means not track or track with overflow selling
   *
   * @generated from field: optional perkup.v1.ProductVariant.InventoryPolicy inventory_policy = 8;
   */
  inventoryPolicy?: ProductVariant_InventoryPolicy;

  /**
   * @generated from field: optional string inventory_management = 10;
   */
  inventoryManagement?: string;

  /**
   * @generated from field: optional string inventory_item_id = 22;
   */
  inventoryItemId?: string;

  /**
   * @generated from field: optional int64 inventory_quantity = 23;
   */
  inventoryQuantity?: bigint;

  /**
   * @generated from field: optional bool inventoryTracked = 61;
   */
  inventoryTracked?: boolean;

  /**
   * metrics add counters for things like clicks, purchases, etc.
   *
   * @generated from field: map<string, int32> metrics = 62;
   */
  metrics: { [key: string]: number } = {};

  /**
   * @generated from field: optional string voucher_redemption_instructions = 64;
   */
  voucherRedemptionInstructions?: string;

  /**
   * @generated from field: bool is_bundled = 65;
   */
  isBundled = false;

  /**
   * lower case iso-3 country codes
   *
   * @generated from field: repeated string shipping_countries = 66;
   */
  shippingCountries: string[] = [];

  /**
   * @generated from field: optional perkup.v1.ProductVariant.Category categories = 67;
   */
  categories?: ProductVariant_Category;

  /**
   * @generated from field: optional string purchasing_store_url = 68;
   */
  purchasingStoreUrl?: string;

  /**
   * @generated from field: perkup.v1.ProductVariant.Type type = 69;
   */
  type = ProductVariant_Type.TYPE_UNSPECIFIED;

  /**
   * true if any of the variant is available. it is true either conditions fulfilled:
   * 1. inventory not track
   * 2. inventory track and inventory quantity > 0
   * 3. inventory track and inventory policy is continue which means it allows overselling
   *
   * @generated from field: optional bool is_available = 70;
   */
  isAvailable?: boolean;

  /**
   * true if any of the variant is available
   *
   * @generated from field: optional bool product_is_available = 71;
   */
  productIsAvailable?: boolean;

  /**
   * expiry date of the variant, work as a ttl, variant will be auto remove by Firestore (Firestore rules applied)
   *
   * @generated from field: optional google.protobuf.Timestamp firestore_delete_at = 72;
   */
  firestoreDeleteAt?: Timestamp;

  /**
   * @generated from field: optional string product_image_url = 73;
   */
  productImageUrl?: string;

  /**
   * tagline for the product (may be generate by AI etc..)
   *  eg. Known for its durable construction, it’s a practical gift that’s built to last.
   *
   * @generated from field: optional string product_tagline = 74;
   */
  productTagline?: string;

  /**
   *  Boost is used to sort collections and product variants in the collection.
   *
   * The higher the boost the higher the collection and it's respective products will be shown in the list.
   *
   * Product variant will inherit the maximum boost from all collections it belongs to.
   *
   * if no collection is assigned to the product variant, the default value is 0.
   *
   * @generated from field: int32 boost = 75;
   */
  boost = 0;

  constructor(data?: PartialMessage<ProductVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "compare_at_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "price", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "position", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "fulfillment_service", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "created_at", kind: "message", T: Timestamp, opt: true },
    { no: 15, name: "updated_at", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "taxable", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 17, name: "barcode", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "grams", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 19, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 21, name: "weight_unit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "requires_shipping", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 26, name: "admin_graphql_api_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "variant_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "product_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 31, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "variants_count", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 34, name: "options", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ProductVariant_Option} },
    { no: 35, name: "published_at", kind: "message", T: Timestamp, opt: true },
    { no: 36, name: "collections", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 37, name: "collection_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 45, name: "has_no_collection", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 38, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 40, name: "provider", kind: "enum", T: proto3.getEnumType(ProductVariant_Provider) },
    { no: 41, name: "is_near_cash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 42, name: "order_quantity_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 43, name: "currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 44, name: "currency_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 46, name: "country_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 47, name: "countries", kind: "message", T: ProductVariant_Country, repeated: true },
    { no: 48, name: "max_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 49, name: "min_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 50, name: "delivery_type", kind: "enum", T: proto3.getEnumType(ProductVariant_DeliveryType), opt: true },
    { no: 51, name: "discount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 52, name: "exchangeRate", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 53, name: "is_phone_number_mandatory", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 54, name: "value_type", kind: "enum", T: proto3.getEnumType(ProductVariant_ValueType), opt: true },
    { no: 55, name: "extra_fee", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 56, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 57, name: "currency_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 58, name: "product_images", kind: "message", T: ProductVariant_Image, repeated: true },
    { no: 59, name: "source_type", kind: "enum", T: proto3.getEnumType(ProductVariant_SourceType), opt: true },
    { no: 60, name: "product_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "inventory_policy", kind: "enum", T: proto3.getEnumType(ProductVariant_InventoryPolicy), opt: true },
    { no: 10, name: "inventory_management", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "inventory_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "inventory_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 61, name: "inventoryTracked", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 62, name: "metrics", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
    { no: 64, name: "voucher_redemption_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 65, name: "is_bundled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 66, name: "shipping_countries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 67, name: "categories", kind: "message", T: ProductVariant_Category, opt: true },
    { no: 68, name: "purchasing_store_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 69, name: "type", kind: "enum", T: proto3.getEnumType(ProductVariant_Type) },
    { no: 70, name: "is_available", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 71, name: "product_is_available", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 72, name: "firestore_delete_at", kind: "message", T: Timestamp, opt: true },
    { no: 73, name: "product_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 74, name: "product_tagline", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 75, name: "boost", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariant {
    return new ProductVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariant {
    return new ProductVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariant {
    return new ProductVariant().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariant | PlainMessage<ProductVariant> | undefined, b: ProductVariant | PlainMessage<ProductVariant> | undefined): boolean {
    return proto3.util.equals(ProductVariant, a, b);
  }
}

/**
 * provider
 *
 * @generated from enum perkup.v1.ProductVariant.Provider
 */
export enum ProductVariant_Provider {
  /**
   * @generated from enum value: PROVIDER_UNSPECIFIED = 0;
   */
  PROVIDER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: shopify = 1;
   */
  shopify = 1,

  /**
   * @generated from enum value: plum = 2;
   */
  plum = 2,

  /**
   * e.g. airtable
   *
   * @generated from enum value: manual = 3;
   */
  manual = 3,

  /**
   * @generated from enum value: printful = 4;
   */
  printful = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_Provider)
proto3.util.setEnumType(ProductVariant_Provider, "perkup.v1.ProductVariant.Provider", [
  { no: 0, name: "PROVIDER_UNSPECIFIED" },
  { no: 1, name: "shopify" },
  { no: 2, name: "plum" },
  { no: 3, name: "manual" },
  { no: 4, name: "printful" },
]);

/**
 * @generated from enum perkup.v1.ProductVariant.DeliveryType
 */
export enum ProductVariant_DeliveryType {
  /**
   * @generated from enum value: DELIVERY_TYPE_UNSPECIFIED = 0;
   */
  DELIVERY_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: realtime = 1;
   */
  realtime = 1,

  /**
   * @generated from enum value: delayed = 2;
   */
  delayed = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_DeliveryType)
proto3.util.setEnumType(ProductVariant_DeliveryType, "perkup.v1.ProductVariant.DeliveryType", [
  { no: 0, name: "DELIVERY_TYPE_UNSPECIFIED" },
  { no: 1, name: "realtime" },
  { no: 2, name: "delayed" },
]);

/**
 * @generated from enum perkup.v1.ProductVariant.ValueType
 */
export enum ProductVariant_ValueType {
  /**
   * @generated from enum value: VALUE_TYPE_UNSPECIFIED = 0;
   */
  VALUE_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: fixed_denomination = 1;
   */
  fixed_denomination = 1,

  /**
   * @generated from enum value: open_value = 2;
   */
  open_value = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_ValueType)
proto3.util.setEnumType(ProductVariant_ValueType, "perkup.v1.ProductVariant.ValueType", [
  { no: 0, name: "VALUE_TYPE_UNSPECIFIED" },
  { no: 1, name: "fixed_denomination" },
  { no: 2, name: "open_value" },
]);

/**
 * The source type of the product optionally `fullPrepaid`, possibly more future types like `partialPrepaid`.
 *  - if `fullPrepaid` is specified we do not draw funds from an account.
 *  - omitted is the normal state, indicating accounts will fund the balance transactions.
 *
 * @generated from enum perkup.v1.ProductVariant.SourceType
 */
export enum ProductVariant_SourceType {
  /**
   * @generated from enum value: SOURCE_TYPE_UNSPECIFIED = 0;
   */
  SOURCE_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: fullPrepaid = 1;
   */
  fullPrepaid = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_SourceType)
proto3.util.setEnumType(ProductVariant_SourceType, "perkup.v1.ProductVariant.SourceType", [
  { no: 0, name: "SOURCE_TYPE_UNSPECIFIED" },
  { no: 1, name: "fullPrepaid" },
]);

/**
 * @generated from enum perkup.v1.ProductVariant.InventoryPolicy
 */
export enum ProductVariant_InventoryPolicy {
  /**
   * @generated from enum value: INVENTORY_POLICY_UNSPECIFIED = 0;
   */
  INVENTORY_POLICY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: deny = 1;
   */
  deny = 1,

  /**
   * @generated from enum value: continue = 2;
   */
  continue = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_InventoryPolicy)
proto3.util.setEnumType(ProductVariant_InventoryPolicy, "perkup.v1.ProductVariant.InventoryPolicy", [
  { no: 0, name: "INVENTORY_POLICY_UNSPECIFIED" },
  { no: 1, name: "deny" },
  { no: 2, name: "continue" },
]);

/**
 * @generated from enum perkup.v1.ProductVariant.Type
 */
export enum ProductVariant_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: nearCash = 1;
   */
  nearCash = 1,

  /**
   * @generated from enum value: swag = 2;
   */
  swag = 2,

  /**
   * @generated from enum value: publicGift = 3;
   */
  publicGift = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductVariant_Type)
proto3.util.setEnumType(ProductVariant_Type, "perkup.v1.ProductVariant.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "nearCash" },
  { no: 2, name: "swag" },
  { no: 3, name: "publicGift" },
]);

/**
 * @generated from message perkup.v1.ProductVariant.Category
 */
export class ProductVariant_Category extends Message<ProductVariant_Category> {
  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments > Percussion > Hand Percussion > Musical Cowbells
   * Arts & Entertainment > Party & Celebration > Gift Giving > Gift Cards & Certificates
   * ]
   *
   * @generated from field: repeated string paths = 7;
   */
  paths: string[] = [];

  /**
   * [Arts & Entertainment] (unique...)
   *
   * @generated from field: repeated string lvl0 = 1;
   */
  lvl0: string[] = [];

  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts
   * Arts & Entertainment > Party & Celebration
   * ]
   *
   * @generated from field: repeated string lvl1 = 2;
   */
  lvl1: string[] = [];

  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments
   * Arts & Entertainment > Party & Celebration > Gift Giving
   * ]
   *
   * @generated from field: repeated string lvl2 = 3;
   */
  lvl2: string[] = [];

  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments > Percussion
   * ]
   *
   * @generated from field: repeated string lvl3 = 4;
   */
  lvl3: string[] = [];

  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments > Percussion > Hand Percussion
   * ]
   *
   * @generated from field: repeated string lvl4 = 5;
   */
  lvl4: string[] = [];

  /**
   * [
   * Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments > Percussion > Hand Percussion > Musical Cowbells
   * ]
   *
   * @generated from field: repeated string lvl5 = 6;
   */
  lvl5: string[] = [];

  constructor(data?: PartialMessage<ProductVariant_Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductVariant.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "paths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 1, name: "lvl0", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "lvl1", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "lvl2", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "lvl3", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "lvl4", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "lvl5", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariant_Category {
    return new ProductVariant_Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariant_Category {
    return new ProductVariant_Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariant_Category {
    return new ProductVariant_Category().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariant_Category | PlainMessage<ProductVariant_Category> | undefined, b: ProductVariant_Category | PlainMessage<ProductVariant_Category> | undefined): boolean {
    return proto3.util.equals(ProductVariant_Category, a, b);
  }
}

/**
 * product variant option with the correspond value and all value options
 * Size example: {value: S, values: [S, M, L]}
 * Color example: {value: Red, values: [Red, Black, White]}
 * Denomination example: {value: 100, values: [100, 200, 500]}
 *
 * @generated from message perkup.v1.ProductVariant.Option
 */
export class ProductVariant_Option extends Message<ProductVariant_Option> {
  /**
   * option value e.g. Red
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * all the value choices for this option e.g. Red, Black, White...
   *
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<ProductVariant_Option>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductVariant.Option";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariant_Option {
    return new ProductVariant_Option().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariant_Option {
    return new ProductVariant_Option().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariant_Option {
    return new ProductVariant_Option().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariant_Option | PlainMessage<ProductVariant_Option> | undefined, b: ProductVariant_Option | PlainMessage<ProductVariant_Option> | undefined): boolean {
    return proto3.util.equals(ProductVariant_Option, a, b);
  }
}

/**
 * @generated from message perkup.v1.ProductVariant.Image
 */
export class ProductVariant_Image extends Message<ProductVariant_Image> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional int64 position = 2;
   */
  position?: bigint;

  /**
   * @generated from field: optional string src = 9;
   */
  src?: string;

  constructor(data?: PartialMessage<ProductVariant_Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductVariant.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "position", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "src", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariant_Image {
    return new ProductVariant_Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariant_Image {
    return new ProductVariant_Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariant_Image {
    return new ProductVariant_Image().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariant_Image | PlainMessage<ProductVariant_Image> | undefined, b: ProductVariant_Image | PlainMessage<ProductVariant_Image> | undefined): boolean {
    return proto3.util.equals(ProductVariant_Image, a, b);
  }
}

/**
 * @generated from message perkup.v1.ProductVariant.Country
 */
export class ProductVariant_Country extends Message<ProductVariant_Country> {
  /**
   * 2-letter ISO country code
   *
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<ProductVariant_Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductVariant.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductVariant_Country {
    return new ProductVariant_Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductVariant_Country {
    return new ProductVariant_Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductVariant_Country {
    return new ProductVariant_Country().fromJsonString(jsonString, options);
  }

  static equals(a: ProductVariant_Country | PlainMessage<ProductVariant_Country> | undefined, b: ProductVariant_Country | PlainMessage<ProductVariant_Country> | undefined): boolean {
    return proto3.util.equals(ProductVariant_Country, a, b);
  }
}

