import { GOOGLE_MAPS_API_KEY } from '@repo/constants/keys'
import { AddressElement } from '@stripe/react-stripe-js'
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { UserContext } from 'context'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useContext } from 'react'
import { buildFullName } from 'utils'

export function AddressForm({
  mode = 'shipping',
  allowedCountries,
  hidePhoneNumber = false,
  defaultAddress,
  onAddressChange,
}: {
  mode?: 'billing' | 'shipping'
  allowedCountries?: string[]
  hidePhoneNumber?: boolean
  defaultAddress?: ShippingAddress
  onAddressChange: (event: StripeAddressElementChangeEvent) => void
}) {
  const user = useContext(UserContext)

  const fullName = buildFullName({
    firstName: user.profile?.firstName,
    lastName: user.profile?.lastName,
  })

  return (
    <AddressElement
      id="customStripeElement"
      onChange={onAddressChange}
      options={{
        mode,
        blockPoBox: true,
        allowedCountries,
        autocomplete: {
          mode: 'google_maps_api',
          apiKey: GOOGLE_MAPS_API_KEY,
        },
        defaultValues: {
          name: defaultAddress?.name || fullName,
          address: defaultAddress
            ? {
                line1: defaultAddress.line1,
                line2: defaultAddress.line2,
                country: defaultAddress.country,
                city: defaultAddress.city,
                state: defaultAddress.state,
                postal_code: defaultAddress.postalCode,
              }
            : undefined,
          phone: defaultAddress?.phoneNumber
            ? parsePhoneNumber(defaultAddress.phoneNumber).nationalNumber
            : undefined,
        },
        display: {
          name: 'full',
        },
        fields: {
          phone: hidePhoneNumber ? 'never' : 'always',
        },
        validation: hidePhoneNumber
          ? undefined
          : {
              phone: { required: 'always' },
            },
      }}
    />
  )
}
