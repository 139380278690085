import { CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import {
  GetProductVariantById,
  GetProgramById,
  ListProductVariantsByIds,
} from 'api/databaseCalls'
import { PROGRAM_DUPLICATED } from 'constants/events'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext, UserContext } from 'context'

import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { WithSelectedQuantity } from 'types'
import { createRewardLocationState, logEvent } from 'utils'
import { isPublicGiftProductVariant } from 'utils/productVariant'

export function DuplicateReward({
  programId,
  useIconButton = true,
}: {
  programId: string
  useIconButton?: boolean
}) {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const orgId = org?.id

  const handleDuplicateProgram = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)
    const program = await GetProgramById({ orgId, programId })

    if (program?.type === ProgramType.direct) {
      // Determine if it's a public or swag gift
      const firstItem = program?.items[0]

      if (!firstItem) {
        navigate(DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG)
      } else {
        const defaultPv = await GetProductVariantById({
          productVariantId: firstItem?.productVariantId,
        })
        const isPublicGift = defaultPv && isPublicGiftProductVariant(defaultPv)
        const navRoute = isPublicGift
          ? DEFAULT_ROUTES.ORGANIZATION.ORDER.GIFTS
          : DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG

        let pvsForProgram: WithSelectedQuantity<ProductVariant>[] = []
        if (program.items.length > 0) {
          const pvs = await ListProductVariantsByIds({
            ids: program.items.map(i => i.productVariantId),
          })
          if (!pvs) return
          const pvsWithQuantity: WithSelectedQuantity<ProductVariant>[] =
            pvs.map(pv => {
              const itemForPv = program.items.find(
                i => i.productVariantId === pv.id
              )
              const newPv = Object.assign(pv, {
                selectedQuantity: itemForPv?.quantity || 1,
              })
              return newPv
            })
          pvsForProgram = pvsWithQuantity
        }

        navigate(navRoute, { state: { productVariants: pvsForProgram } })
      }
    } else {
      const createRewardState = createRewardLocationState({ program, org })
      navigate(
        DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD,
        createRewardState
      )

      window.scrollTo(0, 0)
    }
    logEvent(PROGRAM_DUPLICATED, {
      programName: program?.name,
      orgName: org.name,
      orgSubscriptionStatus: org?.subscriptionStatus
        ? Organization_SubscriptionStatus[org.subscriptionStatus]
        : null,
      programStatus: program?.status,
      orgId,
      userId: user.id,
    })
    setIsLoading(false)
  }

  if (!useIconButton)
    return (
      <Button onClick={handleDuplicateProgram} disabled={isLoading}>
        Duplicate
      </Button>
    )

  return (
    <Tooltip title="Duplicate">
      <Button
        icon={<CopyOutlined />}
        type="text"
        onClick={handleDuplicateProgram}
        disabled={isLoading}
      />
    </Tooltip>
  )
}
