import { Flex, Popover, Tooltip } from 'antd'
import { IndividualAvatar } from 'components/IndividualAvatar'
import { PerkImage } from 'components/PerkImage'
import { ImageSize, ImageSizeString } from 'constants/placid/images'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Pane, Spinner, Text } from 'evergreen-ui'
import { Individual } from 'gen/perkup/v1/individual_pb'
import {
  ProductCollection,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { useListIndividualsByIds } from 'hooks/individuals/useListIndividualsByIds'
import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  formatCollectionPermission,
  getHighestPermissionByKey,
} from 'utils/permissions'

type IndividualMap = Record<
  string,
  {
    individual: Individual
    permission: ProductCollection_Permission
    from: ProductCollection
  }
>

function AccessDetails({
  from,
  permission,
}: {
  from: ProductCollection
  permission: ProductCollection_Permission
}) {
  return (
    <Flex vertical>
      <Flex>
        <Tooltip title="To change a product permissions, update the permissions of one of its collections.">
          <Text>{formatCollectionPermission(permission)}</Text>
        </Tooltip>
      </Flex>
      <Flex>
        <Text color="muted">Inherited from</Text>
        <Popover
          color="white"
          title={
            <Flex
              vertical
              gap={12}
              align="center"
              style={{
                width: 'fit-content',
              }}
            >
              <PerkImage
                style={{
                  width: 256 satisfies ImageSize,
                }}
                src={from.imageUrl}
                sizes={'256px' satisfies ImageSizeString}
              />
              <Text>{from.name}</Text>
            </Flex>
          }
        >
          <Link
            to={`${DEFAULT_ROUTES.ORGANIZATION.SWAG.COLLECTIONS}/${from.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              className="one-line-truncate"
              color="inherit"
              marginLeft={4}
              maxWidth="25ch"
            >
              {from.name}
            </Text>
          </Link>
        </Popover>
      </Flex>
    </Flex>
  )
}

const styles = {
  cellTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
} as const

export function ProductCollectionsPermissionsTable({
  collections,
}: {
  collections: ProductCollection[]
}) {
  const {
    adminPermission,
    managerPermission,
    individualsPermissions,
    individualIds,
  } = useMemo(() => {
    const { admin, manager, ...individualsPermissions } =
      getHighestPermissionByKey(
        collections.map(collection => ({
          from: collection,
          permissions: collection.permissions,
        }))
      )

    return {
      adminPermission: admin,
      managerPermission: manager,
      individualsPermissions,
      individualIds: Object.keys(individualsPermissions),
    }
  }, [collections])

  const { individuals, isLoading } = useListIndividualsByIds({
    individualIds,
  })

  const individualMap = individuals.reduce<IndividualMap>((acc, individual) => {
    if (individualsPermissions[individual.id]?.permission)
      acc[individual.id] = {
        individual,
        permission: individualsPermissions[individual.id].permission,
        from: individualsPermissions[individual.id].from,
      }
    return acc
  }, {})

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Pane
      display="grid"
      gridTemplateColumns="max-content 1fr"
      columnGap={32}
      rowGap={16}
    >
      {adminPermission && (
        <>
          <Text {...styles.cellTitle}>All admins</Text>
          <AccessDetails {...adminPermission} />
        </>
      )}

      {managerPermission && (
        <>
          <Text {...styles.cellTitle}>All managers</Text>
          <AccessDetails {...managerPermission} />
        </>
      )}
      {individualIds.length > 0 && (
        <>
          <Text {...styles.cellTitle}>Specific users</Text>
          <div />
        </>
      )}
      {Object.entries(individualMap).map(
        ([key, { individual, permission, from }]) => (
          <Fragment key={key}>
            <IndividualAvatar individual={individual} hideRoleBadge />
            <AccessDetails from={from} permission={permission} />
          </Fragment>
        )
      )}
    </Pane>
  )
}
