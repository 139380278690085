import { PlusOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Select, Skeleton, Tooltip } from 'antd'
import { createCollection } from 'api/databaseCalls'
import { PerkEmptyState, ProductCollectionCard } from 'components'
import { ProductCollectionsGrid } from 'components/product-collections/product-collections-grid'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import {
  ProductCollection,
  ProductCollection_CollectionAccess_Enum,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { useListProductCollectionsByOrgId } from 'hooks'
import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import { calculateProductCollectionMinPrice, makePlural } from 'utils'
import {
  PRODUCT_COLLECTION_LEVELS,
  getHighestPermission,
} from 'utils/permissions'

function OrgSwagCollectionsPage() {
  const { name: orgName, id: orgId } = useContext(OrgContext)
  const { role, id: individualId } = useContext(IndividualContext)

  const { productCollections, isLoading } = useListProductCollectionsByOrgId()

  const [isCreatingCollection, setIsCreatingCollection] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [accessFilter, setAccessFilter] = useState<
    ProductCollection_CollectionAccess_Enum | 'All'
  >('All')

  const navigate = useNavigate()

  const handleCreateCollection = async () => {
    setIsCreatingCollection(true)
    const newCollection = await createCollection({
      orgId,
      individualId,
      collectionName: `${orgName} swag`,
      collectionDescription: '',
    })
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.SWAG.COLLECTIONS}/${newCollection?.id}`
    )
    setIsCreatingCollection(false)
  }

  const handleSendCollection = (collection: ProductCollection) => {
    const { name, imageUrl, id: productCollectionId } = collection

    const gift = new Program_Gift({
      id: productCollectionId,
      title: name,
      imageUrl,
      productCollectionId,
      redeemableQuantity: 1,
    })
    const budget = calculateProductCollectionMinPrice(collection)
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget },
    })
  }

  if (isLoading) {
    return (
      <Flex vertical>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={`${index.toString()}`}
            paragraph={{ rows: 2 }}
            style={{ margin: 'auto', padding: 32 }}
            active
          />
        ))}
      </Flex>
    )
  }

  const collectionsToDisplay = productCollections
    .filter(({ name, access, permissions }) => {
      const highestCollectionPermission = getHighestPermission(
        PRODUCT_COLLECTION_LEVELS
      )({
        role,
        individualId,
        permissions,
      })

      return (
        highestCollectionPermission &&
        name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (accessFilter === 'All' || access === accessFilter)
      )
    })
    .sort((a, b) => {
      const aCreatedAt = a?.createdAt?.toDate()
      const bCreatedAt = b?.createdAt?.toDate()

      if (aCreatedAt && bCreatedAt) {
        return aCreatedAt > bCreatedAt ? -1 : 1
      }
      return 0
    })
    .sort((a, b) => {
      const aIsPublic =
        a?.access === ProductCollection_CollectionAccess_Enum.public
      const bIsPublic =
        b?.access === ProductCollection_CollectionAccess_Enum.public
      if (aIsPublic && !bIsPublic) return -1
      if (!aIsPublic && bIsPublic) return 1
      return 0
    })

  return (
    <>
      <Helmet>
        <title>Collections | Swag</title>
      </Helmet>

      <Flex vertical gap={16} style={{ width: '100%', padding: 32 }}>
        <Flex align="center" gap={24}>
          <Flex align="center" gap={24} flex={1}>
            <Heading size={700}>Collections</Heading>

            <Input
              allowClear
              placeholder="Search by collection name"
              value={searchQuery}
              onChange={e => setSearchQuery(e.currentTarget.value)}
              style={{
                maxWidth: 360,
              }}
              prefix={<SearchOutlined />}
            />
            <Button
              icon={<PlusOutlined />}
              onClick={handleCreateCollection}
              loading={isCreatingCollection}
              disabled={isCreatingCollection}
            >
              Create collection
            </Button>
          </Flex>

          <Flex gap={8} align="center">
            <Flex align="center">
              <Text>Show</Text>
              <Select
                style={{ height: 'min-content' }}
                placement="bottomRight"
                dropdownStyle={{ minWidth: 'max-content' }}
                variant="borderless"
                value={accessFilter}
                onChange={setAccessFilter}
                options={[
                  { value: 'All', label: 'All' },
                  {
                    value: ProductCollection_CollectionAccess_Enum.public,
                    label: 'In Swag Store',
                  },
                  {
                    value: ProductCollection_CollectionAccess_Enum.private,
                    label: 'Not in Swag Store',
                  },
                ]}
              />
            </Flex>
            <Text color="muted" minWidth="fit-content" height="fit-content">
              {makePlural('collection', collectionsToDisplay.length, true)}
            </Text>
          </Flex>
        </Flex>

        {productCollections.length === 0 && (
          <PerkEmptyState
            header="Create your first collection to get started 🚀"
            ctaProps={{
              icon: <PlusOutlined />,
              onClick: handleCreateCollection,
              loading: isCreatingCollection,
              disabled: isCreatingCollection,
              children: 'Create Collection',
            }}
          />
        )}

        <ProductCollectionsGrid>
          {collectionsToDisplay.map(collection => {
            const highestCollectionPermission = getHighestPermission(
              PRODUCT_COLLECTION_LEVELS
            )({
              role,
              individualId,
              permissions: collection.permissions,
            })

            const onlyHasViewAccess =
              highestCollectionPermission === ProductCollection_Permission.view
            const collectionIsEmpty = Number(collection.productsCount) === 0
            const isSendDisabled = onlyHasViewAccess || collectionIsEmpty
            const disabledTooltip = onlyHasViewAccess
              ? "You don't have permissions to send this collection"
              : 'This collection has no products'

            return (
              <ProductCollectionCard
                key={collection.id}
                productCollection={collection}
                toPrefix={DEFAULT_ROUTES.ORGANIZATION.SWAG.COLLECTIONS}
              >
                <ProductCollectionCard.Details />
                <ProductCollectionCard.Badge />
                <ProductCollectionCard.Menu />
                <ProductCollectionCard.CtaRow
                  cta={
                    isSendDisabled ? (
                      <Tooltip title={disabledTooltip}>
                        <Button disabled>
                          Send <SendOutlined />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleSendCollection(collection)
                        }}
                      >
                        Send <SendOutlined />
                      </Button>
                    )
                  }
                />
              </ProductCollectionCard>
            )
          })}
        </ProductCollectionsGrid>
      </Flex>
    </>
  )
}

export default OrgSwagCollectionsPage
